import React from 'react';
import { compose } from 'glamor';
import * as theme from '../theme';

export const Label = ({ error, children }) => {
	const labelStyle = {
		display: 'block',
		fontSize: 18,
		height: 30,
	};

	const errorStyle = !!error && {
		color: theme.flavours.danger.highlightColor,
	};

	return (
		<label
			className={compose(
				labelStyle,
				errorStyle,
			)}
		>
			{children}
		</label>
	);
};
