import {WebSocketLink} from '@apollo/client/link/ws';
import {ApolloClient, HttpLink, InMemoryCache, split} from '@apollo/client';
import {getMainDefinition} from '@apollo/client/utilities';

export const DRIVO_URL = process.env.NODE_ENV === "production" ? "https://api.drivo.io" : "http://localhost:4000";

const httpLink = new HttpLink({
  uri: `${DRIVO_URL}/graphql`,
  // You can add headers or other options here if needed
});

export default new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
});