import React from 'react';
import {withInstallation} from "../../../../components/InstallationContext";
import {Helmet} from "react-helmet";
import {H1} from "../../../../components/Typography";
import {CardContainer} from "../../../../components/Container";
import Container from "@material-ui/core/Container";
import HourlyRatesForm from "./HourlyRatesForm";
import {useQuery} from "@apollo/client";
import {hourlyRateByFacility} from "../queries";
import drivoApollo from "../../../../lib/drivoApollo";
import {Loading} from "../../../../components/LoadingFork";


function AddOrEditHourlyRates({installation, history}) {

    function onDone(rateCard) {
        history.goBack(rateCard ? {
            flash: {
                flavour: 'success',
                message: `${rateCard.name} created successfully`,
                createdAt: Date.now(),
            },
        } : null);
    }


    const {data = {}, error, loading, refetch} = useQuery(hourlyRateByFacility, {
        client: drivoApollo,
        variables: {
            installationId: installation.id
        }
    })

    if (loading) return (<CardContainer><Loading/></CardContainer>)

    return (
        <CardContainer>
            <Container spaceBelow="medium">
                <Helmet>
                    <title>Public Parking Hourly Rates</title>
                </Helmet>
                <H1 spaceBelow="large">
                    Public Parking Hourly Rates
                </H1>

                <HourlyRatesForm
                    installation={installation}
                    facilityHourlyRatesData={data ? data.hourlyRateByFacility : null}
                    facilityHourlyRatesRefetch={refetch}
                    onDone={onDone}
                    // onCancel={onDone}
                />
            </Container>
        </CardContainer>
    )
}

export default withInstallation(AddOrEditHourlyRates)
