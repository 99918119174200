import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';
import { createBrowserHistory } from 'history';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { render } from 'react-dom';
import ReactModal from 'react-modal';
import { ApolloProvider as ApolloHooksProvider } from "@apollo/client"
import 'font-awesome/css/font-awesome.min.css';
import './reset.css';
import apolloClient from './lib/apollo';

import ScrollToTop from './components/ScrollToTop';
import { AppConfigProvider } from './components/AppConfigContext';
import { InstallationSelector } from './components/InstallationSelector';
import { InstallationProvider } from './components/InstallationContext';
import App from './App';
import {ApolloProvider} from "@apollo/client";

const configUri = process.env.REACT_APP_CONFIG_URI;

// Create Custom Sentry Route component
const SentryRoute = Sentry.withSentryRouting(Route);
const history = createBrowserHistory();

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENV,
  integrations: [
    Sentry.reactRouterV5BrowserTracingIntegration({ history }),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

// initialize react Modal behaviour
const appElement = document.getElementById('root');
ReactModal.setAppElement(appElement);
ReactModal.defaultStyles.overlay.backgroundColor = 'rgba(0,0,0,0.2)';
ReactModal.defaultStyles.overlay.zIndex = 100000;

render(
  <BrowserRouter>
    <ScrollToTop>
      <ApolloProvider client={apolloClient}>
        <ApolloHooksProvider client={apolloClient}>
          <AppConfigProvider configUri={configUri}>
            <Switch>
              <SentryRoute exact path="/" component={InstallationSelector} />
              <SentryRoute
                path='/:brandingKey'
                render={(props) => (
                  <InstallationProvider {...props}>
                    <App />
                  </InstallationProvider>
                )}
              />
            </Switch>
          </AppConfigProvider>
        </ApolloHooksProvider>
      </ApolloProvider>
    </ScrollToTop>
  </BrowserRouter>,
  appElement,
);
