import React, {useState} from 'react'
import {Alert} from '../../../components/Alert';
import {Button, NakedButton} from '../../../components/Button';
import {Container, CardContainer} from '../../../components/Container';
import {HR, H1, H2, Byline, strong} from '../../../components/Typography';
import {Helmet} from 'react-helmet';
import {Left, Right} from '../../../components/Header'
import {Loading, LoadingError} from '../../../components/LoadingFork'
import {css} from 'glamor';
import {fmtDate} from "../../../lib/misc";
import {useQuery, useMutation} from "@apollo/client"
import {withInstallation} from '../../../components/InstallationContext';
import {
    Header,
    PublicStar,
    Status
} from './components/common'

import RateCardDetailsForm from './DetailsForm'
import {
    rateCardById,
    archiveRateCardMutation, rateCardStatusesQuery
} from './queries';
import drivoApollo from "../../../lib/drivoApollo";

function ArchiveForm({installation, rateCard, onArchive}) {
    const [showConfirm, setShowConfirm] = useState(false)

    return <div>
        <HR/>
        {showConfirm ? <div style={{textAlign: 'center'}}>
            <p>Are you sure? This cannot be un-done.</p>
            <div>
                <Button flavour='danger' spaceAbove='small' onClick={onArchive}>
                    Yes really archive this guest card
                </Button>
                <NakedButton flavour='gray'
                             spaceAbove='small'
                             onClick={() => setShowConfirm(false)}>
                    Cancel
                </NakedButton>
            </div>
        </div> : <NakedButton block
                              flavour='danger'
                              spaceAbove='small'
                              onClick={() => setShowConfirm(true)}>
            Archive guest card
        </NakedButton>}
    </div>
}

function RateCardDetails({appConfig, installation, match, location, history}) {
    const {rateCardId} = match.params
    const {data, loading, error: fetchError} = useQuery(rateCardById, {
        fetchPolicy: 'no-cache',
        variables: {rateCardId},
    })

    const {data: rateCardStatus} = useQuery(rateCardStatusesQuery, {
        fetchPolicy: 'no-cache',
        variables: {rateCardId},
        client: drivoApollo
    })

    const [archiveRateCard, {error: archiveError}] = useMutation(archiveRateCardMutation)
    const error = fetchError || archiveError

    if (loading) return (
        <CardContainer>
            <Loading/>
        </CardContainer>
    )
    if (error) return (
        <CardContainer>
            <LoadingError error={error}/>
        </CardContainer>
    )

    function onDone() {
        console.log('OD')
        history.goBack()
    }

    const rateCard = data.RateCard
    if (!rateCard) {
        return (
            <CardContainer>
                <Container spaceBelow='medium'>
                    <H1>Not found</H1>
                    <Button onClick={onDone}>Back to guest cards</Button>
                </Container>
            </CardContainer>
        )
    }

    async function onArchive() {
        console.log('onArchive')
        await archiveRateCard({
            variables: {
                rateCardId: rateCard.id,
            }
        })
        onDone()
    }

    return (
        <CardContainer>
            <div>
                <Helmet>
                    <title>{`${rateCard.name} guest card`}</title>
                </Helmet>
                <Container spaceBelow='medium'>
                    {rateCard.isArchived && (
                        <Alert flavour='info'>
                            This guest card has been archived and cannot be edited.
                        </Alert>
                    )}

                    {rateCard.defaultPublic && (
                        <Alert flavour='info'>
                            <strong>
                                <PublicStar/>
                                This guest card is set as the Public Guest Card.
                            </strong>
                            <a href='#public-ratecard' className={css({paddingLeft: 10})}>
                                See details below
                            </a>
                        </Alert>
                    )}

                    <Header>
                        <Left>
                            <div>
                                <H1 spaceBelow='none'>{rateCard.name}</H1>
                                <Byline>
                                    <span>Version {rateCard.version} • </span>
                                    {rateCard.createdBy && <span>Created by {rateCard.createdBy.name} </span>}
                                    <span>on {fmtDate(rateCard.createdAt, installation.timeZoneName, 'd MMM yyyy')}</span>
                                </Byline>
                                {rateCard.lot && <Byline><b><span>Lot: {rateCard.lot.name}</span></b></Byline>}
                            </div>
                        </Left>
                        <Right>
                            <Status {...rateCard} />
                        </Right>
                    </Header>
                </Container>
                <Container spaceAbove='medium' spaceBelow='medium'>
                    <H2>Guest card details</H2>
                    <RateCardDetailsForm
                        installation={installation}
                        rateCard={rateCard}
                        rateCardStatus={rateCardStatus?.rateCardStatus}
                        onSubmit={onDone}
                        onCancel={onDone}
                    />
                </Container>

                {!rateCard.isArchived && <Container spaceAbove='medium' spaceBelow='medium'>
                    <ArchiveForm
                        installation={installation}
                        rateCard={rateCard}
                        onArchive={onArchive}
                    />
                </Container>}
            </div>
        </CardContainer>
    )
}

export default withInstallation(RateCardDetails)
