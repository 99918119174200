import React from 'react';
import { css, compose } from 'glamor';

const container = css({
	position: 'fixed',
	top: 0,
	left: 0,
	right: 0,
	paddingLeft: [20, '3vw'],
	paddingRight: [20, '3vw'],
	display: 'flex',
	flexDirection: 'row',
	flexWrap: 'wrap',
	justifyContent: 'space-between',
	alignItems: 'stretch',
	backgroundColor: 'rgba(255,255,255,0.93)',
	boxShadow: '0 0 1px 0 rgba(0,0,0,0.2)',
});

const padding = css({
	position: 'relative',
	paddingTop: 70,
	zIndex: 9999,
});

export const Left = ({ className, ...props }) => (
	<div
		className={compose(
			{
				order: 1,
				display: 'flex',
				justifyContent: 'flex-start',
				alignItems: 'center',
			},
			className,
		)}
		{...props}
	/>
);
export const Center = ({ className, ...props }) => (
	<div
		className={compose(
			{
				order: 2,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			},
			className,
		)}
		{...props}
	/>
);
export const Right = ({ className, ...props }) => (
	<div
		className={compose(
			{
				order: 3,
				display: 'flex',
				justifyContent: 'flex-end',
				alignItems: 'center',
			},
			className,
		)}
		{...props}
	/>
);

export const Header = ({ className, children }) => (
	<header className={padding}>
		<div
			className={compose(
				container,
				className,
			)}
		>
			{children}
		</div>
	</header>
);
