import React from 'react';
import {Helmet} from 'react-helmet';
import {configureForm} from '@thinkmill/pragmatic-forms';
import authManager from '../../lib/authManager';
import {css} from 'glamor';
import {GraphqlErrorMessage, ValidationErrorMessage} from '../../components/ErrorMessage';
import {Container} from '../../components/Container';
import {Input} from '../../components/Input';
import {Button} from '../../components/Button';
import {LogoBox} from '../../components/LogoBox';
import drivo from "../../icons/drivo.svg";
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import {makeStyles} from "@material-ui/core/styles";

import hotel101Logo from '../../icons/Hotel101Logo.svg';

const formLayout = css({
    display: 'flex',
    flexDirection: 'column',
});


const useStyles = makeStyles(() => ({
    card: {
        width: '650px',
        height: '620px',
        borderRadius: '25px',
        background: 'linear-gradient(#B0FF33, #97C93B)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05)',
        transition: '.3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12)',
    },
    nestedCard: {
        // overflowY: 'auto',
        width: '621px',
        height: '591px',
        borderRadius: '25px',
        background: '#fff',


    },
    cardContent: {

        alignItems:'center'
    },
    signInButton:{
        backgroundColor:'#3127B8',
        marginTop:'35px',
        height:'40px',
        width:'48%',
        fontSize:'18px'
    }
}))

const withForm = configureForm({
    initFields: () => ({email: '', password: ''}),
    validate: ({email, password}) => {
        const errors = {};
        if (!email) errors.email = 'Please enter your email address';
        if (!password) errors.password = 'Please enter your password';
        return errors;
    },
    submit: ({email, password}) => {
        return authManager.login({email, password});
    },
});

function Login({form}) {
    const classes = useStyles()
    return (
        <div>

            <Card className={classes.card}>
                <Card className={classes.nestedCard}>
                    <Container size="small">
                        <CardContent className={classes.cardContent}>
                            <Helmet>
                                <title>Login</title>
                            </Helmet>
                            <div className={css({paddingTop: 40, paddingBottom: 40})}>
                                <LogoBox size={80} src={'/DRIVO_Logo.png'}/>
                            </div>

                            <form.Form className={formLayout}>
                                {form.submitError && <GraphqlErrorMessage error={form.submitError}/>}
                                {form.hasErrors && <ValidationErrorMessage/>}

                                <Input
                                    {...form.getFieldProps({
                                        name: 'email',
                                        type: 'email',
                                    })}
                                    placeholder="Email Address"
                                    autoComplete="username"
                                />
                                <Input
                                    style={{'margin-top': '15px'}}
                                    {...form.getFieldProps({
                                        name: 'password',
                                        type: 'password',
                                    })}
                                    placeholder="Password"
                                    autoComplete="current-password"
                                />
                                <Button type="submit" style={{marginTop:'20px',height:'45px',fontSize:'18px'}} block disabled={form.isLoading}>
                                    Sign in
                                </Button>
                                {/*<u><Typography variant="caption" display="block" style={{color:'grey',marginTop:'75px',cursor:'pointer',textAlign:'center'}} gutterBottom>*/}
                                {/*    Did you forget your password*/}
                                {/*</Typography></u>*/}
                            </form.Form>
                        </CardContent>
                    </Container>

                </Card>
            </Card>

        </div>
    );
}

export default withForm(Login);
