import React from 'react'
import { StatusPill } from '../../../components/StatusPill'

export default function RateCardLabel ({ rateCard }) {
	if (!rateCard) return null

	return <span>
		{rateCard.name} (version {rateCard.version}) <StatusPill size='xsmall'>{rateCard.type}</StatusPill>
	</span>
}
