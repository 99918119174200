import React, {useState} from 'react';
import {css} from 'glamor';
import {format, utcToZonedTime} from 'date-fns-tz';
import {useQuery} from "@apollo/client";
import * as theme from '../../theme'

import {Button, HollowButton} from '../../components/Button';
import {CardContainer, Container} from '../../components/Container';
import {H1, H2} from '../../components/Typography'
import {Loading, LoadingError} from '../../components/LoadingFork';
import {Table, TBody, THead} from '../../components/Table';
import {Well} from '../../components/Well'
import {withInstallation} from '../../components/InstallationContext'
import {fmtDate} from "../../lib/misc";
import LinkTR from './components/LinkTR'
import RateCardLabel from './components/RateCardLabel'
import {InlineBookingStatus} from './components/BookingStatus'
import {listBookings, listBookingsWithExtras} from './queries'
import {CellPill} from '../../components/StatusPill'
import DrivoApollo from "../../lib/drivoApollo";
import {DRIVO_URL} from "../../lib/drivoApollo";

const paddingLeft = css({paddingLeft: '40px !important'});
const textBold = css({fontWeight: 'bold', fontSize: 14});
const textGray = css({color: theme.gray40, fontSize: 14});
const rowStyle = css({
    '> td': {
        verticalAlign: 'middle',
        height: 60,
        borderBottom: `1px solid ${theme.gray10}`,
        transition: 'all .2s linear',
        textAlign: "center"
    },
    '> td:first-child': {paddingLeft: 10},
    '> td:last-child': {paddingRight: 10},
    ':hover > td': {
        background: theme.gray10,
        cursor: 'pointer',
    },
});

const extraRowStyle = css({
    '> td': {
        verticalAlign: 'middle',
        height: 60,
        borderBottom: `1px solid ${theme.gray10}`,
        transition: 'all .2s linear',
        textAlign: "center",
        background: theme.barelyYellow,
    },
    '> td:first-child': {paddingLeft: 10},
    '> td:last-child': {paddingRight: 10},
    ':hover > td': {
        background: theme.gray10,
        cursor: 'pointer',
    },
});

function BookingWithExtrasRow({href, booking, timezone}) {
    return <LinkTR className={rowStyle}
                   href={href}>
        <td className={textBold}><CellPill color='#37B534' width='100px' size='tableCell'>{booking.plate}</CellPill>
        </td>
        <td>
            {booking.guestName &&
                <CellPill className={textBold} width='100px' color='#FFA800'
                          size='tableCell'>{booking.guestName}</CellPill>}
        </td>
        <td className={textGray}><CellPill width='117px' color='#FF4773'
                                           size='tableCell'> {fmtDate(new Date(booking.startDay).toISOString(), timezone, 'dd/MM/yyyy')}</CellPill></td>
        <td className={textGray}><CellPill width='117px' color='#45B5F4'
                                           size='tableCell'>{fmtDate(new Date(booking.endDay).toISOString(), timezone, 'dd/MM/yyyy')}</CellPill></td>
        <td className={textGray} style={{width: "350px"}}>
            <table style={{width: "100%"}}>
                <tbody>
                    {booking.bookingExtras.map(e => {
                     return <tr>
                         <td className={textGray}>{e.rateCardExtra.description}</td>
                         <td className={textGray}>${e.rateCardExtra.amount}</td>
                     </tr>
                    })
                    }

                </tbody>
            </table>
        </td>
    </LinkTR>
}


function GetCleanIntervalForBooking(bookingInterval) {
    if (bookingInterval == null) {
        return '';
    }
    let ausTimezone = 'Australia/Brisbane';
    let start = bookingInterval.split("/")[0];
    let end = bookingInterval.split("/")[1];
    // 2021-05-13T07:29:00.643+10:00/2021-05-13T08:42:37.081+10:00
    let startDate = utcToZonedTime(new Date(start), ausTimezone);
    let endDate = utcToZonedTime(new Date(end), ausTimezone);

    return `${format(startDate, 'h:maaa d MMM', {timeZone: ausTimezone})} to 
    ${format(endDate, 'h:maaa d MMM', {timeZone: ausTimezone})}`
}

function UpcomingExtras({installation, facility, history, match}) {
    const {
        data = {},
        loading,
        error,
        refetch,
    } = useQuery(listBookingsWithExtras, {
        fetchPolicy: 'no-cache',
        variables: {
            installationId: installation.id,
        },
        client: DrivoApollo
    })


    const bookings = data.bookingsWithExtras ? data.bookingsWithExtras : []



    return <div>
        <CardContainer>
            <Container size="large" spaceBelow="medium">
                <div style={{
                    display: "flex",
                    gap: "20px",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}>
                    <H1>Upcoming Parking Extras</H1>
                    <Button
                    target={"_blank"}
                    href={`${DRIVO_URL}/extras-report/${facility.id}/download`}
                    spaceAbove="medium"
                    spaceBelow="medium"
                    className={css({
                        marginTop: 0,
                        marginBottom: 16,
                    })}
                >
                    Download Extra Report
                </Button>
          </div>
            </Container>
            <Container size="large">
                {error ? <LoadingError error={error}/> : null}
                {(bookings.length === 0) ? <Well>
                    <div>
                        <H2 spaceAbove="large" spaceBelow="large">
                            {loading ? <Loading/> : 'No upcoming extras'}
                        </H2>
                    </div>
                </Well> : <div>
                    <Table>
                        <THead>
                            <tr>
                                <th style={{textAlign: "center"}}>Licence plate</th>
                                <th style={{textAlign: "center"}}>Customer</th>
                                <th style={{textAlign: "center"}}>Start Day</th>
                                <th style={{textAlign: "center"}}>End Day</th>
                                <th style={{textAlign: "center"}}>Extras</th>
                            </tr>
                        </THead>
                        <TBody>
                            {bookings.map((booking) => (
                                <BookingWithExtrasRow
                                    key={booking.id}
                                    booking={booking}
                                    timezone={installation.timeZoneName}
                                    href={`bookings/${booking.id}`}
                                />

                            ))}
                        </TBody>
                    </Table>
                    <div
                        className={css({
                            textAlign: 'center',
                        })}
                    >
                        <HollowButton
                            className={css({marginLeft: 20})}
                            onClick={() => refetch()}
                            spaceAbove="medium"
                            spaceBelow="medium"
                        >
                            Refresh
                        </HollowButton>
                    </div>
                </div>}

            </Container>
        </CardContainer>
    </div>
}

export default withInstallation(UpcomingExtras)
