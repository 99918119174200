import React from 'react'

import {Button, NakedButton} from '../../../components/Button'
import {Container, ColContainer, TPL_50_50, CardContainer} from '../../../components/Container';
import {GraphqlErrorMessage, ValidationErrorMessage} from '../../../components/ErrorMessage';
import {H1, H2} from '../../../components/Typography';
import {Helmet} from 'react-helmet';
import {Input} from '../../../components/Input';
import {SelectInput} from '../../../components/SelectInput';
import {useFormState} from 'react-use-form-state'
import {useMutation} from "@apollo/client"
import {withInstallation} from '../../../components/InstallationContext';


import {
    createUserMutation,
    createUserPermissionMutation
} from './queries';

const ROLE_ADMIN = 'admin'
const ROLE_RECEPTION = 'receptionist'
const ROLE_OPTIONS = [
    {value: ROLE_ADMIN, label: 'Hotel Admin'},
    {value: ROLE_RECEPTION, label: 'Receptionist'},
]

function UserCreate({installation, history, match}) {
    const [{errors, values}, {text, email, tel, select, password}] = useFormState({
        name: '',
        email: '',
        phone: '',
        role: ROLE_RECEPTION,
    })

    const [createUser, {error: createUserError}] = useMutation(createUserMutation)
    const [createUserPermission, {error: createPermissionError}] = useMutation(createUserPermissionMutation)
    const submitError = createUserError || createPermissionError
    const firstError = Object.values(errors)[0]
    const hasErrors = !!firstError
    const canSubmit = (
        values.name &&
        values.email &&
        values.role &&
        values.password &&
        values.password === values.passwordConfirm
    )

    async function onCreate() {
        const {data: {createUser: {id: userId}}} = await createUser({
            variables: {
                installationId: installation.id,
                name: values.name,
                email: values.email,
                phone: values.phone,
                password: values.password
            },
        })

        const wantsToBeAdmin = values.role === 'admin'
        await createUserPermission({
            variables: {
                installationId: installation.id,
                userId,
                isAdmin: wantsToBeAdmin
            }
        })

        history.push(match.url.replace('/new', `/${userId}`), {
            flash: {
                flavour: 'success',
                message: `A new account for ${values.name} has been created!`,
                createdAt: Date.now(),
            },
        })
    }

    function onCancel() {
        history.goBack()
    }

    function validatePassword(value, state) {
        if (state.password === state.passwordConfirm) return
        return 'Passwords do not match'
    }

    return <div>

                    <CardContainer>
                        <Container spaceAbove="medium" spaceBelow="medium">
                            <Helmet>
                                <title>New user</title>
                            </Helmet>
                            <H1>New user</H1>
                            {submitError && <GraphqlErrorMessage spaceBelow="medium" error={submitError}/>}
                            {hasErrors && <ValidationErrorMessage spaceBelow="medium" error={firstError}/>}

                            <ColContainer columnTemplate={TPL_50_50} spaceAbove="medium">
                                <Input required
                                       label="Name"
                                       error={errors.name}
                                       {...text('name')} />
                                <SelectInput
                                    label="Role"
                                    error={errors.role}
                                    options={ROLE_OPTIONS}
                                    {...select('role')}
                                />
                            </ColContainer>
                            <ColContainer columnTemplate={TPL_50_50} spaceBelow="medium">
                                <Input required
                                       label="Email address"
                                       error={errors.email}
                                       {...email({
                                           name: 'email',
                                           validateOnBlur: true
                                       })} />
                                <Input label="Phone number" {...tel('phone')} />
                            </ColContainer>

                            <H2>Password</H2>
                            <ColContainer columnTemplate={TPL_50_50}>
                                <Input required
                                       label="Password"
                                       placeholder="Password"
                                       minLength={8}
                                       error={errors.password}
                                       {...password('password')} />
                                <Input required
                                       label="Confirm Password"
                                       placeholder="Confirm Password"
                                       error={errors.passwordConfirm}
                                       {...password({
                                           name: 'passwordConfirm',
                                           validate: validatePassword,
                                           validateOnBlur: true
                                       })} />
                            </ColContainer>
                            <div>
                                <Button type="submit" style={{'cursor':'pointer'}} spaceAbove="small" disabled={!canSubmit} onClick={onCreate}>
                                    Create User
                                </Button>
                                <NakedButton style={{'cursor':'pointer'}} flavour="gray" spaceAbove="small" onClick={onCancel}>
                                    Cancel
                                </NakedButton>
                            </div>
                        </Container>
                    </CardContainer>

    </div>
}

export default withInstallation(UserCreate)
