import React from 'react';
import { css } from 'glamor';
import { flavours, verticalSpace } from '../theme';

export const Well = ({ flavour = 'light', ...props }) => (
	<div
		className={css({
			display: 'flex',
			marginTop: verticalSpace.medium,
			marginBottom: verticalSpace.medium,
			paddingTop: verticalSpace.large,
			paddingBottom: verticalSpace.large,
			alignItems: 'center',
			textAlign: 'center',
			justifyContent: 'center',
			color: flavours[flavour].foregroundColor,
			backgroundColor: flavours[flavour].backgroundColor,
			borderRadius: 10,
		})}
		{...props}
	/>
);
