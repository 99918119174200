import gql from 'graphql-tag'

export const allLots = gql`
    query allLots ($installationId: ID!) {
        allLots(
            filter: {
                installationId: $installationId
            }
            orderBy: name_ASC
        ) {
            id
            name
            description
            createdAt
            capacity
        }
    }
`

export const lotById = gql`
    query lotById ($lotId: ID!) {
        Lot(id: $lotId) {
            id
            name
            description
            createdAt
            capacity
            createdBy {
                name
            }
        }
    }
`



export const createLotMutation = gql`
    mutation createLot (
        $installationId: ID!
        $name: String
        $description: String
        $capacity: Int
    ) {
        createLot (
            lot: {
                installationId: $installationId
                name: $name
                description: $description
                capacity: $capacity
            }
        ) {
            id
            name
        }
    }
`


export const updateLotMutation = gql`
    mutation updateLot (
        $id: ID!
        $name: String
        $description: String
        $capacity: Int
    ) {
        updateLot (
            lot: {
                id: $id
                name: $name
                description: $description
                capacity: $capacity
            }
        ) {
            id
            name
        }
    }
`
