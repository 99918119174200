import React, {useState} from 'react'
import {Alert} from '../../../components/Alert';
import {Button, NakedButton} from '../../../components/Button';
import {Container, ColContainer, CardContainer, TPL_50_50, TPL_THIRDS} from '../../../components/Container';
import {FlashMessage} from '../../../components/FlashMessage';
import {GraphqlErrorMessage, ValidationErrorMessage} from '../../../components/ErrorMessage';
import {H1, H2, Byline} from '../../../components/Typography';
import {Helmet} from 'react-helmet';
import {Input} from '../../../components/Input';
import {Left, Right} from '../../../components/Header';
import {Loading, LoadingError} from '../../../components/LoadingFork';
import {StatusPill} from '../../../components/StatusPill';
import {css} from 'glamor';
import {fmtDate} from "../../../lib/misc";
import {useFormState} from 'react-use-form-state'
import {useQuery, useMutation} from "@apollo/client"
import {withInstallation} from '../../../components/InstallationContext';

import {
    userById,
    createUserPermissionMutation,
    updateUserMutation,
    updateUserPermissionMutation,
    updateUserPasswordMutation
} from './queries'

const ROLE_ADMIN = 'admin'
const ROLE_RECEPTION = 'receptionist'
const ROLE_OPTIONS = [
    {value: ROLE_ADMIN, label: 'Hotel Admin'},
    {value: ROLE_RECEPTION, label: 'Receptionist'},
]

function userIsAdmin(userPermissions) {
    return Boolean(userPermissions && userPermissions[0] && userPermissions[0].isAdmin)
}

function Header(props) {
    return <div
        className={css({
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'stretch',
        })}
        {...props}
    />
}

function Status({isEnabled}) {
    return <StatusPill flavour={isEnabled ? 'success' : 'light'}>
        {isEnabled ? 'Enabled' : 'Disabled'}
    </StatusPill>
}

function Role({userPermissions}) {
    const isAdmin = userIsAdmin(userPermissions)
    return <span>{isAdmin ? 'Hotel Admin' : 'Receptionist'}</span>
}

function UserDetailsForm({installation, user, onUpdate, onUpdateRole, onUpdatePassword}) {
    const [{
        errors,
        values,
        reset
    }, {text, email, tel, select}] = useFormState({
        name: user.name || '',
        email: user.email || '',
        phone: user.phone || '',
        isEnabled: user.isEnabled ? 'yes' : 'no',
        role: user.role || ROLE_RECEPTION,
    })

    const [{
        errors: pErrors,
        values: pValues,
        reset: pReset
    }, {password: passwordInput}] = useFormState({
        password: '',
        passwordConfirm: ''
    })

    const firstError = Object.values({...errors, ...pErrors})[0]
    const hasErrors = !!firstError
    const isUpdatedUser = (
        user.name !== values.name ||
        user.email !== values.email ||
        user.phone !== values.phone ||
        user.isEnabled !== (values.isEnabled === 'yes')
    )
    const isUpdatedRole = user.role !== values.role
    const isUpdatedDetails = isUpdatedUser || isUpdatedRole
    const isUpdatedPassword = (
        pValues.password !== '' ||
        pValues.passwordConfirm !== ''
    )

    const canUpdatePassword = !hasErrors && isUpdatedPassword && pValues.password === pValues.passwordConfirm

    async function onSubmit() {
        const {role: newRole, ...userValues} = values
        if (isUpdatedUser) await onUpdate(userValues)
        if (isUpdatedRole) await onUpdateRole(newRole)
    }

    async function onSubmitPassword() {
        if (!isUpdatedPassword) return
        await onUpdatePassword(pValues.password)
        pReset()
    }

    function validatePassword(value, state) {
        if (state.password === state.passwordConfirm) return
        return 'Passwords do not match'
    }

    return <div>
        {hasErrors && <ValidationErrorMessage error={firstError}/>}
        {/*<ColContainer columnTemplate={TPL_THIRDS} spaceBelow="medium">*/}
        {/*    <SelectInput required*/}
        {/*                 label="Status"*/}
        {/*                 disabled={user.id === authManager.userId}*/}
        {/*                 error={errors.isEnabled}*/}
        {/*                 {...select('isEnabled')}*/}
        {/*                 options={[{value: 'yes', label: 'Enabled'}, {value: 'no', label: 'Disabled'}]}*/}
        {/*    />*/}
        {/*</ColContainer>*/}
        <ColContainer columnTemplate={TPL_THIRDS} spaceBelow="medium">
            <Input required
                   label="Name"
                   error={errors.name}
                   {...text('name')}
            />
            {/*<SelectInput required*/}
            {/*             label="Role"*/}
            {/*             error={errors.role}*/}
            {/*             options={ROLE_OPTIONS}*/}
            {/*             {...select('role')}*/}
            {/*/>*/}
            <Input required
                   label="Email address"
                   error={errors.email}
                   {...email('email')} />
            <Input
                label="Contact number"
                error={errors.phone}
                {...tel('phone')} />
        </ColContainer>
        {/*<ColContainer columnTemplate={TPL_50_50}>*/}
        {/*    <Input required*/}
        {/*           label="Email address"*/}
        {/*           error={errors.email}*/}
        {/*           {...email('email')} />*/}
        {/*    <Input*/}
        {/*        label="Contact number"*/}
        {/*        error={errors.phone}*/}
        {/*        {...tel('phone')} />*/}
        {/*</ColContainer>*/}
        <Container spaceAbove="small">
            <Button type="submit" disabled={!isUpdatedDetails || hasErrors}
                    style={{'background-color': '#3127B8', 'cursor': 'pointer'}} onClick={onSubmit}>
                Update profile
            </Button>
            {isUpdatedDetails ?
                <NakedButton type="reset" style={{'cursor': 'pointer'}} flavour="gray" onClick={() => reset()}>
                    Cancel
                </NakedButton> : null}
        </Container>
        <br/>
        <Container spaceAbove="small">
            <H2>Update password</H2>
        </Container>
        <ColContainer columnTemplate={TPL_50_50}>
            {/* This helps password managers know which accounts password has been changed. */}
            <input name="email" defaultValue={values.email} hidden/>
            <Input
                label="New Password"
                placeholder="New Password"
                autoComplete="new-password"
                error={errors.password}
                {...passwordInput({
                    name: 'password'
                })} />
            <Input
                label="Confirm Password"
                placeholder="Confirm Password"
                autoComplete="new-password"
                error={errors.passwordConfirm}
                {...passwordInput({
                    name: 'passwordConfirm',
                    validate: validatePassword,
                    validateOnBlur: true
                })} />
        </ColContainer>
        <Container spaceAbove="small">
            <Button type="submit" disabled={!canUpdatePassword} onClick={onSubmitPassword}>
                Update password
            </Button>
            {isUpdatedPassword ? <NakedButton type="reset" flavour="gray" onClick={() => pReset()}>
                Cancel
            </NakedButton> : null}
        </Container>
    </div>
}

function UserDetails({installation, match, location, history}) {
    const {userId} = match.params
    const [showSuccessMessage, setSuccessMessage] = useState(false)
    const [createUserPermission, {error: createPermissionError}] = useMutation(createUserPermissionMutation)
    const [updateUser, {error: updateError}] = useMutation(updateUserMutation)
    const [updateUserPermission, {error: updatePermissionError}] = useMutation(updateUserPermissionMutation)
    const [updateUserPassword, {error: updatePasswordError}] = useMutation(updateUserPasswordMutation)
    const submitError = createPermissionError || updateError || updatePermissionError || updatePasswordError

    const {data = {}, loading, error, refetch} = useQuery(userById, {
        variables: {
            userId,
            installationId: installation.id,
        }
    })

    if (loading) return (<CardContainer><Loading/></CardContainer>)
    if (error) return (<CardContainer><LoadingError error={error}/></CardContainer>)

    const user = {...data.User}
    if (!user) {
        return (
            <CardContainer>
                <Container spaceAbove="medium" spaceBelow="medium">
                    <H1>Not found</H1>
                    <Button onClick={onCancel}>Back to Users</Button>
                </Container>
            </CardContainer>
        )
    }

    // inject useful fields
    const isAdmin = userIsAdmin(user.userPermissions)
    user.role = isAdmin ? ROLE_ADMIN : ROLE_RECEPTION

    async function onUpdate(values) {
        await updateUser({
            variables: {
                ...values,
                isEnabled: values.isEnabled === 'yes',
                id: user.id
            }
        })

        setSuccessMessage(true)
        refetch()
    }

    function onCancel() {
        history.goBack()
    }

    async function onUpdatePassword(newPassword) {
        await updateUserPassword({
            variables: {
                id: user.id,
                password: newPassword
            },
        })

        setSuccessMessage(true)
    }

    async function onUpdateRole(newRole) {
        // TODO: may be undefined, and we ignore if N > 1
        const permission = user.userPermissions[0]
        const wantsToBeAdmin = newRole === 'admin'

        if (!permission) {
            await createUserPermission({
                variables: {
                    installationId: installation.id,
                    userId: user.id,
                    isAdmin: wantsToBeAdmin
                }
            })
        } else {
            await updateUserPermission({
                variables: {
                    permissionId: permission.id,
                    isAdmin: wantsToBeAdmin
                },
            })
        }

        setSuccessMessage(true)
        refetch()
    }

    return (
        <CardContainer>
            <div>
                <Helmet>
                    <title>{`${user.name} | User details`}</title>
                </Helmet>
                <Container spaceBelow="medium">
                    <FlashMessage locationState={location.state}/>
                    <Header>
                        <Left>
                            <div>
                                <H1 spaceBelow="none">{user.name}</H1>
                                <Byline>
                                    <Role userPermissions={user.userPermissions}/>
                                    , user since{' '}
                                    {fmtDate(user.createdAt, installation.timeZoneName)}
                                </Byline>
                            </div>
                        </Left>
                        <Right>
                            <Status isEnabled={user.isEnabled}/>
                        </Right>
                    </Header>
                    <hr style={{'color': '#B1B1B2'}}/>
                    <br/>
                </Container>

                <Container spaceAbove="medium" spaceBelow="medium">
                    <H2>User details</H2>
                    {showSuccessMessage && <Alert flavour="success">Your changes have been saved.</Alert>}
                    {submitError && <GraphqlErrorMessage error={submitError}/>}
                    <UserDetailsForm
                        installation={installation}
                        user={user}
                        onUpdate={onUpdate}
                        onUpdateRole={onUpdateRole}
                        onUpdatePassword={onUpdatePassword}
                        onCancel={onCancel}/>
                </Container>

            </div>
        </CardContainer>
    )
}

export default withInstallation(UserDetails)
