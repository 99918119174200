import React from 'react';
import { css } from 'glamor';
import { StatusPill } from '../../../components/StatusPill';
import { BOOKING_STATUS_OPTIONS } from '../../../lib/enums'

const FLAVOURS = {
	PENDING_ENTRY: 'warning',
	CURRENT: 'success',
	CLOSED: 'gray',
	FINALISED: 'gray',
	ERROR: 'danger'
}

function getStatus (status) {
	const { label } = BOOKING_STATUS_OPTIONS.filter(x => x.value === status).pop() || { label: 'UNKNOWN' }
	const flavour = FLAVOURS[status]
	return { label, flavour }
}

export function BookingStatus ({ status }) {
	const { label, flavour } = getStatus(status)
	const _css = css({
		position: 'absolute',
		top: 6,
		right: 16,
	})

	return <div className={_css}>
		<StatusPill flavour={flavour} size='small'>{label}</StatusPill>
	</div>
}

export function InlineBookingStatus ({ status }) {
	const { label, flavour } = getStatus(status)
	const _css = css({
		width: 100,
		paddingLeft: 'initial',
		paddingRight: 'initial',
	})

	return <StatusPill size='small' className={_css} flavour={flavour}>{label}</StatusPill>
}
