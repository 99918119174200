import React, {useState} from 'react';
import {Container} from '../../components/Container';
import {Helmet} from "react-helmet";
import {H1} from "../../components/Typography";
import {Table, TBody, TD, TR} from "../../components/Table";
import {useMutation} from "@apollo/client";
import drivoApollo from "../../lib/drivoApollo";
import {withInstallation} from "../../components/InstallationContext";
import {CardContainer} from "../../components/Container";
import {mutationSupportTicket} from './queries'
import authManager from '../../lib/authManager';
import {supportQuestions} from './supportQuestions'
import * as Survey from "survey-react";
import "survey-react/survey.css";

const adinaInstallationId = 'dbe6a81d-4e26-4eb2-a25d-95e861e91574'

function Support({match, history, installation}) {

    const [supportComplete, setSupportComplete] = useState(false)
    const [createSupportMutation, {error: submitError}] = useMutation(mutationSupportTicket, {client: drivoApollo})
    let model = new Survey.Model(supportQuestions(installation.id));

    const changeFunc = (params) => {
        const name = params[0]
        const support = model.data['support_type']
        model.clear()
        model.setValue(name, support)
    }

    Survey
        .FunctionFactory
        .Instance
        .register("changeFunc", changeFunc);

    const onComplete = async (survey) => {
        setSupportComplete(true)
        const createTicket = survey.data['gate_refund'] === 'Yes' ||
            survey.data['entered_correctly'] ||
            survey.data['contact_maintenance'] === 'No' ||
            survey.data['contact_maintenance_reset_gate'] === 'No' ||
            survey.data['not_allowing_booking'] === 'No' ||
            survey.data['qr_code_not_working'] === 'Yes' ||
            survey.data['reset_nuc_console_stream'] === 'No' ||
            survey.data['support_type'] === 'Camera Damaged' ||
            survey.data['support_type'] === 'Light Damaged' ||
            survey.data['support_type'] === 'Kiosk Damaged' ||
            (survey.data['pull_cables'] === 'No' && installation.id !== adinaInstallationId)

        if (true) {
            let text = survey.data['lodge_refund_ticket'] || survey.data['plate_number'] || ''
            const support_ticket = survey.data['support_type']
            if (support_ticket === "Not Recognizing Plate") {
                text =
                    `
                Plate: ${survey.data['plate_number']}
                Gate: ${survey.data['plate_booking_which_gate']}
                Date: ${survey.data['plate_booking_date']}
                `
            }
            const {data: {addSupportTicket: {id: ticketId}}} = await createSupportMutation({
                variables: {
                    installationId: installation.id,
                    userId: authManager.userId,
                    supportType: support_ticket,
                    text: text
                }
            })
            history.push(match.url.replace('/new', `/${ticketId}`), {
                flash: {
                    flavour: 'success',
                    message: `A new ticket has been created!`,
                    createdAt: Date.now(),
                },
            })
        } else {
            history.goBack()
        }
    }

    return (
        <CardContainer>
            <div>
                <Helmet>
                    <title>Support Ticket</title>
                </Helmet>
                <>
                    <Container size="large" spaceBelow="medium">
                        <H1>New Ticket</H1>
                    </Container>
                    <Container size="large" spaceBelow="large">
                        <Table>
                            <TBody>
                                <TR>
                                    <TD style={{borderBottom: "none"}}>
                                        {!supportComplete ? (
                                            <Survey.Survey model={model} onComplete={onComplete}
                                                           completeText={'Create Ticket'}
                                            />
                                        ) : null}
                                    </TD>
                                </TR>
                            </TBody>
                        </Table>
                    </Container>
                </>
            </div>
        </CardContainer>
    )
}

export default withInstallation(Support);
