import React from 'react';
import { css } from 'glamor';
import { NavLink } from 'react-router-dom';
import * as theme from '../theme';

export const SecondaryNav = ({ children }) => (
	<div
		className={css({
			display: 'flex',
			alignItems: 'stretch',
			height: 60,
			borderColor: theme.gray10,
			borderWidth: 0,
			borderBottomWidth: 1,
			borderStyle: 'solid',
		})}
	>
		{children}
	</div>
);

const navLinkClassName = css({
	display: 'flex',
	alignItems: 'center',
	borderBottomWidth: 1,
	borderBottomColor: 'transparent',
	borderBottomStyle: 'solid',
	color: theme.black,
	marginLeft: 50,
	marginBottom: -1,
	textDecoration: 'none',
	':first-child': {
		marginLeft: 0,
	},
	':visited': { color: theme.black },
	':hover': {
		color: theme.blue,
		borderBottomColor: theme.blue,
	},
	transition: 'all 0.3s linear',
}).toString(); // NOTE: toString is required to prevent a ReactRouter proptype violation

const navLinkActiveClassName = css({
	borderBottomColor: theme.blue,
}).toString(); // NOTE: toString is required to prevent a ReactRouter proptype violation

export const SecondaryNavLink = (props) => (
	<NavLink className={navLinkClassName} activeClassName={navLinkActiveClassName} {...props} />
);
