import React from 'react';

import { Container } from './Container';
import { Well } from './Well';
import { H1, H2 } from './Typography';
import { Alert } from './Alert';

export const Error404 = () => (
	<Container>
		<Well>
			<div>
				<H1>Something‘s not right.</H1>
				<p>We could not find the page you were looking for.</p>
			</div>
		</Well>
	</Container>
);

export const ErrorPage = ({ message }) => (
	<Container>
		<Well>
			<div>
				<H2 spaceBelow="small">There was a problem loading the page</H2>
				<Alert spaceAbove="small" flavour="danger">
					{message}
				</Alert>
			</div>
		</Well>
	</Container>
);
