import React from 'react'
import {Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    cardText: {
        fontSize: '18px',
        fontWeight: 500,
        opacity: 0.72,
    }
}))

function DailyReportTable({leftSide, rightSide}) {
    const classes = useStyles()
    return (
        <>
            <Grid item xs={6}>
                <Typography variant="subtitle2"
                            className={classes.cardText}>
                    {leftSide}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="subtitle2"
                            className={classes.cardText}>
                    {rightSide}
                </Typography>
            </Grid>
        </>
    )
}

export default DailyReportTable
