import React from 'react';
import { css } from 'glamor';
import * as theme from '../theme';

const highlightColor = {
	default: theme.gray90,
	primary: theme.blue,
	info: theme.cyan,
	danger: theme.red,
	success: theme.green,
};

const makeHeading = (Tag, fontSize) => ({
	spaceAbove = 'none',
	spaceBelow = 'small',
	children,
	className,
	...props
}) => (
	<Tag
		className={css(
			{
				fontSize,
				fontWeight: 800,
				marginTop: theme.verticalSpace[spaceAbove],
				marginBottom: theme.verticalSpace[spaceBelow],
			},
			className,
		)}
		{...props}
	>
		{children}
	</Tag>
);

export const H1 = makeHeading('h1', 48);
export const H2 = makeHeading('h2', 24);
export const H3 = makeHeading('h3', 20);
export const H4 = makeHeading('h4', 18);
export const H5 = makeHeading('h5', 16);
export const H6 = makeHeading('h6', 14);

export const styleLink = (Comp) => ({
	flavour = 'default',
	spaceAbove = 'none',
	spaceBelow = 'none',
	block = false,
	className,
	...props
}) => (
	<Comp
		className={css(
			{
				color: highlightColor[flavour],
				display: block ? 'inline-block' : 'initial',
				cursor: props.onClick ? 'pointer' : 'initial',
				textDecoration: 'none',
				marginTop: theme.verticalSpace[spaceAbove],
				marginBottom: theme.verticalSpace[spaceBelow],
			},
			className,
		)}
		{...props}
	/>
);

export const A = styleLink('a');

export const Byline = ({
	spaceAbove = 'none',
	spaceBelow = 'small',
	children,
	className,
	...props
}) => (
	<p
		className={css(
			{
				color: theme.gray40,
				marginTop: theme.verticalSpace[spaceAbove],
				marginBottom: theme.verticalSpace[spaceBelow],
			},
			className,
		)}
		{...props}
	>
		{children}
	</p>
);

export const HR = ({
	spaceAbove,
	spaceBelow,
	thickness = '1px',
	color = theme.gray10,
	className,
	...props
}) => (
	<div
		className={css(
			{
				height: 0,
				borderColor: color,
				borderStyle: 'solid',
				borderWidth: `0 0 ${thickness} 0`,
				marginTop: theme.verticalSpace[spaceAbove],
				marginBottom: theme.verticalSpace[spaceBelow],
			},
			className,
		)}
		{...props}
	/>
);
