// Configure moment
import moment from 'moment-timezone';
import 'moment/locale/en-au';

// Set en-au as the default locale
moment.locale('en-au');
moment.tz.setDefault('Australia/Sydney');

export const setDefaultTimezone = (zone) => {
	if (moment.tz.zone(zone)) {
		moment.tz.setDefault(zone);
	} else {
		throw new Error(`Timezone ${zone} is not loaded`);
	}
};
