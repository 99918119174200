import React from 'react';
import { Container } from '../../components/Container';
import { SecondaryNav, SecondaryNavLink } from '../../components/SecondaryNav';
import { Switch, Route, Redirect } from 'react-router-dom';

import Exporter from './Exporter';
import Payouts from './Payouts';
import RateCardCreate from './RateCards/Create';
import RateCardDetails from './RateCards/Details';
import RateCardList from './RateCards/List';
import ParkingLotList from './ParkingLots/List';
import SanctionedPlates from './SanctionedPlates';
import UserCreate from './Users/Create';
import UserDetails from './Users/Details';
import UserList from './Users/List';
import VoucheryAdmin from "./VoucheryAdmin";
import SanctionedTimesOnlyList from "./SanctionedOnlyTimes/List";
import SanctionedTimeCreate from "./SanctionedOnlyTimes/Create";
import SanctionedTimeUpdate from "./SanctionedOnlyTimes/Details";
import AddOrEditHourlyRates from "./RateCards/HourlyRates/AddOrEditHourlyRates";
import ParkingLotCreate from "./ParkingLots/Create";
import ParkingLotDetails from "./ParkingLots/Details";

export default function Settings ({ match }) {
	return <Container spaceAbove="none" spaceBelow="medium">
		{/*<SecondaryNav>*/}
		{/*	<SecondaryNavLink to={`${match.url}/guestcards`}>Guest cards</SecondaryNavLink>*/}
		{/*	<SecondaryNavLink to={`${match.url}/payouts`}>Payouts</SecondaryNavLink>*/}
		{/*	<SecondaryNavLink to={`${match.url}/bookings`}>Data Exporter</SecondaryNavLink>*/}
		{/*	<SecondaryNavLink to={`${match.url}/users`}>Users</SecondaryNavLink>*/}
		{/*	<SecondaryNavLink to={`${match.url}/plates`}>Sanctioned plates</SecondaryNavLink>*/}
		{/*</SecondaryNav>*/}
		<Switch>
			{/*<Route exact path={`${match.path}`} render={() => <Redirect to={`${match.url}/users`} />} />*/}
			<Route exact path={`${match.path}/guestcards`} component={RateCardList} />
			<Route exact path={`${match.path}/lots`} component={ParkingLotList} />
			<Route exact path={`${match.path}/lots/new`} component={ParkingLotCreate} />
			<Route exact path={`${match.path}/lots/:lotId`} component={ParkingLotDetails} />
			<Route exact path={`${match.path}/guestcards/new`} component={RateCardCreate} />
			<Route exact path={`${match.path}/guestcards/hourly-rates`} component={AddOrEditHourlyRates} />
			<Route exact path={`${match.path}/guestcards/:rateCardId`} component={RateCardDetails} />
			<Route path={`${match.path}/payouts`} component={Payouts} />
			<Route path={`${match.path}/bookings`} component={Exporter} />
			<Route exact path={`${match.path}/users`} component={UserList} />
			<Route exact path={`${match.path}/users/new`} component={UserCreate} />
			<Route exact path={`${match.path}/users/:userId`} component={UserDetails} />
			<Route exact path={`${match.path}/plates`} component={SanctionedPlates} />
			{/*<Route exact path={`${match.path}/vouchery`} component={VoucheryAdmin} />*/}


			<Route exact path={`${match.path}/sanctioned`} component={SanctionedTimesOnlyList} />
			<Route exact path={`${match.path}/sanctioned/new`} component={SanctionedTimeCreate} />
			<Route exact path={`${match.path}/sanctioned/:id`} component={SanctionedTimeUpdate} />
		</Switch>
	</Container>
}
