import React, { createContext, useState, useEffect } from 'react';
import { ErrorPage } from './ErrorPage';

const ConfigContext = createContext({ appConfig: null });

export function AppConfigProvider ({ configUri, children }) {
  const [loading, setLoading] = useState(true)
  const [appConfig, setConfig] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    async function fetchConfig () {
      try {
        const req = await fetch(configUri)
        const {
          APPLICATION_ASSETS_URL,
          APPLICATION_URL,
          PUBLIC_CONSOLE_URL,
          STRIPE_PUBLISHABLE_KEY,
          SUPPORT_CONTACT_EMAIL,
          SUPPORT_CONTACT_PHONE
        } = await req.json()

        setConfig({
          APPLICATION_ASSETS_URL,
          APPLICATION_URL,
          PUBLIC_CONSOLE_URL,
          STRIPE_PUBLISHABLE_KEY,
          SUPPORT_CONTACT_EMAIL,
          SUPPORT_CONTACT_PHONE
        })
      } catch (err) {
        console.error(err); // eslint-disable-line no-console
        setError(`Failed to load application config from "${configUri}"`)
      }

      setLoading(false)
    }

    fetchConfig()
  }, [configUri])

  if (error) return <ErrorPage message={error} />
  if (loading) return null

  return <ConfigContext.Provider
    value={{ appConfig }}
    children={children} />
}

export function withAppConfig (WrappedComponent) {
	return function WithAppConfig (props) {
    return <ConfigContext.Consumer>
      {({ appConfig }) => {
        return <WrappedComponent {...props} appConfig={appConfig} />
      }}
    </ConfigContext.Consumer>
	}
}
