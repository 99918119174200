import React, {useState} from 'react';
import {Helmet} from 'react-helmet';
import {css} from 'glamor';
import {useQuery} from "@apollo/client";

import * as theme from '../../../theme';
import {BadgePill} from '../../../components/StatusPill'
import {Button} from '../../../components/Button'
import {CardContainer, Container} from '../../../components/Container';
import {FlashMessage} from '../../../components/FlashMessage';
import {H1, H2} from '../../../components/Typography';
import {Left, Right} from '../../../components/Header';
import {Loading, LoadingError} from '../../../components/LoadingFork';
import {Table, TBody, THead} from '../../../components/Table';
import {Well} from '../../../components/Well';
import {formatCurrency, makeSafe} from '../../../lib/rateCardUtils';
import {allRateCards, allRateCardStatusesQuery} from './queries'
import {withInstallation} from '../../../components/InstallationContext';
import {Header, PublicStar, Status} from './components/common'
import drivoApollo from "../../../lib/drivoApollo";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import {fmtDate} from "../../../lib/misc";

const rowStyle = css({
    '> td': {
        verticalAlign: 'middle',
        height: 60,
        borderBottom: `1px solid ${theme.gray10}`,
        transition: 'all .2s linear',
    },
    '> td:first-child': {paddingLeft: 10},
    '> td:last-child': {paddingRight: 10},
    ':hover > td': {
        background: theme.gray10,
        cursor: 'pointer',
    },
});

const LINK_CSS = css({
    cursor: 'pointer',
    color: theme.gray20,
    textTransform: 'lowercase !important',
    ':hover': {
        textDecoration: 'underline',
    },
});

function RateCardListItem({installation, rateCard, onClick, rateCardStatus}) {
    const {
        id,
        version,

        name,
        type,
        calculationMode,
        isDaily,
        defaultPublic,

        bookingRateAudPerDay,
        overstayRateAudPerHour,
        serviceFeeRatio,

        bookingRateAudPerHour,
        maximum24HourAud,

        createdAt,
        expiresLaterToday
    } = makeSafe(rateCard)

    const { currency } = installation

    const actualBRPD = bookingRateAudPerDay
    const actualBRPH = bookingRateAudPerHour
    const actualORPH = overstayRateAudPerHour
    const actualM24H = maximum24HourAud

    return <tr key={id} onClick={onClick} className={rowStyle}>
        <td>
            {name}
            {defaultPublic ? <PublicStar/> : null}
            { rateCardStatus != null ? (rateCardStatus.expired || rateCardStatus.full) ?
                <span title={rateCardStatus.expired ? "Guest Card Expired" : "Guest Card Full"}>
                    <ErrorOutlineIcon
                        style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                            lineHeight: 1,
                            marginLeft: "5px"
                        }}
                        color={"error"}
                        fontSize={"small"}
                    />
                </span>
                : null : null}
            { !rateCardStatus?.expired && expiresLaterToday &&
                <span title={"Expires today at 11:59 PM"}>
                    <ReportProblemOutlinedIcon
                        style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                            lineHeight: 1,
                            marginLeft: "5px",
                            color: "#FFA800"
                        }}
                        color={"inherit"}
                        fontSize={"small"}
                    />
                </span>
            }
        </td>
        <td><BadgePill flavour='success' size='xsmall'>{type}</BadgePill></td>
        <td><BadgePill flavour='success' size='xsmall'>{calculationMode}</BadgePill></td>
        <td>{version}</td>
        <td>{fmtDate(createdAt, installation.timeZoneName, 'd MMM yyyy')}</td>
        {isDaily ? <>
            <td>{formatCurrency(actualBRPD, currency)}</td>
            <td>{formatCurrency(actualORPH, currency)}{maximum24HourAud ? ` (capped to ${formatCurrency(actualM24H, currency)} per day)` : ''}</td>
        </> : <>
            <td>{formatCurrency(actualBRPH, currency)} per
                hour {maximum24HourAud ? `(capped to ${formatCurrency(actualM24H, currency)} per day)` : ''}</td>
            <td>-</td>
        </>}
        <td>
            <div className={css({
                display: 'flex',
                justifyContent: 'stretch',
                flexDirection: 'column',
            })}>
                <Status {...rateCard} />
            </div>
        </td>
    </tr>
}

function RateCardList({installation, match, location, history}) {

    const [showArchived, setShowArchived] = useState(false)
    const {data = {}, loading, error} = useQuery(allRateCards, {
        fetchPolicy: 'no-cache',
        variables: {
            installationId: installation.id,
            isArchived: showArchived
        }
    })

    const {data: allRateCardStatuses} = useQuery(allRateCardStatusesQuery, {
        fetchPolicy: 'no-cache',
        variables: {
            installationId: installation.id
        },
        client: drivoApollo
    })

    const rateCardStatuses = allRateCardStatuses != null ? allRateCardStatuses.rateCardsForInstallation : [];

    function toggleArchived() {
        setShowArchived(!showArchived)
    }

    function onClickRateCard(rateCard) {
        history.push(`guestcards/${rateCard.id}`);
    }

    const rateCards = data.allRateCards || []
    const filteredRateCards = rateCards.filter(x => x.isArchived ? showArchived : true)

    return <div>

        <CardContainer>
            <Helmet>
                <title>Guest cards</title>
            </Helmet>
            <Container size="large" spaceAbove="small" spaceBelow="medium">
                <Header>
                    <Left>
                        <H1>Guest cards</H1>
                    </Left>

                    <Right>
                        {rateCards.length ? <Button to={`${match.url}/hourly-rates`} style={{
                            backgroundColor: '#3127B8',
                            cursor: 'pointer',
                            marginRight: 2
                        }}>Hourly Rates</Button> : null}

                        {rateCards.length ?
                            <Button to={`${match.url}/new`} style={{backgroundColor: '#3127B8', cursor: 'pointer'}}>New
                                Guest card</Button> : null}
                    </Right>
                </Header>
            </Container>
            <Container spaceBelow="large">
                <FlashMessage locationState={location.state}/>
                <Table>
                    <THead>
                        <tr>
                            <th>Name</th>
                            <th>Type</th>
                            <th>Calculation Mode</th>
                            <th>Version</th>
                            <th>Created Date</th>
                            <th>Rate</th>
                            <th>Overstay</th>
                            <th>
                                Status{' '}
                                <span style={{color: theme.gray20,}}>(</span>
                                <span className={LINK_CSS} onClick={toggleArchived}>
								{showArchived ? `Hide Archived` : `Show Archived`}
							</span>
                                <span style={{color: theme.gray20,}}>)</span>
                            </th>
                        </tr>
                    </THead>
                    <TBody>
                        {filteredRateCards.length ? filteredRateCards.map((rateCard) => {
                            return <RateCardListItem
                                key={rateCard.id}
                                installation={installation}
                                rateCard={rateCard}
                                rateCardStatus={rateCardStatuses.find(r => r.id === rateCard.id)}
                                onClick={() => onClickRateCard(rateCard)}
                            />
                        }) : <tr>
                            <td colSpan={8}>
                                {loading ? <Loading/> : null}
                                {error ? <LoadingError error={error}/> : null}
                                {loading ? null : <Well>
                                    <div>
                                        <H2 spaceBelow="large">No guest cards available</H2>
                                        <Button spaceBelow="none" to={`${match.url}/new`}>
                                            Create a new guest card now
                                        </Button>
                                    </div>
                                </Well>}
                            </td>
                        </tr>}
                    </TBody>
                </Table>
            </Container>
        </CardContainer>

    </div>
}

export default withInstallation(RateCardList)
