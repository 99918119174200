import React from 'react';
import { css } from 'glamor';

export const LogoBox = ({ className, size = 50, src, alt = '' }) => (
	<div className={className}>
		<img
			className={css({
				height: size,
				display: 'block',
				marginLeft: 'auto',
				marginRight: 'auto',
				imageRendering: 'auto', // might help browsers resize the image crisply
			})}
			src={src}
			alt={alt}
		/>
	</div>
);
