import React from 'react';
import { css, compose } from 'glamor';
import * as theme from '../theme';

export const pillSize = {
	xsmall: {
		borderWidth: 2,
		borderRadius: 4,
		fontSize: 10,
		height: 24,
		paddingHorizontal: 10,
		paddingVertical: 2,
	},
	small: {
		borderWidth: 2,
		borderRadius: 6,
		fontSize: 14,
		height: 30,
		paddingHorizontal: 16,
		paddingVertical: 4,
	},
	medium: {
		heightAboveGround: 2,
		borderWidth: 2,
		borderRadius: 6,
		fontSize: 20,
		height: 60,
		paddingHorizontal: 32,
		paddingVertical: 8,
	},
	large: {
		heightAboveGround: 6,
		borderWidth: 4,
		borderRadius: 12,
		fontSize: 40,
		height: 120,
		paddingHorizontal: 64,
		paddingVertical: 16,
	},
	tableCell:{
		borderWidth: 2,
		borderRadius: 6,
		fontSize: 4,
		height: 20,
		paddingHorizontal: 1,
		paddingVertical: 2,
	}
};

export const StatusPill = ({ flavour = 'primary', size = 'small', children, className }) => {
	const baseStyle = css({
		display: 'inline-block',
		background: theme.white,
		color: theme.flavours[flavour].highlightColor,
		borderStyle: 'solid',
		borderColor: theme.flavours[flavour].highlightColor,
		fontWeight: 'bold',
		textTransform: 'uppercase',
		textAlign: 'center',

		// Size
		borderRadius: pillSize[size].borderRadius,
		borderWidth: pillSize[size].borderWidth,
		paddingLeft: pillSize[size].paddingHorizontal,
		paddingRight: pillSize[size].paddingHorizontal,
		paddingTop: pillSize[size].paddingVertical,
		paddingBottom: pillSize[size].paddingVertical,
		fontSize: pillSize[size].fontSize,
		verticalAlign: 'bottom',
	});

	return (
		<span
			className={compose(
				baseStyle,
				className,
			)}
		>
			{children}
		</span>
	);
};

export const BadgePill = ({ flavour = 'primary', size = 'small', children, className }) => {
	const baseStyle = css({
		display: 'inline-block',
		backgroundColor: 'rgb(156,159,194,.1)',
		color: theme.flavours[flavour].highlightColor,
		fontWeight: 'bold',
		textTransform: 'uppercase',
		textAlign: 'center',

		// Size
		borderRadius: pillSize[size].borderRadius,
		borderWidth: pillSize[size].borderWidth,
		paddingLeft: pillSize[size].paddingHorizontal+2,
		paddingRight: pillSize[size].paddingHorizontal+2,
		paddingTop: pillSize[size].paddingVertical+8,
		paddingBottom: pillSize[size].paddingVertical+8,
		fontSize: pillSize[size].fontSize,
		verticalAlign: 'bottom',
	});

	return (
		<span
			className={compose(
				baseStyle,
				className,
			)}
		>
			{children}
		</span>
	);
};


export const CellPill = ({ size = 'small', children, className, color='green' ,width}) => {
	const baseStyle = css({
		display: 'inline-block',
		backgroundColor: 'rgb(156,159,194,.1)',
		color: color,
		fontWeight: 'bold',
		textTransform: 'uppercase',
		textAlign: 'center',

		// Size
		borderRadius: pillSize[size].borderRadius,
		borderWidth: pillSize[size].borderWidth,
		paddingLeft: pillSize[size].paddingHorizontal+2,
		paddingRight: pillSize[size].paddingHorizontal+2,
		paddingTop: pillSize[size].paddingVertical+7,
		paddingBottom: pillSize[size].paddingVertical+7,
		verticalAlign: 'bottom',
		width:width,
	});

	return (
		<span
			className={compose(
				baseStyle,
				className,
			)}
		>
			{children}
		</span>
	);
};