import React from 'react';
import { compose } from 'glamor';

const srStyle = compose({
	border: 0,
	clip: 'rect(1px, 1px, 1px, 1px)',
	height: 1,
	overflow: 'hidden',
	padding: 0,
	position: 'absolute',
	whiteSpace: 'nowrap',
	width: 1,
});

function SROnly ({ component = 'span', ...props }) {
	const Tag = component;
	return <Tag className={srStyle} {...props} />;
}

const pulse = compose.keyframes({
	'0%': { opacity: 0 },
	'40%': { opacity: 1 },
	'80%': { opacity: 0 },
	'100%': { opacity: 0 },
});

function Wrapper (props) {
	return <div
		className={compose({
			fontSize: '.8rem',
			textAlign: 'center',
			verticalAlign: 'middle',
			width: '5em',
		})}
		{...props}
	/>
}

function Dot ({ delay = 0 }) {
	return <span
		className={compose({
			animation: `${pulse} 1s infinite ease-in-out`,
			animationDelay: `${delay}ms`,
			backgroundColor: 'rgba(0, 0, 0, 0.4)',
			borderRadius: '1em',
			display: 'inline-block',
			height: '1em',
			marginLeft: delay ? '1em' : 0,
			verticalAlign: 'top',
			width: '1em',
		})}
	/>
}

export default function LoadingIndicator () {
	return <Wrapper>
		<Dot />
		<Dot delay={160} />
		<Dot delay={320} />
		<SROnly>Loading...</SROnly>
	</Wrapper>
}
