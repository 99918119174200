import React, {useState} from 'react';
import * as theme from '../theme';
import moment from 'moment';
import {InlineError} from './InputInlineError';
import {Label} from './Label';
import {css} from 'glamor';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import {SingleDatePicker} from 'react-dates';
import OutsideClickHandler from 'react-outside-click-handler';
import calendarIcon from '../icons/calendar.svg';


export function SingleDateInput({
                                    float,
                                    label,
                                    error,
                                    onChange,
                                    date
                                }) {
    const [focused, setFocus] = useState(false);
    const [focusedInput, setFocusedInput] = useState('date')
    const baseStyle = {
        position: 'relative',
        ' .DayPicker': {
            borderRadius: 6,
        },
        ' .CalendarMonthGrid': {
            background: 'transparent',
        },
        ' .CalendarDay__selected': {
            background: theme.blue,
            border: `1px double ${theme.blue}`,
            ':active': {
                background: theme.gray70,
            },
        },
    };

    const floatedBaseStyle = float && {
        ' .DayPicker': {
            position: 'absolute',
            top: 44,
            left: 16,
            zIndex: 999,
        },
    };

    const focusedBaseStyle = focused && {
        ' .DayPicker': {},
    };

    const iconStyle = css({
        position: 'absolute',
        top: '50%',
        right: 16,
        transform: 'translateY(-50%)',
        pointerEvents: 'none',
    });

    const inputStyle = {
        display: 'block',
        width: '100%',
        background: theme.white,
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: theme.gray10,
        borderRadius: 6,
        height: 60,
        fontSize: 22,
        paddingLeft: 16,
        paddingRight: 16,
        marginBottom: 16,
        transition: theme.transitionSnappy,
        '::placeholder': {
            color: theme.gray20,
        },
        ':focus': {
            outline: 'none',
        },
        ':disabled': {
            opacity: 0.5,
        },
    };

    const focusedStyles = focused && {
        borderColor: theme.blue,
    };

    const errorStyle = !!error && {
        color: theme.flavours.danger.foregroundColor,
        borderColor: theme.flavours.danger.highlightColor,
    };

    let dateRangeStr = ''
    if (date) {
        dateRangeStr = `${date.format('YYYY-MM-DD')}`
    }

    function onDateChange(date, doOffset) {
        if (doOffset) {
            date = moment(date).startOf('day')
        }

        onChange(date)
    }

    function onFocusChange(focus) {
        if (focus === null) setFocus(false)
        setFocusedInput(focus || 'date')
    }

    function onBlur() {
        if (date) {
            onDateChange(date, true)
        }
        setFocus(false)
    }

    return (
        <OutsideClickHandler onOutsideClick={onBlur}>
            <div className={css(baseStyle, focusedBaseStyle, floatedBaseStyle)}>
                {label && <Label error={error}>{label}</Label>}
                <div className={css({position: 'relative'})}>
                    <img className={iconStyle} src={calendarIcon} alt=""/>
                    <input
                        className={css(inputStyle, focusedStyles, errorStyle)}
                        value={dateRangeStr}
                        onFocus={() => setFocus(true)}
                        readOnly
                    />
                </div>
                {focused && <SingleDatePicker
                    focused={focusedInput}
                    date={date}
                    onFocusChange={onFocusChange}
                    onDateChange={(date) => onDateChange(date, true)}
                    numberOfMonths={1}
                    isOutsideRange={() => false}
                />}
                {error && <InlineError error={error}/>}
            </div>
        </OutsideClickHandler>
    );
}
