import React from 'react';
import { css } from 'glamor';
import {graphql} from "@apollo/client/react/hoc";

import authManager from '../../../lib/authManager';
import compose from 'lodash/flowRight';
import { configureForm } from '@thinkmill/pragmatic-forms';
import { Alert } from '../../../components/Alert';
import { Input } from '../../../components/Input';
import { Button } from '../../../components/Button';
import { withInstallation } from '../../../components/InstallationContext';

import { createSanctionedPlateQuery } from './queries';
import {SelectInput} from "../../../components/SelectInput";

const validatePlate = ({ plate }) => {
	const errors = {};
	const disallowedInput = /[^a-z0-9 -]/gi;
	if (!plate.trim().length) {
		errors.plate = 'Please enter a plate';
	} else if (disallowedInput.test(plate)) {
		errors.plate = 'Plate should only include letters and numbers';
	}
	return errors;
};

export const CreateForm = compose(
	withInstallation,
	graphql(createSanctionedPlateQuery),
	configureForm({
		initFields: () => ({
			plate: '',
			label: '',
			lotId: null,
			isEnabled: true,
		}),
		validate: validatePlate,
		submit: ({ plate, label, lotId }, props) => {
			// Do some manipulations on the data going in.
			plate = plate.replace(/[^a-z0-9]/gi, '').toUpperCase();
			return props.mutate({
				variables: {
					installationId: props.installation.id,
					createdById: authManager.userId,
					plate,
					label,
					lotId,
				},
			});
		},
		onSuccess: (result, props, form) => {
			props.onCreateSuccess();
			form.reset();
		},
	}),
)(({ form, lotsOptions }) => (
	<form.Form>
		{form.submitError && <Alert flavour="danger">{form.submitError.message}</Alert>}
		<div
			className={css({
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'flex-end',
			})}
		>
			<Input
				label="Licence Plate"
				className={css({ flex: 1, marginRight: 20 })}
				{...form.getFieldProps({ type: 'text', name: 'plate' })}
			/>
			<Input
				label="Description"
				className={css({ flex: 2, marginRight: 20 })}
				{...form.getFieldProps({ type: 'text', name: 'label' })}
			/>
			{lotsOptions && <SelectInput
				label='Lot'
				className={css({ flex: 2, marginRight: 20 })}
				options={[{label: "Default", value: null},
					...lotsOptions.map(l => {return {value: l.id, label: `${l.name} (${l.description})`}})]}
				{...form.getFieldProps({name: 'lotId', type: 'select'})}
			/>}
			<Button type="submit" >Add Plate</Button>
		</div>
	</form.Form>
));
