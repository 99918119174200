import gql from 'graphql-tag'

export const listUsersQuery = gql`
	query listUsers ($installationId: ID!) {
		allUsers(
			filter: {
			installationId: $installationId
			}
		) {
			id
			name
			email
			phone
			isEnabled
			userPermissions(filter: { installationId: $installationId }) {
				id
				isAdmin
			}
		}
		_allUsersMeta(filter: { installationId: $installationId }) {
			count
		}
	}
`

export const userById = gql`
	query userById (
		$installationId: ID!,
		$userId: ID!
	) {
		User(id: $userId) {
			id
			name
			email
			phone
			isEnabled
			createdAt
			userPermissions(filter: { installationId: $installationId }) {
				id
				isAdmin
			}
		}
	}
`

export const createUserMutation = gql`
	mutation createUser (
		$installationId: ID!
		$name: String!
		$email: String!
		$phone: String!
		$password: String!
	) {
		createUser(
			user: {
				installationId: $installationId
				isEnabled: true
				name: $name
				phone: $phone
				email: $email
				password: $password
			}
		) {
			id
		}
	}
`

export const createUserPermissionMutation = gql`
	mutation createUserPermission (
		$installationId: ID!
		$userId: ID!
		$isAdmin: Boolean!
	) {
		createUserPermission(
			userPermission: {
				installationId: $installationId
				userId: $userId
				isAdmin: $isAdmin
			}
		) {
			id
		}
	}
`

export const updateUserMutation = gql`
	mutation updateUser (
		$id: ID!
		$name: String!
		$email: String!
		$phone: String!
		$isEnabled: Boolean!
	) {
		updateUser(
			user: {
				id: $id
				name: $name
				email: $email
				phone: $phone
				isEnabled: $isEnabled
			}
		) {
			name
			email
			phone
			isEnabled
		}
	}
`

export const updateUserPermissionMutation = gql`
	mutation updateUserPermission ($permissionId: ID!, $isAdmin: Boolean!) {
		updateUserPermission(
			userPermission: {
				id: $permissionId
				isAdmin: $isAdmin
			}
		) {
			id
		}
	}
`

export const updateUserPasswordMutation = gql`
	mutation updatePassword (
		$id: ID!
		$password: String!
	) {
		updateUser(user: { id: $id, password: $password }) {
			id
		}
	}
`
