import React, {useEffect} from 'react';
import {css} from 'glamor';
import {useQuery} from "@apollo/client";

import * as theme from '../../../theme';
import {withInstallation} from '../../../components/InstallationContext';
import {FlashMessage} from '../../../components/FlashMessage';
import {Button} from '../../../components/Button';
import {Container, CardContainer} from '../../../components/Container';
import {Left, Right} from '../../../components/Header';
import {Well} from '../../../components/Well';

import {Byline, H1, H2} from '../../../components/Typography';
import {Table, THead, TBody} from '../../../components/Table';
import {Loading, LoadingError} from '../../../components/LoadingFork';
import {ListSanctionedPlateTime} from './queries';
import drivoApollo from "../../../lib/drivoApollo";
import {fmtDate} from "../../../lib/misc";


function Header(props) {
    return <div
        className={css({
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'stretch',
        })}
        {...props}
    />
}


const rowStyle = css({
    '> td': {
        verticalAlign: 'middle',
        height: 60,
        borderBottom: `1px solid ${theme.gray10}`,
        transition: 'all .2s linear',
    },
    '> td:first-child': {paddingLeft: 10},
    '> td:last-child': {paddingRight: 10},
    ':hover > td': {
        background: theme.gray10,
        cursor: 'pointer',
    },
});


function SanctionedTimeRow({sanctionedTime, onClick, timezone}) {

    const {start, end, date, id} = sanctionedTime
    return <tr key={id} className={rowStyle} onClick={onClick}>
        <td>{fmtDate(date, timezone, 'yyyy-MM-dd')}</td>
        <td>{start}</td>
        <td>{end}</td>
    </tr>
}

function SanctionedTimesOnly({match, installation, location, history}) {
    const {data = {}, loading, error, refetch} = useQuery(ListSanctionedPlateTime, {
        client: drivoApollo,
        fetchPolicy: 'no-cache',
        variables: {
            installationId: installation.id
        }
    })
    useEffect(() => {
        refetch()
    }, refetch)

    const sanctionedTime = data?.getSanctionedByFacility || []

    return <div>
        <CardContainer>
            <Container size="large" spaceAbove="small" spaceBelow="small">
                <Header>
                    <Left>
                        <H1>Sanctioned Only Entry Times</H1>
                    </Left>
                    <Right>
                        {!loading && sanctionedTime.length !== 0 && (
                            <Button to={`${match.url}/new`} style={{'background-color': '#3127B8'}}>Add new
                                time</Button>
                        )}
                    </Right>
                </Header>
                <Byline>During these times, only sanctioned plates (bookings and whitelist) will be allowed entry.</Byline>
            </Container>
            <Container spaceBelow="large">
                <FlashMessage locationState={location.state}/>
                {loading ? <Loading/> : null}
                {error ? <LoadingError error={error}/> : null}
                {!loading && sanctionedTime.length === 0 && <Well>
                    <div>
                        <H2 spaceBelow="large">No data available</H2>
                        <Button spaceBelow="none" to={`${match.url}/new`}>
                            Create a new time now
                        </Button>
                    </div>
                </Well>}
                {!loading && <Table>
                    <THead>
                        <tr>
                            <th>Date</th>
                            <th>Start</th>
                            <th>End</th>
                        </tr>
                    </THead>
                    <TBody>{sanctionedTime.map((time) => {
                        return <SanctionedTimeRow key={time.id} timezone={installation.timeZoneName} sanctionedTime={time} onClick={() => {
                            history.push(`${match.url}/${time.id}`);
                        }}/>
                    })}</TBody>
                </Table>}
            </Container>
        </CardContainer>

    </div>
}

export default withInstallation(SanctionedTimesOnly)
