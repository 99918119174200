import React, {useEffect, useState} from 'react'
import {withInstallation} from "../../../components/InstallationContext";
import {Helmet} from "react-helmet";
import {Container, CardContainer, ColContainer} from '../../../components/Container'
import {H1, H2} from "../../../components/Typography";
import {Table, TBody, THead} from "../../../components/Table";
import {VoucherDetails,createVoucheryMutation,GetVouchers} from './queries'
import {CellPill} from '../../../components/StatusPill'
import {Well} from "../../../components/Well";
import ReactModal from 'react-modal';
import {SearchInput} from "../../../components/SearchInput";
import {css} from "glamor";
import {Input} from "../../../components/Input";
import {HollowButton} from "../../../components/Button";
import Rating from '@material-ui/lab/Rating';
import Typography from "@material-ui/core/Typography";
import gql from 'graphql-tag';
import {useMutation, useQuery} from "@apollo/client";
import {createBookingMutation} from "../../Booking/mutations";
import drivoApollo from "../../../lib/drivoApollo";


function VoucheryRow({voucher}) {
    return (
        <>
            <td style={{textAlign: "center"}}><CellPill width='60px' color='#FFA800'
                                                        size='tableCell'> {voucher.id}</CellPill></td>
            <td style={{textAlign: "center"}}><CellPill color='#37B534' width='140px'
                                                        size='tableCell'>{voucher.name}</CellPill></td>
            <td style={{textAlign: "center"}}><CellPill width='60px' color='#FF4773'
                                                        size='tableCell'>{voucher.left}</CellPill></td>
            <td style={{textAlign: "center"}}><CellPill width='180px' color='#45B5F4'
                                                        size='tableCell'>{voucher.location}</CellPill></td>
            <td style={{textAlign: "center"}}><CellPill width='60px' color='#08515B' size='tableCell'> {voucher.rating}</CellPill></td>
            <td style={{textAlign: "center"}}><CellPill width='60px' color='#1710F3' size='tableCell'>{voucher.steps}</CellPill>
            </td>
            <td style={{textAlign: "right"}}> <CellPill color='#37B534' width='70px'
                                                        size='tableCell'>{voucher.image}</CellPill></td>
        </>
    )
}

function VoucheryAdmin({installation}) {
    const [voucher, setVoucher] = useState()
    const [searchTerm, setSearchTerm] = useState()
    const [isFormOpen, setFormOpen] = useState(false)
    const [selectedVoucher, setSelectedVoucher] = useState({})
    const [newRating,setNewRating] = useState(0)
    const [newSteps,setNewSteps] = useState(10)
    const [createVouchery,{error}] = useMutation(createVoucheryMutation,{client: drivoApollo})
    const[voucherId,setIds]=useState([])


    const {data} = useQuery(GetVouchers,{
        client: drivoApollo,
        variables: {id:voucherId},
        fetchPolicy: "no-cache",
    })
    SetData()
    function SetData(){
        const dbVouchersData = (() => {
            if (
                data &&
                data.getVouchers &&
                data.getVouchers.vouchers
            ) {
                return data.getVouchers.vouchers;
            } else {
                return 0;
            }
        })();
        if(dbVouchersData.length > 0){
            // console.log("dbVouchersData ", dbVouchersData)
            // console.log("voucher from vouchery  ", voucher)
            voucher.map(vou=>{
                dbVouchersData.map(dbvoucher=>{
                    if(dbvoucher.id === vou.id){
                        vou.rating=dbvoucher.rating
                        vou.steps=dbvoucher.steps
                    }
                })
            })
        }

    }

    function onChange(e,state){
        console.log("e ",e)
        console.log("state ",state)
        if(state==='rating'){
            setNewRating(e)
        }else if(state==='steps'){
           setNewSteps(e)
        }
    }
    useEffect(() => {

        (async () => {
            await VoucherDetails(installation.brandingKey)
                .then(data => {
                    setVoucher(data)
                    setSearchTerm(data)
                    let tempId=[]
                    data.map(d=>{
                        tempId.push(d.id)
                    })
                    setIds(tempId)
                })

        })()
    }, [])

    function onSearch(e) {
        const value = e.target.value
        const lowerCasedValue = value.toLowerCase().trim();
        let copyVouchers = [...searchTerm]
        if (lowerCasedValue === "") setVoucher(copyVouchers);
        else {
            const filteredData = copyVouchers.filter(item => {
                return Object.keys(item).some(key =>
                    item[key].toString().toLowerCase().includes(lowerCasedValue)
                );
            });
            setVoucher(filteredData);
        }
    }

    function handleRowClick(voucher) {
        console.log("handleRowClick", voucher)
        setSelectedVoucher(voucher)
        setNewSteps(voucher.steps)
        setNewRating(voucher.rating)
        setFormOpen(true)
    }

    function closeModal() {
        setFormOpen(false)
        setSelectedVoucher({})
        setNewSteps(0)
        setNewRating(0)
    }

    async function OnSave() {

        if(newRating > 0 && newSteps > 0){

            try{

                const {data} = await createVouchery({
                    variables: {
                        id: selectedVoucher.id,
                        rating: newRating,
                        steps: parseInt(newSteps)
                    }
                })
                closeModal()

            }catch (e) {

                console.log("error ",e)

            }

        }
    }

    return (
        <CardContainer>
            <Helmet>
                <title>Vouchery Admin</title>
            </Helmet>
            <Container size="large" spaceAbove="small" spaceBelow="none">
                <H1>Vouchery Admin</H1>
                <SearchInput
                    placeholder="Search "
                    onChange={onSearch}
                />

                {voucher ?
                    <div>
                        <Table>
                            <THead>
                                <tr>
                                    <th style={{textAlign: "center"}}>Campaign ID</th>
                                    <th style={{textAlign: "center"}}>Campaign Name</th>
                                    <th style={{textAlign: "center"}}>Vouchers Left</th>
                                    <th style={{textAlign: "center"}}>Location</th>
                                    <th style={{textAlign: "center"}}>Rating</th>
                                    <th style={{textAlign: "center"}}>Steps</th>
                                    <th style={{textAlign: "center"}}>Image</th>
                                </tr>
                            </THead>

                            {voucher.map(vou => (
                                <TBody onClick={() => handleRowClick(vou)} style={{cursor: 'pointer'}}>
                                    <VoucheryRow

                                        key={vou.id}
                                        voucher={vou}/>
                                </TBody>
                            ))}

                        </Table>
                    </div>
                    :
                    <Well>
                        <div>
                            <H2 spaceAbove="large" spaceBelow="large">
                                No Vouchers available
                            </H2>
                        </div>
                    </Well>
                }

                <ReactModal
                    isOpen={isFormOpen}
                    onRequestClose={closeModal}
                    className={css({
                        display: 'block',
                        background: 'white',
                        margin: 10,
                        padding: 20,
                        borderRadius: 6,
                        outline: 'none',
                        minWidth: 400,
                    }).toString()}
                    overlayClassName={css({
                        backgroundColor: 'rgba(0,0,0,0.2)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'fixed',
                        zIndex: 100000,
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                    }).toString()}
                >
                    <h1 className={css({textAlign: 'left',color:'#37B534'})}>{selectedVoucher.name}</h1>
                    <Typography component="h4">Rating</Typography>
                        <Rating
                            style={{fontSize:'xxx-large',marginBottom:'12px'}}
                            name="simple-controlled"
                            value={newRating}
                            onChange={(event, newValue) => {
                                onChange(newValue,'rating');
                            }}
                        />
                        <Input
                            required
                            type="number"
                            style={{width:'400px'}}
                            label="Steps"
                            value={newSteps}
                            onValueChange={e => onChange(e, 'steps')}
                        />

                    <Typography component="h4">Select Image</Typography>
                        <input type="file" accept="image/*" style={{fontSize:'large',marginTop:'10px'}}/>

                    <ColContainer>
                        <HollowButton
                            className={css({marginTop: 30})}
                            onClick={OnSave}
                        >
                            Save
                        </HollowButton>
                        <HollowButton
                            className={css({marginTop: 30})}
                            onClick={closeModal}
                        >
                            cancel
                        </HollowButton>
                    </ColContainer>
                </ReactModal>


            </Container>
        </CardContainer>
    )
}

export default withInstallation(VoucheryAdmin);
