import gql from 'graphql-tag'
import {useQuery} from "@apollo/client";
import drivoApollo from "../../../lib/drivoApollo";

const MainCampaignId = 6
const url = `https://mayar.vouchery.io/api/v2.0/`;
const auth = "Basic <aGFtemFhc2lmMDk4N0BnbWFpbC5jb206bWFsaXJjYW50dDwz>"
const options = {method: 'GET', headers: {Accept: 'application/json', Authorization: auth}};


export const VoucherDetails = async (brandingName) => {
    let data = []

    const MainCampaign = await fetch(`${url}campaigns/${MainCampaignId}`, options)
        .then(res => res.json())
        .then(json => {
            return json
        })
        .catch(err => console.error('error:' + err));

    MainCampaign.children.map(sub => {
        let loc
        let rewardFlag = false
        sub.rules.map((rule) => {
            if (rule.rule_type === "AdditionalCategory") {
                if(rule.data.tags[0] ===brandingName){
                    loc = rule.data.tags[0]
                    rewardFlag=true
                }

            }

        })

        if(rewardFlag){
            let SubData = {
                id: sub.id,
                name: sub.name,
                left: sub.vouchers_count - sub.vouchers_distributed_count,
                location: loc,
                rating:0,
                steps:0,
                image:"false"
            }
            data.push(SubData)
        }
    })

    return data
}
export const GetVouchers = gql`
    query Vouchery($id:[Int!]!)
    {
              getVouchers(id:$id){
                vouchers{
                  id
                  steps
                  rating
                }
              }
    }
`


export const createVoucheryMutation = gql`
    mutation Vouchery(
        $id:Int!,
        $rating:Int!,
        $steps:Int!
    ){
        createVouchery(id:$id,rating:$rating,steps:$steps){
                id
                rating
                steps
  }
  
}
`
