import React from 'react'
import {Button} from '../../../components/Button';
import {CardContainer, Container} from '../../../components/Container';
import {Byline, H1, H2} from '../../../components/Typography';
import {Helmet} from 'react-helmet';
import {Left} from '../../../components/Header'
import {Loading, LoadingError} from '../../../components/LoadingFork'
import {fmtDate} from "../../../lib/misc";
import {useQuery} from "@apollo/client"
import {withInstallation} from '../../../components/InstallationContext';

import ParkingLotDetailsForm from './DetailsForm'
import {lotById} from './queries';
import {
    Header,
} from '../RateCards/components/common'

function ParkingLotDetails({appConfig, installation, match, location, history}) {
    const {lotId} = match.params
    const {data, loading, error: fetchError} = useQuery(lotById, {
        fetchPolicy: 'no-cache',
        variables: {lotId},
    })

    const error = fetchError

    if (loading) return (
        <CardContainer>
            <Loading/>
        </CardContainer>
    )
    if (error) return (
        <CardContainer>
            <LoadingError error={error}/>
        </CardContainer>
    )

    function onDone() {
        console.log('OD')
        history.goBack()
    }

    const lot = data.Lot
    if (!lot) {
        return (
            <CardContainer>
                <Container spaceBelow='medium'>
                    <H1>Not found</H1>
                    <Button onClick={onDone}>Back to parking lots</Button>
                </Container>
            </CardContainer>
        )
    }

    return (
        <CardContainer>
            <div>
                <Helmet>
                    <title>{`${lot.name}`}</title>
                </Helmet>
                <Container spaceBelow='medium'>

                    <Header>
                        <Left>
                            <div>
                                <H1 spaceBelow='none'>{lot.name}</H1>
                                <Byline>
                                    {lot.createdBy && <span>Created by {lot.createdBy.name} </span>}
                                    <span>on {fmtDate(lot.createdAt, installation.timeZoneName, 'd MMM yyyy')}</span>
                                </Byline>
                            </div>
                        </Left>
                    </Header>
                </Container>
                <Container spaceAbove='medium' spaceBelow='medium'>
                    <H2>Lot details</H2>
                    <ParkingLotDetailsForm
                        installation={installation}
                        lot={lot}
                        onSubmit={onDone}
                        onCancel={onDone}
                    />
                </Container>

            </div>
        </CardContainer>
    )
}

export default withInstallation(ParkingLotDetails)
