import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {Grid} from "@material-ui/core";
import moment from "moment/moment";
import Card from "@material-ui/core/Card";
import React, {useState} from 'react'
import {useQuery} from "@apollo/client";
import gql from "graphql-tag";
import drivoApollo from "../lib/drivoApollo";
import {useStyles} from "../pages/Dashboard";
import {makeStyles} from "@material-ui/core/styles";
import {NavLink} from "react-router-dom";
import {CellPill} from "./StatusPill";
import CalendarHeatmap from "react-calendar-heatmap";

import 'react-calendar-heatmap/dist/styles.css';
import {DateTime} from "luxon";

export const tableStyles = makeStyles(() => ({
    cardContentRoot: {
        width: "100%",

    },
    root: {
        "& table": {
            marginTop: 20,
            width: "100%",
            borderCollapse: "collapse",
            "& tr": {
                borderBottom: "1px solid #d2d6da",
                textAlign: "center",
                borderCollapse: "collapse",
                "& td": {
                    borderBottom: "1px solid #EAEDEF",
                    padding: "5px 0",
                    borderCollapse: "collapse",
                }
            },
            "& th": {
                borderBottom: "1px solid #d2d6da",
                borderCollapse: "collapse",
                color: "#999",
                "& td": {
                    borderBottom: "1px solid #EAEDEF",
                    padding: "5px 0",
                    borderCollapse: "collapse",
                }
            }
        }
    }
}))

function getSvgPadding(value) {
    const padding =  value < 10 ? 8 : value < 100 ? 7 : 6
    return padding;
}

export function BookingSummary({installation}) {

    const [date, setDate] = useState(DateTime.now().setZone(installation.timeZoneName).toFormat('YYYY-MM-DD'));
    const classes = useStyles();
    const tableClasses = tableStyles();


    const {data: monthlyBookings, loading: loadingMonthlyBookings} = useQuery(gql`
        query MonthlyBookings($installationId:ID!)
        {
            getMonthlyBookings(installationId:$installationId){
                date
                count
                bookings {
                    plate
                    startDay
                    endDay
                    id
                }
            }
        }
    `, {
        client: drivoApollo,
        variables: {installationId: installation.id},
        fetchPolicy: "no-cache",
    });

    if (loadingMonthlyBookings) {
        return null;
    }

    let monthlyBookingsData = monthlyBookings?.getMonthlyBookings

    const getBookingsForDay = (day) => {
        const dayBookings = monthlyBookings.getMonthlyBookings.filter(d => {
            return d.date === day
        });
        if (dayBookings.length > 0) {
            return dayBookings[0];
        }
        return null;
    }

    const getDayComponent = (day) => {
        const bookingsSummaryForDay = getBookingsForDay(day);
        if (bookingsSummaryForDay == null) {
            return null;
        }
        return <>

            <p>
                <b>Date: </b>
                <span>{bookingsSummaryForDay.date}</span>
            </p>
            <p>
                <b>Total Bookings: </b>
                <span>{bookingsSummaryForDay.count}</span>
            </p>
            {
                bookingsSummaryForDay.count > 0 && <table>
                    <tbody>
                    <tr>
                        <th>Plate</th>
                        <th>Start</th>
                        <th>End</th>
                    </tr>
                    </tbody>
                    {bookingsSummaryForDay.bookings.map(b => {
                        function stringToZonedString(dateString, timeZone){
                            return DateTime
                                .fromISO(new Date(dateString).toISOString(), {locale: "en-AU"})
                                .setZone(timeZone).toFormat("d MMM yyyy HH:mm ZZZZ")
                        }
                        return <tr>
                            <td><NavLink to={`/${installation.brandingKey}/bookings/${b.id}`}>
                                <CellPill color='#37B534' width='105px' size='small'>{b.plate.toUpperCase()}</CellPill>
                            </NavLink></td>
                            <td>{stringToZonedString(b.startDay, installation.timeZoneName)}</td>
                            <td>{stringToZonedString(b.endDay, installation.timeZoneName)}</td>
                        </tr>

                    })}
                </table>
            }

        </>
    }


    return <Card className={classes.card}
                 style={{display: 'flex', minHeight: 600, justifyContent: "center", alignItems: "center"}}>
        <CardContent className={tableClasses.cardContentRoot}>
            <Typography variant="subtitle2"
                        style={{fontSize: '30px', textAlign: 'center', fontWeight: 700}}>
                Future Bookings Summary
            </Typography>
            <Grid container spacing={1} className={tableClasses.root}>
                <Grid item xs={12}>
                    <CalendarHeatmap
                        startDate={new Date(moment().subtract(1, 'day').format('YYYY-MM-DD'))}
                        endDate={new Date(moment().add(1, 'months').format('YYYY-MM-DD'))}
                        values={monthlyBookingsData}
                        horizontal={false}
                        showWeekdayLabels={true}
                        showMonthLabels={false}
                        titleForValue={(value) => {
                            return (
                                <>
                                    <p>
                                        <b>Date: </b>
                                        <span>{value?.date}</span>
                                    </p>

                                    <p>
                                        <b>, Bookings: </b>
                                        <span>{value?.count}</span>
                                    </p>
                                </>

                            )
                        }}
                        classForValue={(value) => {
                            if (!value) {
                                return "color-empty";
                            }
                            return value.count > 4 ? 'color-github-4' : `color-github-${value.count}`;
                        }}
                        onClick={(value) => {
                            setDate(moment(value.date).format('YYYY-MM-DD'))
                        }}
                        transformDayElement={(element, value) => {
                            if (!value) return <></>;

                            return (
                            <g style={{cursor: "pointer"}} onClick={() => setDate(moment(value.date).format('YYYY-MM-DD'))}>
                                {element}
                                <text
                                    x={element["props"].x + 1}
                                    y={element["props"].y + 3}
                                    style={{
                                        fontSize: "2px",
                                        fill: "#000",
                                        textAlign: "center"
                                    }}
                                >
                                    {moment(value.date).format('D MMM')}
                                </text>
                                <text
                                    x={element["props"].x + getSvgPadding(value.count)}
                                    y={element["props"].y + 8}
                                    style={{
                                        fontSize: "2px",
                                        fill: "#000",
                                        textAlign: "center"
                                    }}
                                >
                                    {value.count}
                                </text>
                            </g>
                        )}}
                    />
                </Grid>

                <Grid item xs={12} style={{paddingTop: "20px", paddingBottom: '40px'}}>
                    {getDayComponent(date)}
                </Grid>


            </Grid>
        </CardContent>
    </Card>
}
