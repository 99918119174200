import React from 'react';
import {Helmet} from 'react-helmet';
import {css} from 'glamor';
import {useQuery} from "@apollo/client";

import * as theme from '../../../theme';
import {Button} from '../../../components/Button'
import {CardContainer, Container} from '../../../components/Container';
import {FlashMessage} from '../../../components/FlashMessage';
import {H1, H2} from '../../../components/Typography';
import {Left, Right} from '../../../components/Header';
import {Loading, LoadingError} from '../../../components/LoadingFork';
import {Table, TBody, THead} from '../../../components/Table';
import {Well} from '../../../components/Well';
import {format} from 'date-fns'
import {withInstallation} from '../../../components/InstallationContext';
import {Header} from '../RateCards/components/common'
import {allLots} from "./queries";
import {fmtDate} from "../../../lib/misc";

const rowStyle = css({
    '> td': {
        verticalAlign: 'middle',
        height: 60,
        borderBottom: `1px solid ${theme.gray10}`,
        transition: 'all .2s linear',
    },
    '> td:first-child': {paddingLeft: 10},
    '> td:last-child': {paddingRight: 10},
    ':hover > td': {
        background: theme.gray10,
        cursor: 'pointer',
    },
});

const LINK_CSS = css({
    cursor: 'pointer',
    color: theme.gray20,
    textTransform: 'lowercase !important',
    ':hover': {
        textDecoration: 'underline',
    },
});

function ParkingLotListItem({timezone, lot, onClick}) {
    const {
        id,

        name,
        description,
        capacity,
        createdAt,
    } = lot;


    return <tr key={id} onClick={onClick} className={rowStyle}>
        <td>
            {name}
        </td>
        <td>{description}</td>
        <td>{capacity}</td>
        <td>{fmtDate(createdAt, timezone, 'd MMM yyyy')}</td>
    </tr>
}

function ParkingLotList({installation, match, location, history}) {


    const {data = {}, loading, error} = useQuery(allLots, {
        fetchPolicy: 'no-cache',
        variables: {
            installationId: installation.id
        }
    })

    function onClickLot(lot) {
        history.push(`lots/${lot.id}`);
    }

    const lots = data.allLots || []

    return <div>

        <CardContainer>
            <Helmet>
                <title>Parking Lots</title>
            </Helmet>
            <Container size="large" spaceAbove="small" spaceBelow="medium">
                <Header>
                    <Left>
                        <H1>Parking Lots</H1>
                    </Left>

                    <Right>
                            <Button to={`${match.url}/new`} style={{backgroundColor: '#3127B8', cursor: 'pointer'}}>New
                                Parking Lot</Button>
                    </Right>
                </Header>
            </Container>
            <Container spaceBelow="large">
                <FlashMessage locationState={location.state}/>
                <Table>
                    <THead>
                        <tr>
                            <th>Name</th>
                            <th>Description</th>
                            <th>Capacity</th>
                            <th>Created Date</th>
                        </tr>
                    </THead>
                    <TBody>
                        {lots.length ? lots.map((lot) => {
                            return <ParkingLotListItem
                                key={lot.id}
                                lot={lot}
                                onClick={() => onClickLot(lot)}
                                timezone={installation.timeZoneName}
                            />
                        }) : <tr>
                            <td colSpan={7}>
                                {loading ? <Loading/> : null}
                                {error ? <LoadingError error={error}/> : null}
                                {loading ? null : <Well>
                                    <div>
                                        <H2 spaceBelow="large">No parking lots available</H2>
                                        <Button spaceBelow="none" to={`${match.url}/new`}>
                                            Create a parking lot now
                                        </Button>
                                    </div>
                                </Well>}
                            </td>
                        </tr>}
                    </TBody>
                </Table>
            </Container>
        </CardContainer>

    </div>
}

export default withInstallation(ParkingLotList)
