import React from 'react';
import { compose, css } from 'glamor';
import { Alert } from './Alert';
import { gray50 } from '../theme';
import LoadingIndicator from './LoadingIndicator';

function View (props) {
	return <div
		className={compose({
			alignItems: 'center',
			display: 'flex',
			height: 400,
			justifyContent: 'center',
		})}
		{...props}
	/>
}

function StatusMessage ({ children }) {
	return <div
		className={css({
			padding: 20,
			color: gray50,
			textAlign: 'center',
		})}
	>
		{children}
	</div>
}

export function Loading ({ message }) {
	return <View>
		<div className={css({ textAlign: 'center' })}>
			<LoadingIndicator />
			{message && <StatusMessage>{message}</StatusMessage>}
		</div>
	</View>
}

export function LoadingError ({ error }) {
	return <Alert spaceAbove="medium" flavour="danger">
		{error.message || error}
	</Alert>
}

export function LoadingFork ({
	isLoading,
	error,
	statusMessage,
	graphqlQuery,
	render = () => null,
	renderLoading = Loading,
	renderError = LoadingError,
}) {
	if (isLoading || (graphqlQuery && graphqlQuery.loading)) {
		return renderLoading({ statusMessage });
	}

	if (error || (graphqlQuery && graphqlQuery.error)) {
		return renderError({
			error: error || graphqlQuery.error
		});
	}

	return render();
}
