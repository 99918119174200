import React from 'react'
import {Button, NakedButton} from '../../../components/Button'
import {Container} from '../../../components/Container'
import {LoadingError} from '../../../components/LoadingFork'
import {H3, HR} from '../../../components/Typography'
import {Input} from '../../../components/Input'
import {Table, TBody} from '../../../components/Table'
import {css} from 'glamor'
import {useFormState} from 'react-use-form-state'
import {useMutation} from "@apollo/client"
import {createLotMutation, updateLotMutation} from './queries'
import * as theme from "../../../theme";

const CF_CSS = css({
    display: 'flex',
    alignItems: 'center',
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: '#FAFAFA',
    borderRadius: 6,
    fontSize: 22,
    fontWeight: 'bold',
    height: 60,
    paddingLeft: 16,
    paddingRight: 16,
    marginBottom: 16,
})

const ALIGN_LEFT = css({textAlign: 'left !important'})
const ALIGN_RIGHT = css({textAlign: 'right !important'})

function TextInputRow({name, ...props}) {
    return <tr>
        <td>
            <span className={ALIGN_LEFT}>{name}</span>
        </td>
        <td className={ALIGN_RIGHT}>
            <Input
                {...props}
            />
        </td>
    </tr>
}

function TextAreaRow({name, ...props}) {
    return <tr>
        <td>
            <span className={ALIGN_LEFT}>{name}</span>
        </td>
        <td className={ALIGN_RIGHT}>
            <textarea
                className={css(
                    {
                        display: 'block',
                        width: '100%',
                        background: theme.white,
                        borderWidth: '2px',
                        borderStyle: 'solid',
                        borderColor: '#EAEDEF',
                        borderRadius: '6px',
                        fontSize: '18px',
                        transition: theme.transitionSnappy,
                        ':focus': {
                            outline: 'none',
                            borderColor: theme.blue,
                        },
                        ':disabled': {
                            opacity: 0.5,
                        },
                        '::placeholder': {
                            color: theme.gray20,
                        },
                    },
                )}

                {...props}
            />
        </td>
    </tr>
}

export default function DetailsForm({
                                        installation,
                                        lot,
                                        onSubmit,
                                        onCancel
                                    }) {
    const [{
        errors,
        values
    }, {text, checkbox, select, raw, number}] = useFormState({
        name: lot ? lot.name : '',
        description: lot ? lot.description : '',
        capacity: lot ? lot.capacity : null
    })

    const [createLot, {error: submitError}] = useMutation(createLotMutation)
    const [updateLot, {error: submitErrorUpdate}] = useMutation(updateLotMutation)


    async function _onSubmit() {
        if (lot) {
            const {data} = await updateLot({
                variables: {
                    id: lot.id,
                    ...values,
                    capacity: values.capacity ? Number(values.capacity) : null
                }
            })
            console.log('data', data);
            return onSubmit(data.updateLot)
        } else {
            const {data} = await createLot({
                variables: {
                    installationId: installation.id,
                    ...values,
                    capacity: values.capacity ? Number(values.capacity) : null
                }
            })
            return onSubmit(data.createLot)

        }
    }

    const isCreating = !lot
    const hasErrors = Object.values(errors).some(x => x)
    const canSubmit = !hasErrors


    return <div>
        {submitError && <LoadingError error={submitError}/>}
        {submitErrorUpdate && <LoadingError error={submitErrorUpdate}/>}
        {hasErrors && <LoadingError error={Object.values(errors)[0]}/>}

        {isCreating ? <H3>Parking Lot Details</H3> : null}

        <HR spaceAbove='medium' spaceBelow='medium'/>

        <Table>
            <TBody>


                <TextInputRow key={"name"}
                              {...text('name')}
                              rows={1}
                              name={"Name"}/>
                <TextAreaRow
                    {...text('description')}
                    rows="3"
                    cols="30"
                    key={"description"}
                    name={"Description"}
                />
                <TextInputRow key={"capacity"}
                              {...number('capacity')}
                              rows={1}
                              name={"Capacity"}/>
            </TBody>
        </Table>


        <Container>
            <Button block type='submit' disabled={!canSubmit}
                    onClick={_onSubmit}>{isCreating ? 'Create' : 'Update card'}</Button>{' '}
            <NakedButton block flavour='gray' onClick={onCancel}>Cancel</NakedButton>
        </Container>
    </div>
}
