import gql from 'graphql-tag';

export const getProfile = gql`
	query profile {
		loggedInUser {
			id
			name
			email
			phone
		}
	}
`;

export const updatePasswordQuery = gql`
	mutation updatePassword($userId: ID!, $password: String!) {
		updateUser(user: { id: $userId, password: $password }) {
			id
		}
	}
`;

export const updateUserQuery = gql`
	mutation updateUser($userId: ID!, $name: String, $email: String, $phone: String) {
		updateUser(user: { id: $userId, name: $name, email: $email, phone: $phone }) {
			name
			email
			phone
		}
	}
`;
