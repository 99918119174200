import React, { useState } from 'react';
import ReactModal from 'react-modal';
import compose from 'lodash/flowRight';

import { Button } from '../../components/Button';
import { GraphqlErrorMessage } from '../../components/ErrorMessage';
import { H2 } from '../../components/Typography';
import { Input } from '../../components/Input';
import { configureForm } from '@thinkmill/pragmatic-forms';
import { createRefundMutation } from './mutations';
import { css } from 'glamor';
import { graphql } from "@apollo/client/react/hoc";
import { withInstallation } from "../../components/InstallationContext";

const withForm = configureForm({
	initFields: () => ({
		amountAud: '',
	}),
	validate: (formData) => {
		const errors = {};

		const amount = parseFloat(formData.amountAud);
		if (Number.isNaN(amount)) {
			errors.amountAud = 'Amount is not valid';
		} else if (amount <= 0) {
			errors.amountAud = 'Please specify an amount';
		}
		return errors;
	},
	submit: (formData, props) => {
		return props.requestRefund({
			variables: {
				installationId: props.installationId,
				bookingId: props.bookingId,
				amountAud: formData.amountAud,
			}
		});
	},
	onSuccess: (res, props) => {
		props.onSuccess();
	},
});

const KNOWN_ERROR_CODES = {
	invalidAmount: 'Please enter a valid amount',
	amountTooLarge: 'You cannot refund more than the amount charged',
};

const RequestRefundForm = compose(
	graphql(createRefundMutation, { name: 'requestRefund' }),
	withForm,
)(({ installation, form, onCancel }) => (
	<form.Form>
		<H2 spaceAbove="none">Request refund</H2>
		{form.submitError && (
			<GraphqlErrorMessage error={form.submitError} knownErrorCodes={KNOWN_ERROR_CODES} />
		)}
		<Input
			label={`Amount (${installation.currency || 'AUD'})`}
			placeholder="10.00"
			{...form.getFieldProps({ type: 'text', name: 'amountAud' })}
		/>
		<Button spaceBelow="none" type="submit" disabled={form.loading}>
			Submit
		</Button>
		<Button
			spaceBelow="none"
			mode="naked"
			flavour="gray"
			onClick={onCancel}
			disabled={form.loading}
		>
			Cancel
		</Button>
	</form.Form>
));

function SuccessMessage ({ onClose }) {
	return <div className={css({ textAlign: 'center' })}>
		<H2 spaceAbove="medium">Success</H2>
		<p>The refund request has been sent.</p>
		<Button spaceAbove="medium" onClick={onClose}>
			Done
		</Button>
	</div>
}

function _RequestRefund ({ installation, booking, onRefund }) {
	const [isFormOpen, setFormOpen] = useState(false)
	const [success, setSuccess] = useState(false)

	function openForm () { setFormOpen(true) }
	function closeForm () {
		setFormOpen(false)
		setSuccess(false)
	}

	function onSuccess () {
		setSuccess(true)
		onRefund()
	}

	return (
		<div>
			<Button block mode="naked" flavour="gray" onClick={openForm}>
				Request refund
			</Button>
			<ReactModal
				isOpen={isFormOpen}
				onRequestClose={closeForm}
				className={css({
					display: 'block',
					background: 'white',
					margin: 10,
					padding: 20,
					borderRadius: 6,
					outline: 'none',
					minWidth: 400,
				}).toString()}
				overlayClassName={css({
					backgroundColor: 'rgba(0,0,0,0.2)',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					position: 'fixed',
					zIndex: 100000,
					top: 0,
					right: 0,
					bottom: 0,
					left: 0,
				}).toString()}
			>
				{success ? (
					<SuccessMessage onClose={closeForm} />
				) : (
					<RequestRefundForm
						installation={installation}
						onSuccess={onSuccess}
						onCancel={closeForm}
						installationId={booking.installationId}
						bookingId={booking.id}
					/>
				)}
			</ReactModal>
		</div>
	);
}

export default withInstallation(_RequestRefund);
