const contactPerson = [
    {
        installationId: 'dfb58292-f646-47e7-acef-62fed8fdbb68',
        installationName: 'Qt Gold Coast',
        contactName: 'Carparkit @ support@carparkit.com.au'
    },
    {
        installationId: 'dbe6a81d-4e26-4eb2-a25d-95e861e91574',
        installationName: 'Adina Darling Harbour',
        contactName: 'Carparkit @ support@carparkit.com.au'
    },
    {
        installationId: '01bc6fe0-811f-4955-80b0-66a3596fd22a',
        installationName: 'Mercure Newcastle Airport',
        contactName: 'Carparkit @ support@carparkit.com.au'
    },
    {
        installationId: '3b1ad91e-d286-464c-9a8b-022f8310369e',
        installationName: 'APX Parramatta',
        contactName: 'Carparkit @ support@carparkit.com.au'
    },
    {
        installationId: '76e54f42-bb78-413d-a7f6-8c12af766dcf',
        installationName: 'Novotel Wollongong Northbeach',
        contactName: 'Carparkit @ support@carparkit.com.au'
    },
    {
        installationId: '792e3266-e9a1-4478-aca4-6121d6646914',
        installationName: 'Rydges Sydney Central',
        contactName: 'Carparkit @ support@carparkit.com.au'
    },
    {
        installationId: '85e44fb2-f61a-4439-8eb4-ed0bb11cb71c',
        installationName: 'Vibe Gold Coast',
        contactName: 'Carparkit @ support@carparkit.com.au'
    },
    {
        installationId: '8aa35748-5164-49a6-9357-96853a091cf9',
        installationName: 'Carparkit',
        contactName: 'Carparkit @ support@carparkit.com.au'
    },
    {
        installationId: '8c72ceb2-7454-4cd6-9cea-5a2dcefd8fa0',
        installationName: 'Esplanade Hotel Fremantle by Rydges',
        contactName: 'Carparkit @ support@carparkit.com.au'
    },
    {
        installationId: '92e345e9-6f3a-443c-9753-be3c79780dd2',
        installationName: 'Quest Liverpool',
        contactName: 'Carparkit @ support@carparkit.com.au'
    },
    {
        installationId: '9d234ed7-c871-463a-98d3-7549362134bf',
        installationName: 'Novotel Brighton-Le Sands',
        contactName: 'Carparkit @ support@carparkit.com.au'
    },
    {
        installationId: '9e26daca-792d-4fbb-b6b3-d67f9a0b4ee7',
        installationName: 'Hotel X',
        contactName: 'Carparkit @ support@carparkit.com.au'
    },
    {
        installationId: 'a543abc8-0364-45c3-87cf-c2866c90d903',
        installationName: 'Novotel Sydney Parramatta',
        contactName: 'Carparkit @ support@carparkit.com.au'
    },
    {
        installationId: 'ba83305f-d135-419a-b0f0-59821ecd091b',
        installationName: 'Four Points by Sheraton',
        contactName: 'Carparkit @ support@carparkit.com.au'
    },
    {
        installationId: 'c7a04c36-bb36-4cc3-80ca-dc41904c3c50',
        installationName: 'Rydges Canberra',
        contactName: 'Carparkit @ support@carparkit.com.au'
    },
    {
        installationId: 'd8db1e4a-33c6-4816-baf8-c02278a64295',
        installationName: 'The Rochester (testing)',
        contactName: 'Carparkit @ support@carparkit.com.au'
    },
    {
        installationId: 'd9c4a336-1cfb-4030-bc24-6620108f6671',
        installationName: 'Rydges North Sydney',
        contactName: 'Carparkit @ support@carparkit.com.au'
    }
]

const choices = [
    'Gate Not Opening',
    'Refund',
    'Gate Refund',
    'Power Outage',
    'Internet Outage',
    'Not Recognizing Plate',
    'Payment at Gate Not Processing',
    'Entry Gate Not Opening',
    'Reset NUC and Still Not Working',
    'Not Opening for Vehicles but Opening for Intercom',
    'Gate Damaged',
    'Page Not Loading',
    'Console Streams Not Showing',
    'Console Not Allowing Booking',
    'QR Code Not Working',
    'Camera Damaged',
    'Light Damaged',
    'Kiosk Damaged'
]

export const supportQuestions = (inst) => {
    const name = "Carparkit @ support@carparkit.com.au";
    const adinaInstallationId = 'dbe6a81d-4e26-4eb2-a25d-95e861e91574'

    return {
        "showQuestionNumbers": "off",
        "elements": [
            {
                "type": "text",
                "name": "installation_Id_adina",
                "title": "Not visible only use for condition",
                "defaultValue": `${adinaInstallationId}`,
                "isRequired": false,
                "visibleIf": "false",
            },
            {
                "type": "dropdown",
                "name": "support_type",
                "title": "Select support type",
                "isRequired": true,
                "choices": [...choices]
            },
            {
                "type": "radiogroup",
                "name": "try_intercom",
                "title": "Can you open from intercom?",
                "isRequired": true,
                "visibleIf": "{support_type}='Gate Not Opening'",
                "choices": [
                    "Yes", "No"
                ],
                "colCount": 0
            },
            {
                "type": "radiogroup",
                "name": "power_issue",
                "title": "This is power issue you need to contact maintenance",
                "visibleIf": "{try_intercom}='No'",
                "colCount": 0
            }, {
                "type": "radiogroup",
                "name": "reset_nuc",
                "title": "Please reset NUC. Working?",
                "visibleIf": "{try_intercom}='Yes' or {support_type}='Power Outage' or {support_type}='Internet outage' or {support_type}='Not Opening for Vehicles but Opening for Intercom'",
                "isRequired": true,
                "choices": [
                    "Yes", "No"
                ],
                "colCount": 0
            }, {
                "type": "radiogroup",
                "name": "pull_cables",
                "title": "Pull cables from blue Netgear Prosafe GS108 Gigabit Switch in PABX room, wait one minute and plug back in. Working ? ",
                "visibleIf": "{reset_nuc}='No' or {support_type}='Reset NUC and Still Not Working'",
                "isRequired": true,
                "choices": [
                    "Yes", "No"
                ],
                "colCount": 0
            }, {
                "type": "radiogroup",
                "name": "contact_person",
                "title": `Please contact ${name}`,
                "visibleIf": `{pull_cables}='No' and {installation_Id_adina}='${inst}'`,
            },//problem 1 gate not opening is ended here
            {
                "type": "radiogroup",
                "name": "try_refund",
                "title": "Is this a legitimate refund? (overstay charges and forgetting to register for parking are not legitimate reasons)",
                "isRequired": true,
                "visibleIf": "{support_type}='Refund'",
                "choices": [
                    "Yes", "No"
                ],
                "colCount": 0
            }, {
                "type": "radiogroup",
                "name": "legitimate_refund",
                "title": "Put in a refund request in Console on the relevant booking",
                "visibleIf": "{try_refund}='Yes'",
            },//problem 2 Refund is ended here
            {
                "type": "radiogroup",
                "name": "gate_refund",
                "title": "Is this a legitimate refund? (forgetting to register for parking is not a legitimate reason)",
                "visibleIf": "{support_type}='Gate Refund'",
                "isRequired": true,
                "choices": [
                    "Yes", "No"
                ],
                "colCount": 0
            }, {
                "type": "comment",
                "name": "lodge_refund_ticket",
                "title": "Please provide parker's bank details",
                "visibleIf": "{gate_refund}='Yes'",
                "isRequired": true,
            },//problem 3 Gate Refund is ended here
            {
                "type": "radiogroup",
                "name": "plate_booking",
                "title": "Does the plate have a booking?",
                "isRequired": true,
                "visibleIf": "{support_type}='Not Recognizing Plate'",
                "choices": [
                    "Yes", "No"
                ],
                "colCount": 0
            },
            {
                "type": "text",
                "name": "plate_booking_which_gate",
                "title": "Which gate?",
                "isRequired": true,
                "visibleIf": "{support_type}='Not Recognizing Plate'",
            },
            {
                "type": "text",
                "name": "plate_booking_date",
                "title": "Date and time",
                "isRequired": true,
                "visibleIf": "{support_type}='Not Recognizing Plate'",
            },
            {
                "type": "radiogroup",
                "name": "entered_correctly",
                "title": "Is the plate entered correctly, with no spaces?",
                "isRequired": true,
                "visibleIf": "{plate_booking}='Yes'",
                "choices": [
                    "Yes", "No"
                ],
                "colCount": 0
            },
            {
                "type": "text",
                "name": "plate_number",
                "title": "Please enter plate number",
                "isRequired": true,
                "visibleIf": "{entered_correctly}='Yes'",
            },
            {
                "type": "radiogroup",
                "name": "contact_maintenance",
                "title": "Contact maintenance to reset the payment terminal. Working?",
                "isRequired": true,
                "visibleIf": "{support_type}='Payment at Gate Not Processing'",
                "choices": [
                    "Yes", "No"
                ],
                "colCount": 0
            },
            {
                "type": "radiogroup",
                "name": "contact_maintenance_reset_gate",
                "title": "Contact maintenance to reset the gate. Working?",
                "isRequired": true,
                "visibleIf": "{support_type}='Entry Gate Not Opening'",
                "choices": [
                    "Yes", "No"
                ],
                "colCount": 0
            },
            {
                "type": "radiogroup",
                "name": "gate_not_working",
                "title": `If the gate no longer works, this is a critical issue and ${name} needs to be contacted ASAP to fix it. Please also create a ticket.`,
                "visibleIf": "{support_type}='Gate Damaged'",
            },
            {
                "type": "radiogroup",
                "name": "page_not_loading",
                "title": "Refresh the page as you may be logged out. ",
                "visibleIf": "{support_type}='Page Not Loading'",
            },
            {
                "type": "radiogroup",
                "name": "reset_nuc_console_stream",
                "title": "Please reset NUC. Working?",
                "isRequired": true,
                "visibleIf": "{support_type}='Console Streams Not Showing'",
                "choices": [
                    "Yes", "No"
                ],
                "colCount": 0
            },
            {
                "type": "radiogroup",
                "name": "not_allowing_booking",
                "title": "Check to see if a booking has already been made?",
                "isRequired": true,
                "visibleIf": "{support_type}='Console Not Allowing Booking'",
                "choices": [
                    "Yes", "No"
                ],
                "colCount": 0
            },
            {
                "type": "radiogroup",
                "name": "qr_code_not_working",
                "title": "Click into the corresponding guest card on Console. Under Public Rate Card Info, is the public ticked? If not, tick this option. Still problem?",
                "isRequired": true,
                "visibleIf": "{support_type}='QR Code Not Working'",
                "choices": [
                    "Yes", "No"
                ],
                "colCount": 0
            },
            {
                "type": "radiogroup",
                "name": "send_photos",
                "title": "Please send photos to support@carparkit.com.au and submit, support will get back to you asap",
                "visibleIf": "{support_type}='Camera Damaged' or {support_type}='Light Damaged' or {support_type}='Kiosk Damaged'",
                "colCount": 0
            },
            {
                "type": "radiogroup",
                "name": "create_ticket",
                "title": "Please submit the ticket, support will get back to you asap",
                "visibleIf": "{contact_maintenance}='No' or {contact_maintenance_reset_gate}='No' or {reset_nuc_console_stream}='No' or {not_allowing_booking}='No' or {qr_code_not_working}='Yes'",
                "colCount": 0,
            }

        ],
        "triggers": [
            {
                "type": "runexpression",
                "expression": "{support_type} notempty",
                "runExpression": "changeFunc('support_type')"
            }
        ],

    };
}

