import React from 'react';
import {graphql} from "@apollo/client/react/hoc";
import { configureForm } from '@thinkmill/pragmatic-forms';

import authManager from '../../lib/authManager';
import compose from 'lodash/flowRight';
import { withState } from '../../lib/StateFully';
import { Alert } from '../../components/Alert';
import { Input } from '../../components/Input';
import { Button, NakedButton } from '../../components/Button';
import { ColContainer } from '../../components/Container';
import { GraphqlErrorMessage, ValidationErrorMessage } from '../../components/ErrorMessage';

import { updatePasswordQuery } from './queries';

const withForm = configureForm({
	initFields: () => ({
		password: '',
		passwordConfirm: '',
	}),
	validate: ({ password, passwordConfirm }) => {
		const errors = {};

		if (password.length < 1) errors.password = 'Please enter a password';

		if (password !== passwordConfirm) errors.passwordConfirm = 'Your passwords do not match';

		return errors;
	},
	submit: ({ password }, props) => {
		return props.mutate({
			variables: {
				password,
				userId: authManager.userId,
			},
		});
	},
	onSuccess: async (result, props, form) => {
		form.reset();
		props.setState({ showSuccessMessage: true });
	},
	onFirstInteraction: (field, props) => {
		props.setState({ showSuccessMessage: false });
	},
});

const PasswordForm = ({ form, state, User }) => (
	<form.Form>
		{state.showSuccessMessage && <Alert flavour="success">Your changes have been saved.</Alert>}
		{form.submitError && <GraphqlErrorMessage error={form.submitError} />}
		{form.hasErrors && <ValidationErrorMessage />}
		<ColContainer>
			{/* This helps password managers know which accounts password has been changed. */}
			<input name="email" defaultValue={User.email} hidden readOnly />
			<Input
				label="Password"
				autoComplete="new-password"
				{...form.getFieldProps({ name: 'password', type: 'password' })}
			/>
			<Input
				label="Confirm password"
				autoComplete="new-password"
				{...form.getFieldProps({
					name: 'passwordConfirm',
					type: 'password',
				})}
			/>
		</ColContainer>
		<Button type="submit" disabled={form.loading || form.isPristine}>
			Update password
		</Button>
		{!form.isPristine && (
			<NakedButton type="reset" flavour="gray" disabled={form.loading}>
				Cancel
			</NakedButton>
		)}
	</form.Form>
);

export default compose(
	withState(() => ({ showSuccessMessage: false })),
	graphql(updatePasswordQuery),
	withForm,
)(PasswordForm);
