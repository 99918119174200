import React, {useState} from 'react';
import gql from 'graphql-tag';
import {css} from 'glamor';
import {useQuery} from "@apollo/client"
import {Button} from './Button';
import {Container, StartContainer} from './Container';
import {H2} from './Typography';
import {Loading, LoadingError} from './LoadingFork';
import {LogoBox} from './LogoBox';
import {SelectInput} from './SelectInput';
import hotel101Logo from '../icons/Hotel101Logo.svg';

import Typography from '@material-ui/core/Typography';
import drivo from "../icons/drivo.svg";
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles(() => ({
    card: {
        width: '600px',
        height: '530px',
        borderRadius: '25px',
        background: 'linear-gradient(#B0FF33, #97C93B)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05)',
        transition: '.3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12)',
    },
    nestedCard: {
        // overflowY: 'auto',
        width: '571px',
        height: '501px',
        borderRadius: '25px',
        background: '#fff',


    },
    cardContent: {

        alignItems: 'center'
    },
    signInButton: {
        backgroundColor: '#3127B8',
        marginTop: '35px',
        height: '40px',
        width: '48%',
        fontSize: '18px'
    }
}))


const allInstallationsQuery = gql`
	query findAllInstallaions {
		allInstallations(orderBy: name_ASC) {
			id
			name
			brandingKey
			timeZoneName
		}
	}
`;

export function InstallationSelector({history}) {
    const classes = useStyles()
    const [brandingKey, setBrandingKey] = useState(undefined)
    const {data, loading, error} = useQuery(allInstallationsQuery)

    function onSubmit() {
        history.push(`/${brandingKey}/dashboard`);
    }

    if (loading) return <Loading/>
    if (error) return <LoadingError error={error}/>

    return (
        <StartContainer>
            <Card className={classes.card}>
                <Card className={classes.nestedCard}>
                    <Container size="small">
                        <CardContent className={classes.cardContent}>
                            <br/>
                            <div className={css({paddingTop: 40, paddingBottom: 40})}>
                                <LogoBox size={60} src={'/DRIVO_Logo.png'}/>
                            </div>
                            <form onSubmit={onSubmit}>
                                <H2>Please choose an installation</H2>
                                <SelectInput
                                    placeholder="Installations"
                                    options={data.allInstallations.map(({name, brandingKey}) => ({
                                        label: name,
                                        value: brandingKey,
                                    }))}
                                    onChange={(e) => setBrandingKey(e.target.value)}
                                />
                                <Button type="submit"  disabled={!brandingKey}>Continue</Button>
                            </form>
                        </CardContent>
                    </Container>
                </Card>
            </Card>
        </StartContainer>
    )
}
