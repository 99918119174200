const {
    formatHours,
    formatMinutes
} = require('./rateCardUtils')
const {
    lightFormat: format,
    set
} = require('date-fns')

const BOOKING_STATUS_OPTIONS = [
    {value: 'PENDING_ENTRY', label: 'Pending entry'}, // entry is NULL
    {value: 'CURRENT', label: 'Current'}, // entry not NULL, exit is NULL
    {value: 'CLOSED', label: 'Closed'}, // (exit not NULL) or (timeout passed)
    {value: 'FINALISED', label: 'Finalised'}, // payment, if any, was processed
//    { value: 'ERROR', label: 'Error' }, // TODO: unused
]

const BOOKING_STATUS_OPTIONS_WITHOUT_FINALISED = [
    {value: 'PENDING_ENTRY', label: 'Pending entry', disabled: true}, // entry is NULL
    {value: 'CURRENT', label: 'Current'}, // entry not NULL, exit is NULL
    {value: 'CLOSED', label: 'Closed'}, // (exit not NULL) or (timeout passed)
]

const SUPPORT_TYPE_OPTIONS = [
    {value: 'GATE_NOT_OPEN', label: 'Gate Not Opening'},
    {value: 'WEBSITE_DOWN', label: 'Website Down'},
    {value: 'CAMERA_NOT_WORKING', label: 'Camera Not Working'},
    {value: 'OTHER', label: 'Other'}
]

function getTicketText(supportTypeValue) {
    return SUPPORT_TYPE_OPTIONS.find(option => option.value === supportTypeValue)?.label;
}

const BOOKING_STATUS_SEARCH_OPTIONS = [
    {value: '', label: 'All bookings'}
].concat(BOOKING_STATUS_OPTIONS)

const EVENT_GATE_TYPES = [
    {value: 'ENTRY', label: 'Entry'},
    {value: 'EXIT', label: 'Exit'}
]

const INSTALLATION_STATUS_OPTIONS = [
    {value: 'PENDING', label: 'Pending activation'},
    {value: 'ACTIVE', label: 'Active'},
    {value: 'DISABLED', label: 'Disabled'}
]

const RATECARD_TYPE_OPTIONS = [
    {value: 'DAILY', label: 'Daily'},
    {value: 'HOURLY', label: 'Hourly'},
]

const REFUND_STATUS_OPTIONS = [
    {value: 'PENDING', label: 'Pending processing'},
    {value: 'PROCESSED', label: 'Processed successfully'},
    {value: 'REJECTED', label: 'Payment not made'},
]

const ENTRY_EXIT_24HR_OPTIONS = Array.from({length: 24 * 4}, (_, i) => {
    const minutesPast = i * 15 // every 15 minutes

    return {
        label: format(set(new Date(), {
            hours: 0,
            minutes: minutesPast
        }), 'HH:mm'),
        value: minutesPast
    }
})

const GRACE_PERIOD_OPTIONS = [
    0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, // 5 minute increments up to 1 hour
    60, 70, 80, 90, 100, 110, // 10 minute increments up to 2 hours
    120, 150, 180, 210, 240, 270, 300, 330, // 30 min increments up to 6 hours
    360, 420, 480, 540, 600, 660, 720, 780, 840, 900, 960, 1020, 1080, 1140, 1200, 1260, 1320, 1380, 1440, // 1 hour increments up to 24 hours
].map((value) => ({
    label: formatMinutes(value),
    value
}))

const EXPIRY_HOURS_OPTIONS = [].concat(
    [2, 4, 8, 12],
    Array.from({length: 14}, (_, i) => (1 + i) * 24)
).map((value) => ({
    label: formatHours(value),
    value
}))

const RATECARD_CALCULATION_MODE_OPTIONS = [
    { value: 'LEGACY', label: 'Legacy' },
    { value: 'MULTI_DATE_OVERNIGHT', label: 'Multi-date Overnight' },
    { value: 'MULTI_DATE_DAYTIME', label: 'Multi-date Daytime' },
    { value: 'SINGLE_DAY', label: 'Single-day' },
]

function getRateCardCalculationModes (isDaily, isMultiDay, currentMode) {
    const defaultOptions = [];
    // use LEGACY mode only for legacy cards, disable this option for new cards
    if(currentMode && currentMode === 'LEGACY') defaultOptions.push(RATECARD_CALCULATION_MODE_OPTIONS.find(el => el.value === 'LEGACY'));
    if (!isDaily) return defaultOptions;

    if (isMultiDay) return [
        ...defaultOptions,
        ...RATECARD_CALCULATION_MODE_OPTIONS.filter(el => el.value.indexOf('MULTI') !== -1)
    ];

    return [
        ...defaultOptions,
        RATECARD_CALCULATION_MODE_OPTIONS.find(el => el.value === 'SINGLE_DAY'),
    ]
}

export {
    BOOKING_STATUS_OPTIONS,
    BOOKING_STATUS_SEARCH_OPTIONS,
    EVENT_GATE_TYPES,
    EXPIRY_HOURS_OPTIONS,
    INSTALLATION_STATUS_OPTIONS,
    RATECARD_TYPE_OPTIONS,
    REFUND_STATUS_OPTIONS,
    SUPPORT_TYPE_OPTIONS,
    BOOKING_STATUS_OPTIONS_WITHOUT_FINALISED,

    ENTRY_EXIT_24HR_OPTIONS,
    GRACE_PERIOD_OPTIONS,

    getTicketText,
    getRateCardCalculationModes
}

