import React, { useState } from 'react';
import * as theme from '../theme';
import moment from 'moment';
import { Button } from './Button';
import { Container } from './Container';
import { InlineError } from './InputInlineError';
import { Label } from './Label';
import { css } from 'glamor';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { DayPickerRangeController } from 'react-dates';
import OutsideClickHandler from 'react-outside-click-handler';
import calendarIcon from '../icons/calendar.svg';

function DateOptions ({ options, onDatesChange }) {
	const buttons = Object.keys(options).map((key) => {
		const label = key;
		const [startDate, endDate] = options[key];

		return <Button
			flavour="info"
			size="small"
			key={key}
			className={css({ margin: 1 })}
			onClick={() => onDatesChange({ startDate, endDate })}
		>
			{label}
		</Button>
	});

	return <Container size="small" spaceAbove="small" spaceBelow="small">
		{buttons}
	</Container>
}

export function DateRangeInput({
	float,
	label,
	error,
	options,
	onChange,
	value
}) {
	const [focused, setFocus] = useState(false);
	const [focusedInput, setFocusedInput] = useState('startDate')
	const baseStyle = {
		position: 'relative',
		' .DayPicker': {
			borderRadius: 6,
		},
		' .CalendarMonthGrid': {
			background: 'transparent',
		},
		' .CalendarDay__selected': {
			background: theme.blue,
			border: `1px double ${theme.blue}`,
			':active': {
				background: theme.gray70,
			},
		},
	};

	const floatedBaseStyle = float && {
		' .DayPicker': {
			position: 'absolute',
			top: 44,
			left: 16,
			zIndex: 999,
		},
	};

	const focusedBaseStyle = focused && {
		' .DayPicker': {},
	};

	const iconStyle = css({
		position: 'absolute',
		top: '50%',
		right: 16,
		transform: 'translateY(-50%)',
		pointerEvents: 'none',
	});

	const inputStyle = {
		display: 'block',
		width: '100%',
		background: theme.white,
		borderWidth: 2,
		borderStyle: 'solid',
		borderColor: theme.gray10,
		borderRadius: 6,
		height: 60,
		fontSize: 22,
		paddingLeft: 16,
		paddingRight: 16,
		marginBottom: 16,
		transition: theme.transitionSnappy,
		'::placeholder': {
			color: theme.gray20,
		},
		':focus': {
			outline: 'none',
		},
		':disabled': {
			opacity: 0.5,
		},
	};

	const focusedStyles = focused && {
		borderColor: theme.blue,
	};

	const errorStyle = !!error && {
		color: theme.flavours.danger.foregroundColor,
		borderColor: theme.flavours.danger.highlightColor,
	};

	const [startDate, endDate] = value
	let dateRangeStr = ''
	if (startDate && endDate) {
		dateRangeStr = `${startDate.format('DD/MM/YY')} until ${endDate.format('DD/MM/YY')}`
	}

	function onDatesChange ({ startDate, endDate }, doOffset) {
		endDate = endDate || startDate
		if (doOffset) {
			startDate = moment(startDate).startOf('day')
			endDate = moment(endDate).endOf('day')
		}

		onChange([startDate, endDate])
	}

	function onFocusChange (focus) {
		if (focus === null) setFocus(false)
		setFocusedInput(focus || 'startDate')
	}

	function onBlur () {
		if (startDate && !endDate) {
			onDatesChange({ startDate }, true)
		}
		setFocus(false)
	}

	return (
		<OutsideClickHandler onOutsideClick={onBlur}>
			<div className={css(baseStyle, focusedBaseStyle, floatedBaseStyle)}>
				{label && <Label error={error}>{label}</Label>}
				<div className={css({ position: 'relative' })}>
					<img className={iconStyle} src={calendarIcon} alt="" />
					<input
						className={css(inputStyle, focusedStyles, errorStyle)}
						value={dateRangeStr}
						onFocus={() => setFocus(true)}
						readOnly
					/>
				</div>
				{focused && <DayPickerRangeController
					startDate={startDate}
					endDate={endDate}
					focusedInput={focusedInput}
					onFocusChange={onFocusChange}
					onDatesChange={(dates) => onDatesChange(dates, true)}
					numberOfMonths={2}
					renderCalendarInfo={() => <DateOptions options={options} onDatesChange={(dates) => {
						onDatesChange(dates)
						onFocusChange(null)
					}} />}
					isOutsideRange={() => false}
				/>}
				{error && <InlineError error={error} />}
			</div>
		</OutsideClickHandler>
	);
}
