import authManager from '../lib/authManager'
import {withInstallation} from './InstallationContext'

export const INSTALLATIONS = {
    "nsp": "a543abc8-0364-45c3-87cf-c2866c90d903",
    "novotel-wollongong": "76e54f42-bb78-413d-a7f6-8c12af766dcf",
    "qt-gold-coast": "dfb58292-f646-47e7-acef-62fed8fdbb68",
    "esplanade-hotel-fremantle": "8c72ceb2-7454-4cd6-9cea-5a2dcefd8fa0",
    "rc": "c7a04c36-bb36-4cc3-80ca-dc41904c3c50",
    "adina-darling-harbour": "dbe6a81d-4e26-4eb2-a25d-95e861e91574",
    "hotel-x": "9e26daca-792d-4fbb-b6b3-d67f9a0b4ee7",
    "fpcp": "ba83305f-d135-419a-b0f0-59821ecd091b",
    "novotel-brighton": "9d234ed7-c871-463a-98d3-7549362134bf",
    "vibegoldcoast": "85e44fb2-f61a-4439-8eb4-ed0bb11cb71c",
    "ql": "92e345e9-6f3a-443c-9753-be3c79780dd2",
    "rns": "d9c4a336-1cfb-4030-bc24-6620108f6671",
    "mercurenewcastle": "01bc6fe0-811f-4955-80b0-66a3596fd22a",
    "rsc": "792e3266-e9a1-4478-aca4-6121d6646914",
    "holiday-inn-geelong": "55a5fc3e-69d6-4172-9f31-94cb05695694"
}

function _AdminOnly({installation, children}) {
    if (!authManager.isAdminAt(installation.id)) {
        return null
    }

    return children
}


function _MemberOnly({installation, children}) {
    if (!authManager.isMemberAt(installation.id)) {
        return null
    }

    return children
}

export function RootOnly({children}) {
    if (!authManager.isRootAdmin()) return null
    return children
}

export function CanSeeDailyReport({children}) {
    if (!authManager.canSeeDailyReport()) {
        return null
    }

    return children
}

export function NotForInstallation({installation, sites, children}) {
    if (installation && sites && sites.length) {
        sites = sites || [];
        for (let i = 0; i < sites.length; i++) {
            let site = sites[i];
            let id = INSTALLATIONS[site];
            if (id === installation.id) {
                return null;
            }
        }

    }
    return children
}

export const AdminOnly = withInstallation(_AdminOnly)
export const MemberOnly = withInstallation(_MemberOnly)
