import {H4, HR} from "./Typography";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import {flavours} from "../theme";
import {Table, TBody} from "./Table";
import {Input} from "./Input";
import {SelectInput} from "./SelectInput";
import React, {useState} from "react";
import {css} from "glamor";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {LoadingError} from "./LoadingFork";

const ALIGN_LEFT = css({textAlign: 'left !important'})
const ALIGN_RIGHT = css({textAlign: 'right !important'})

const rateCardExtraOptions = [
    {
        label: "Parking",
        value: "PARKING"
    },
    {
        label: "Special Occasion",
        value: "OCCASION"
    },
    {
        label: "Alcohol",
        value: "ALCOHOL"
    }
]

const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
            resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};

export function RateCardExtraContainer({rateCardExtras, setRateCardExtras}) {

    const updateRateCardExtras = (rateCardExtra, index) => {
        let newRateCardExtras = [...rateCardExtras];
        newRateCardExtras[index] = rateCardExtra;
        setRateCardExtras(newRateCardExtras);

    }
    const addRateCardExtra = () => {
        let newRateCardExtras = [...rateCardExtras, {description: "", amount: 0, type: "PARKING"}]
        setRateCardExtras(newRateCardExtras);
    }
    const removeRateCardExtra = (index) => {
        let newRateCardExtras = [...rateCardExtras];
        newRateCardExtras.splice(index, 1);
        setRateCardExtras(newRateCardExtras);
    }
    return <>
        <div style={{display: "flex", alignItems: "center"}}>
            <H4 style={{marginTop: 20}}>Extras/Offers</H4>
            <AddCircleOutlineIcon onClick={addRateCardExtra} style={{
                cursor: "pointer",
                fontSize: 30,
                marginLeft: 15,
                color: flavours.primary.highlightColor
            }}/>
        </div>
        <Table>
            <TBody>
                {rateCardExtras.map((c, i) => <RateCardExtraRow
                        key={`${i}-rateCardExtra`}
                        rateCardExtra={c}
                        index={i}
                        setRateCardExtra={(newExtra, newExtraIndex) => updateRateCardExtras(newExtra, newExtraIndex)}
                        removeRateCardExtra={removeRateCardExtra}
                    />
                )}
            </TBody>
        </Table>
    </>
}

export function RateCardExtraRow({rateCardExtra, index, setRateCardExtra, removeRateCardExtra}) {

    const [uploadError, setUploadError] = useState(false);

    const updateRateCardExtra = (key, value) => {
        const newExtra = {...rateCardExtra};
        newExtra[key] = value;
        setRateCardExtra(newExtra, index);
    }

    const fileChangedHandler = async (event) => {
        if (event.target.files[0].size > 5120000) {
            setUploadError(true)
            updateRateCardExtra("image", "")


        } else {
            setUploadError(false)
            let base64Image = await convertToBase64(event.target.files[0]);

            updateRateCardExtra("image", base64Image)
        }


    };

    return <>
        <tr>
            <td>
                <DeleteForeverIcon className={ALIGN_LEFT} onClick={() => removeRateCardExtra(index)} style={{
                    cursor: "pointer",
                    fontSize: 30,
                    marginLeft: 15,
                    color: flavours.danger.highlightColor
                }}/>
            </td>
            <td>

            </td>
        </tr>
        <tr>
            <td>
                <span className={ALIGN_LEFT}>Description</span>
            </td>
            <td>
                <Input className={ALIGN_RIGHT} value={rateCardExtra.description}
                       onValueChange={v => updateRateCardExtra("description", v)}/>
            </td>
        </tr>
        <tr>
            <td>
                <span className={ALIGN_LEFT}>Charge ($)</span>
            </td>
            <td>
                <Input className={ALIGN_RIGHT} value={rateCardExtra.amount} onValueChange={v => updateRateCardExtra("amount", v)}/>
            </td>
        </tr>
        <tr>
            <td>
                <span className={ALIGN_LEFT}>Category</span>
            </td>
            <td>
                <SelectInput
                    className={ALIGN_RIGHT}
                    options={rateCardExtraOptions}
                    onChange={(e) => updateRateCardExtra("type", e.target.value)}
                />
            </td>
        </tr>
        <tr>
            <td>
                <span className={ALIGN_LEFT}>Disclaimer</span>
            </td>
            <td>
                <Input className={ALIGN_RIGHT} value={rateCardExtra.disclaimer}
                       onValueChange={v => updateRateCardExtra("disclaimer", v)}/>
            </td>
        </tr>
        <tr>
            <td>
                <span className={ALIGN_LEFT}>Image</span>
            </td>
            <td>
                <HR spaceAbove='medium' spaceBelow='medium'/>

                <input
                    type="file"
                    // value={rateCardExtra.image}
                    accept="image/*"
                    onChange={fileChangedHandler}
                />


                {uploadError && <LoadingError
                    error={"File size cannot be greater than 512kb!"}/>}

                <HR spaceAbove='medium' spaceBelow='medium'/>
            </td>
        </tr>
    </>
}
