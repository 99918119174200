import gql from 'graphql-tag'
import { EventEmitter } from 'events'
import apolloClient from './apollo'

export const AUTH_STATE_CHANGE = 'AUTH_STATE_CHANGE'
export const AUTHENTICATED = 'AUTHENTICATED'
export const UN_AUTHENTICATED = 'UN_AUTHENTICATED'
const MAX_RETRYS = 10

const loginMutation = gql`
	mutation login ($email: String!, $password: String!) {
		authenticateUser(email: $email, password: $password) {
			id
			token
		}
	}
`

const logoutMutation = gql`
	mutation logout {
		deauthenticateUser {
			id
		}
	}
`

const loggedAsQuery = gql`
	query whoAmI {
		loggedInUser {
			id
			name
			isRootAdmin
			userPermissions {
				installationId
				isAdmin
			}
		}
	}
`

class AuthManager extends EventEmitter {
	initialize () {
		if (this._initialized) {
			throw new Error('AuthManager cannot be re-initialized');
		}

		this._initialized = true
		this._installationId = null;
		this._isUpdatingAuthState = false;
		this._loggedInUser = null;
		this._willRetryConnectionAt = null;

		this._statusMessage = 'Authenticating';
		this.updateAuthState();
	}

	get isAuthenticated () {
		// Do we have a loggedInUser?
		return !!this._loggedInUser;
	}

	get userId () {
		if (this._loggedInUser) {
			return this._loggedInUser.id;
		}
		return null;
	}

	get userName () {
		if (this._loggedInUser) {
			return this._loggedInUser.name;
		}
		return null;
	}

	get isUpdating () {
		return this._isUpdatingAuthState;
	}

	get statusMessage () {
		if (this._statusMessage) {
			return this._statusMessage;
		} else if (this._willRetryConnectionAt) {
			const retryInSecs = Math.floor(this._willRetryConnectionAt - Date.now() / 1000);
			return `Failed to connect to server. Retrying in ${retryInSecs}s`;
		} else {
			return null;
		}
	}

	isAdminAt (installationId) {
		if (!this._loggedInUser) return false
		if (this._loggedInUser.isRootAdmin) return true
		return this._loggedInUser.userPermissions.find((p) => {
			return p.installationId === installationId && p.isAdmin;
		});
	}

	isMemberAt (installationId) {
		if (!this._loggedInUser) return false
		if (this._loggedInUser.isRootAdmin) return true
		return this._loggedInUser.userPermissions.find((p) => {
			return p.installationId === installationId;
		});
	}

	canSeeDailyReport() {
		if (!this._loggedInUser) return false
		if (this._loggedInUser.isRootAdmin) return true
		if (this._loggedInUser.userPermissions[0].isAdmin) return true

		return false;
	}

	isRootAdmin (installationId) {
		return this._loggedInUser.isRootAdmin
	}

	async updateAuthState (attemptNum = 0) {
		if (this._isUpdatingAuthState) return; // don't make multiple requests
		this._isUpdatingAuthState = true;

		// Capture the authState before we update
		const wasAuthenticated = this.isAuthenticated;

		try {
			// We can check the authenication state by quering
			// loggedInUser. If we get an authentication error, we are not logged in.
			const { data } = await apolloClient.query({
				query: loggedAsQuery,
				fetchPolicy: 'network-only',
				variables: { installationId: this._installationId },
			});
			this._isUpdatingAuthState = false;
			this._storeAuthData(data.loggedInUser);
			if (wasAuthenticated !== this.isAuthenticated) this._notifyAuthStateChanged();
		} catch (err) {
			this._isUpdatingAuthState = false;

			// There was a problem processing your request
			if (attemptNum > MAX_RETRYS) {
				this._clearAuthData();
				this._notifyAuthStateChanged();
			} else {
				// Something failed ... sleep and retry
				const backoffTime = Math.min(500 * attemptNum, 10000);
				this._statusMessage = null;
				this._willRetryConnectionAt = Date.now() + backoffTime;
				this._notifyAuthStateChanged();

				await new Promise((resolve) => setTimeout(resolve, backoffTime));
				this.updateAuthState(attemptNum + 1);
			}
		}
	}

	_storeAuthData (loggedInUser) {
		this._statusMessage = null;
		this._willRetryConnectionAt = null;
		this._loggedInUser = loggedInUser;
	}

	_clearAuthData () {
		this._storeAuthData(null);
	}

	_notifyAuthStateChanged () {
		setImmediate(() => {
			const authState = this.isAuthenticated ? AUTHENTICATED : UN_AUTHENTICATED;
			this.emit(AUTH_STATE_CHANGE, authState);
		});
	}

	async login ({ email, password }) {
		await apolloClient.mutate({
			mutation: loginMutation,
			variables: { email, password },
		});
		// Update
		this.updateAuthState();
	}

	async logout () {
		try {
			await apolloClient.mutate({
				mutation: logoutMutation,
			});

			this._clearAuthData();
			this._notifyAuthStateChanged();
		} catch (err) {
			console.error(err); // eslint-disable-line no-console
		}
	}
}

const auth = new AuthManager()
auth.initialize()
export default auth;
