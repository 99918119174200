import React, {useEffect} from 'react';
import {css} from 'glamor';
import {useQuery, useMutation, useSubscription} from "@apollo/client";
import * as theme from '../../theme';
import {withInstallation} from '../../components/InstallationContext';
import {FlashMessage} from '../../components/FlashMessage';
import {Button} from '../../components/Button';
import {Container, CardContainer} from '../../components/Container';
import {Left, Right} from '../../components/Header';
import {Well} from '../../components/Well';
import {H1, H2} from '../../components/Typography';
import {Table, THead, TBody} from '../../components/Table';
import {Loading, LoadingError} from '../../components/LoadingFork';
import {listSupportTicketQuery} from './queries';
import drivoApollo from "../../lib/drivoApollo";
import {orderBy} from 'lodash'
import {StatusPill} from '../../components/StatusPill';
import authManager from "../../lib/authManager";
import gql from "graphql-tag";
import {fmtDate} from "../../lib/misc";

function Header(props) {
    return <div
        className={css({
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'stretch',
        })}
        {...props}
    />
}

function Status({isOpen}) {
    return <td>
        <div
            className={css({
                display: 'flex',
                justifyContent: 'stretch',
                flexDirection: 'column',
            })}
        >
            <StatusPill flavour={isOpen ? 'success' : 'light'}>
                {isOpen ? 'Open' : 'Resolved'}
            </StatusPill>
        </div>
    </td>
}

const rowStyle = css({
    '> td': {
        verticalAlign: 'middle',
        height: 60,
        borderBottom: `1px solid ${theme.gray10}`,
        transition: 'all .2s linear',
    },
    '> td:first-child': {paddingLeft: 10},
    '> td:last-child': {paddingRight: 10},
    ':hover > td': {
        background: theme.gray10,
        cursor: 'pointer',
    },
});

function SupportTicketRow({installation, ticket, onClick}) {
    const {id, createdAt, createdBy, supportType} = ticket
    let unread = ticket?.status === "OPEN" && ticket?.ticketNotifications[0]?.readStatus === false

    const name = createdBy && createdBy.isRootAdmin ? "Drivo Team" : createdBy?.name
    return <tr key={id} className={rowStyle} onClick={onClick}>
        <td>{fmtDate(createdAt, installation.timeZoneName)} {' '} {unread && <StatusPill size='xsmall'>UNREAD</StatusPill>}
        </td>
        <td>{name}</td>
        <td>{supportType}</td>
        <Status isOpen={ticket.status === 'OPEN'}/>
    </tr>
}

function SupportTicketList({match, installation, location, history}) {

    const {data = {}, loading, error, refetch} = useQuery(listSupportTicketQuery, {
        client: drivoApollo,
        fetchPolicy: 'no-cache',
        variables: {
            installationId: installation.id,
            userId: authManager.userId
        }
    })

    const {data: notification} = useSubscription(gql`
        subscription notificationComment{
          notificationComment{
            createdById
          }
        }
    `, {
        client: drivoApollo,
        fetchPolicy: 'no-cache',
        shouldResubscribe: true
    })
    useEffect(() => {
        if (!loading && notification && notification?.notificationComment) {
            if (notification?.notificationComment.createdById !== authManager.userId && notification?.notificationComment.createdById !== "") {
                refetch()
            }
        }
    }, [notification])

    const tickets = orderBy(data.getAllSupportTicketByInstallationId, ['status', 'createdAt'], ['asc', 'desc']) || []
    return <div>
        <CardContainer>
            <Container size="large" spaceAbove="small" spaceBelow="small">
                <Header>
                    <Left>
                        <H1>Support Tickets</H1>
                    </Left>
                    <Right>
                        {!loading && tickets.length !== 0 && (
                            <Button to={`${match.url}/new`} style={{'background-color': '#3127B8'}}>New ticket</Button>
                        )}
                    </Right>
                </Header>
            </Container>
            <Container spaceBelow="large">
                <FlashMessage locationState={location.state}/>
                {loading ? <Loading/> : null}
                {error ? <LoadingError error={error}/> : null}
                {!loading && tickets.length === 0 && <Well>
                    <div>
                        <H2 spaceBelow="large">No tickets available</H2>
                        <Button spaceBelow="none" to={`${match.url}/new`}>
                            New Ticket
                        </Button>
                    </div>
                </Well>}
                {!loading && tickets.length > 0 && <Table>
                    <THead>
                        <tr>
                            <th>Created At</th>
                            <th>Created By</th>
                            <th>Support Type</th>
                            <th>Status</th>
                        </tr>
                    </THead>
                    <TBody>{tickets.map((ticket) => {
                        return <SupportTicketRow key={ticket.id} ticket={ticket} installation={installation} onClick={() => {
                            history.push(`${match.url}/${ticket.id}`);
                        }}/>
                    })}</TBody>
                </Table>}
            </Container>
        </CardContainer>

    </div>
}

export default withInstallation(SupportTicketList)
