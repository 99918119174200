import React from 'react';
import { css } from 'glamor';
import { flavours, verticalSpace } from '../theme';

export const Alert = ({
	spaceAbove = 'none',
	spaceBelow = 'small',
	flavour,
	children,
	className,
	...props
}) => {
	const baseStyle = {
		padding: 16,
		borderRadius: 6,
		color: flavours[flavour].foregroundColor,
		backgroundColor: flavours[flavour].backgroundColor,
		marginTop: verticalSpace[spaceAbove],
		marginBottom: verticalSpace[spaceBelow],
		'& a': {
			color: 'inherit',
			textDecoration: 'underline',
			':hover': { color: 'inherit' },
			':visited': { color: 'inherit' },
		},
	};

	return (
		<div className={css(baseStyle, className)} {...props}>
			{children}
		</div>
	);
};
