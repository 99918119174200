export const formatCurrency = (num, currency = 'AUD') => {
	return num.toLocaleString('en-AU', {
		style: 'currency',
		currency,
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
};

export const CurrencyDisplay = ({ amountString, currency, emptyString = '-' }) => {
	if (!amountString) return emptyString;
	const num = parseFloat(amountString, 10);
	return formatCurrency(num, currency);
};
