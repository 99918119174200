import React, {useState} from 'react';
import {H3, HR} from "../../../../components/Typography";
import {useFormState} from "react-use-form-state";
import {Button} from "../../../../components/Button";
import {Container} from "../../../../components/Container";
import {Input} from "../../../../components/Input";
import {useMutation} from "@apollo/client";
import {updateHourlyRateValuesMutation} from "../queries";
import drivoApollo from "../../../../lib/drivoApollo";
import {LoadingError} from "../../../../components/LoadingFork";
import {Alert} from "../../../../components/Alert";


export default function HourlyRatesForm({
                                            installation,
                                            facilityHourlyRatesData,
                                            facilityHourlyRatesRefetch,
                                            onDone,
                                        }) {

    const [isLoading, setIsLoading] = useState(false)

    const [{
        errors,
        values
    }, {number}] = useFormState({

        // Hourly
        rate: facilityHourlyRatesData ? facilityHourlyRatesData.rate : null,
        maxDailyCharge: facilityHourlyRatesData ? facilityHourlyRatesData.maxDailyCharge : null,
    })


    const [updateHourlyRate, {error: updateError}] = useMutation(updateHourlyRateValuesMutation, {
        client: drivoApollo
    })


    const isCreating = !facilityHourlyRatesData;
    const hasErrors = Object.values(errors).some(x => x)
    const canSubmit = !hasErrors

    async function handleSubmit() {

        setIsLoading(true)
        const {data} = await updateHourlyRate({
            variables: {
                input: {
                    rate: parseInt(values.rate),
                    maxDailyCharge: parseInt(values.maxDailyCharge),
                    facilityId: facilityHourlyRatesData ? facilityHourlyRatesData.facilityId : null,
                }
            }
        })

        facilityHourlyRatesRefetch()
        onDone(data.updateHourlyRateValues)


        setIsLoading(false)

    }


    return (
        <>
            {updateError && <LoadingError error={updateError}/>}
            <H3>Hourly rate (per hour)</H3>
            <Input
                label=''
                {...number('rate')}
                error={errors.rate}
                required
                disabled={facilityHourlyRatesData ? facilityHourlyRatesData.override : false}
            />

            <HR spaceAbove='medium' spaceBelow='medium'/>
            <H3>Max Daily Charge</H3>
            <Input
                label=''
                {...number('maxDailyCharge')}
                error={errors.maxDailyCharge}
                required
                disabled={facilityHourlyRatesData ? facilityHourlyRatesData.override : false}
            />

            {/*<HR spaceAbove='medium' spaceBelow='medium'/>*/}

            {/*<div className={css({*/}
            {/*    display: 'inline-flex',*/}
            {/*})}>*/}
            {/*    <CheckboxInput label={""} checked={facilityHourlyRatesData ? facilityHourlyRatesData.override : false}*/}
            {/*                   disabled={true}/>*/}
            {/*    <H3 style={{marginLeft: 5}}>Override </H3>*/}
            {/*</div>*/}


            {facilityHourlyRatesData && facilityHourlyRatesData.override && <Alert flavour="info">
                Your site has custom hourly rates for public parkers. Please contact drivo for assistance in making
                changes to the hourly rates.
            </Alert>}

            <HR spaceAbove='none' spaceBelow='medium'/>


            <Container>
                <Button block type='submit'
                        disabled={!canSubmit || (facilityHourlyRatesData && facilityHourlyRatesData.override) || isLoading}
                        onClick={handleSubmit}
                >{isCreating ? 'Create' : 'Update'}</Button>{' '}
            </Container>


        </>
    )

}
