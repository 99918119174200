import React from 'react'
import { css } from 'glamor'
import { withAppConfig } from './AppConfigContext'
import * as theme from '../theme'

const layoutStyle = css({
	display: 'block',
	textAlign: 'center',
})

const textStyle = css({
	fontSize: 14,
	lineHeight: 1.5,
	color: theme.gray40,
	textDecoration: 'none',
})

export const PoweredBy = withAppConfig(({ appConfig }) => (
	<div className={layoutStyle}>
		<span className={textStyle}>
			Powered by Drivo
			<a className={textStyle} href={`tel:${appConfig.SUPPORT_CONTACT_PHONE}`}>
				{appConfig.SUPPORT_CONTACT_PHONE}
			</a>
		</span>
	</div>
))
