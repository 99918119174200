import gql from 'graphql-tag'

export const ListSanctionedPlateTime = gql`
    query getSanctionedByFacility($installationId:ID!){
        getSanctionedByFacility(installationId:$installationId){
         id
            start
            end
            date
  }
}
`
export const createSanctionedTimeMutation = gql`
    mutation createSanctionedTime($date:String!,$start:String!,$end:String!,$installationId:ID!){
        createSanctionedTime(date:$date,start:$start,end:$end,installationId:$installationId)
}
`

export const getSanctionedTimeById = gql`
    query getSanctionedById($id:ID!){
      getSanctionedById(id:$id){
        start
        end
        date
      }
    }
`

export const updateSanctionedTimeMutation = gql `
mutation updateSanctionedTimeById($input:UpdateSanctionedTimeInput!){
  updateSanctionedTimeById(input:$input)
}
`

export const deleteSanctionedTimeMutation = gql`
  mutation deleteSanctionedTimeOnly($id:ID!, $installationId:ID!){
    deleteSanctionedTimeOnly(id:$id, installationId:$installationId)
}
`
