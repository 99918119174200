import React from 'react';
import { withRouter } from 'react-router-dom';

export default withRouter(
	// eslint-disable-next-line no-unused-vars
	({ match, location, history, staticContext, href, ...props }) => {
		if (!href) return <tr {...props} />;
		return (
			<tr
				{...props}
				onClick={(event) => {
					if (event.metaKey) window.open(href);
					else history.push(href);
				}}
			/>
		);
	},
);
