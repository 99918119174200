import React, {useState} from 'react'
import {Button, NakedButton} from '../../../components/Button'
import {Container, ColContainer, TPL_50_50, CardContainer} from '../../../components/Container';
import {GraphqlErrorMessage, ValidationErrorMessage} from '../../../components/ErrorMessage';
import {H1} from '../../../components/Typography';
import {Helmet} from 'react-helmet';
import {Input} from '../../../components/Input';
import {useFormState} from 'react-use-form-state'
import {withInstallation} from '../../../components/InstallationContext';
import {SingleDateInput} from '../../../components/SingleDateInput.js'

import {
    createSanctionedTimeMutation,
} from './queries';
import drivoApollo from "../../../lib/drivoApollo";
import moment from "moment";
import {useMutation} from "@apollo/client";
import {fmtDate} from "../../../lib/misc";


function SanctionedTimeCreate({installation, history, match}) {
    const [date, setDate] = useState(moment(new Date(), 'dd-mm-yyyy'))
    const [{errors, values}, {number}] = useFormState({
        date: '',
        start: '',
        end: '',
    })

    const { timeZoneName: timezone } = installation;

    const [createSanctionedTime, {error: submitError}] = useMutation(createSanctionedTimeMutation, {
        client: drivoApollo
    })
    const firstError = Object.values(errors)[0]
    let hasErrors = !!firstError

    const canSubmit = (
        values.date &&
        values.start &&
        values.end &&
        !hasErrors
    )

    async function onCreate() {
        const {data} = await createSanctionedTime({
            variables: {
                installationId: installation.id,
                date: fmtDate(values.date, timezone, 'YYYY-MM-DD'),
                start: values.start,
                end: values.end
            },
        })

        if (data?.createSanctionedTime) {
            history.goBack()
        } else {
            hasErrors = true
            errors.date = `Duplicate entry not allowed for ${fmtDate(values.date, timezone, 'YYYY-MM-DD')} `
        }


    }

    function onCancel() {
        history.goBack()
    }

    function dateChange(date) {
        values.date = date.format('YYYY-MM-DD')
        setDate(date)
    }

    function validateTime(value) {
        if (value > 0 && value <= 2400 && value.length === 4) return
        return "Invalid time format, time must be in between 0001-2400"
    }

    return <div>

        <CardContainer>
            <Container spaceAbove="medium" spaceBelow="medium">
                <Helmet>
                    <title>New Sanctioned Time</title>
                </Helmet>
                <H1>New Sanctioned Time</H1>
                {submitError && <GraphqlErrorMessage spaceBelow="medium" error={submitError}/>}
                {hasErrors && <ValidationErrorMessage spaceBelow="medium" error={firstError}/>}

                <ColContainer columnTemplate={TPL_50_50} spaceAbove="medium">
                    <Input required
                           label="Start"
                           error={errors.start}
                           {...number({
                               name: 'start',
                               validate: validateTime,
                               validateOnBlur: true

                           })} />

                    <Input required
                           label="End"
                           error={errors.end}
                           min={"0000"}
                           max={"2359"}
                           {...number({
                               name: 'end',
                               validate: validateTime,
                               validateOnBlur: true
                           })} />
                    <SingleDateInput
                        date={date}
                        onChange={dateChange}
                        label={"Date"}
                    />

                </ColContainer>
                <div>
                    <Button type="submit" style={{'cursor': 'pointer'}} spaceAbove="small"
                            onClick={onCreate}>
                        Create
                    </Button>
                    <NakedButton style={{'cursor': 'pointer'}} flavour="gray" spaceAbove="small" onClick={onCancel}>
                        Cancel
                    </NakedButton>
                </div>
            </Container>
        </CardContainer>

    </div>
}

export default withInstallation(SanctionedTimeCreate)
