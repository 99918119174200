import React from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router-dom';

import BookingList from './List';
import BookingCreate from './Create';
import BookingDetail from './Detail';

export default function ({ match }) {
	return <div>
		<Helmet>
			<title>Bookings</title>
		</Helmet>
		<Switch>
			<Route path={`${match.path}/create`} component={BookingCreate} />
			<Route path={`${match.path}/:bookingId`} component={BookingDetail} />
			<Route exact path={match.path} component={BookingList} />
		</Switch>
	</div>
}
