import React, {useCallback, useState, useEffect} from "react";
import {css} from "glamor";
import {useQuery} from "@apollo/client";
import pms from "ms";
import {Button,} from "../../components/Button";
import {Container} from "../../components/Container";
import {H2,} from "../../components/Typography";
import {Loading, LoadingError} from "../../components/LoadingFork";
import {Well} from "../../components/Well";
import {withInstallation} from "../../components/InstallationContext";
import * as theme from "../../theme";
import {dropDuplicates} from "../../lib/rateCardUtils";
import {differenceInMilliseconds,} from "date-fns";
import {listEvents} from "./queries";
import {Table, TBody, THead} from '../../components/Table';
import Grid from '@material-ui/core/Grid'
import {fade, makeStyles} from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import InputBase from "@material-ui/core/InputBase";
import filterIcon from "../../icons/filterIcon.svg";
import SearchIcon from "@material-ui/icons/Search";
import Typography from "@material-ui/core/Typography";
import {CellPill} from '../../components/StatusPill'
import {Left, Right} from "../../components/Header";
import { DateTime } from "luxon";
import authManager from '../../lib/authManager';

const useStyles = makeStyles((theme) => ({
    card: {
        height: '44rem',
        overflowY: 'auto',
        borderRadius: '14px',
        background: '#fff',
        boxShadow: '0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05)',
        transition: '.3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12)',
        padding: '35px 35px 45px 15px',
    },
    search: {
        position: 'relative',
        borderColor: '#91b35c',
        borderStyle: 'solid',
        borderRadius: 30,
        backgroundColor: fade('#eaeaea', 0.35),
        '&:hover': {
            backgroundColor: fade('#eaeaea', 0.55),
        }
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '65ch',
            height: '5ch'
        },
    },
    root: {
        height: '44rem',
        overflowY: 'auto',
        borderRadius: '15px',
        boxShadow: '0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05)',
        transition: '.3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12)',
        padding: '8px 7px 30px 5px',
        '&:hover': {
            transform: ' scale(1.01)',
            boxShadow: '0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06)'
        }

    },
    media: {
        height: 0,
        paddingTop: '30.25%', // 16:9,
        borderRadius: '15px',
        margin: '10px',
    },
    filter: {
        height: 60,
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        imageRendering: 'auto', // might help browsers resize the image crisply
        cursor: 'pointer'
    }
}));


const ROW_STYLE = css({
    "> td": {
        verticalAlign: "middle",
        height: 60,
        textAlign: "center",
        borderBottom: `1px solid ${theme.gray10}`,
        transition: "all .2s linear",
    },
    ":hover > td": {
        background: theme.gray10,
        cursor: "pointer",
    },
});

export function SearchBar({onSearch, searchTerm, click}) {
    const classes = useStyles();
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={11}>
                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon color="primary"/>
                        </div>
                        <InputBase
                            autoFocus
                            placeholder="Search for a licence plate"
                            onChange={onSearch}
                            value={searchTerm}
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{'aria-label': 'search'}}
                        />
                    </div>
                </Grid>
                <Grid item xs={1}>
                    <img
                        className={classes.filter}
                        src={filterIcon}
                        alt={"filter"}
                        onClick={() => click()}
                    />
                </Grid>
            </Grid>
        </>
    )
}
function Header(props) {
    return <div
        className={css({
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'stretch',
        })}
        {...props}
    />
}

function isToday(date, timezone){
    if (!date) return false;
    return date.hasSame?.(DateTime.now().setZone(timezone), "day") ?? false
}

function isYesterday(date, timezone){
    if (!date) return false;
    return date.hasSame?.(DateTime.now().setZone(timezone).minus({days: 1}), "day") ?? false
}

function calendarDate(date, timezone) {
    console.log("Formatting: %o", date.toISO())
    if (isToday(date, timezone)) return "Today";
    if (isYesterday(date, timezone)) return "Yesterday";
    return date.toFormat("yyyy/MM/dd");
}

const cellStyle = css({
    fontWeight: '600',
    textAlign: "center"
})
const tableStyle = css({width: "100%", textAlign: 'left'});

function NewBooking({installation, history, match}) {
    const classes = useStyles()
    const [searchTerm, setSearchTerm] = useState("");
    const {data = {}, loading, error, fetchMore} = useQuery(
        listEvents,
        {
            variables: {
                installationId: installation.id,
                offset: 0,
                search: searchTerm || undefined,
            },
        }
    );


    const events = dropDuplicates(data.allBookableEvents || [], 3000); // TODO: use EVENTS_DUPLICATE_WITHIN_MS from platform
    const eventsCount = (data._allBookableEventsMeta || {count: 0}).count;
    const moreToLoad = events.length < eventsCount;

    const loadMore = useCallback(() => {
        fetchMore({
            variables: {
                installationId: installation.id,
                offset: events.length,
                search: searchTerm || undefined,
            }
        });
    }, [fetchMore, events.length, installation.id, searchTerm]);

    function onSearch(e) {
        const value = e.target.value.replace(/[^a-z0-9]/gi, "").toUpperCase();
        setSearchTerm(value);
    }

    function onEntrySelect(eventId) {
        history.push(
            match.url.replace("/new-booking", `/bookings/create?eventId=${eventId}`)
        );
    }

    function onCreate() {
        history.push(
            match.url.replace("/new-booking", `/bookings/create?plate=${searchTerm}`)
        );
    }

    useEffect(() => {
        
        const intervalId = setInterval(async () => {

            await authManager.updateAuthState();
            if (authManager.isAuthenticated) {
                loadMore()
            }
        }, 30 * 1000);
      
        return () => clearInterval(intervalId);
       
      }, [loadMore, authManager.isAuthenticated])

    if (error) {
        console.error("Error loading events", error);
    }

    function EventRow({event, onClick}) {
        const {id, plate, gateDescription, pcLoggedAt} = event;
        const eventAt = new Date(pcLoggedAt);
        const eventDateTime = DateTime.fromISO(eventAt.toISOString(), { locale: "en-AU" }).setZone(installation.timeZoneName)
        const ms = differenceInMilliseconds(new Date(), eventAt);

        return (
            <tr className={ROW_STYLE} onClick={() => onClick(id)}>
                <td style={cellStyle}><CellPill color='#37B534' width='105px' size='small'>{plate}</CellPill></td>
                <td style={cellStyle}><CellPill width='117px' color='#FFA800'
                                                size='small'>{gateDescription}</CellPill></td>
                <td style={cellStyle}><CellPill width='117px' color='#FF4773'
                                                size='small'>{calendarDate(eventDateTime, installation.timeZoneName)}</CellPill></td>
                <td style={cellStyle}><CellPill width='117px' color='#45B5F4'
                                                size='small'>{eventDateTime.toFormat("HH:mm ZZZZ")}</CellPill></td>
                <td>{pms(ms, {long: true})} ago</td>
            </tr>
        );
    }

    return (
        <div>
            <SearchBar
                onSearch={onSearch}
                searchTerm={searchTerm}
                click={onCreate}
            />
            <br/>
            <Card className={classes.card}>
                <CardMedia
                    className={classes.media}
                    image="/parking.jpg"
                    title="parking"
                />

                <CardContent>


                    <Header>
                        <Left>
                            <Typography variant={'h5'}>Latest Entries</Typography>
                        </Left>
                        <Right>
                            <Button onClick={onCreate} >New Booking</Button>
                        </Right>
                    </Header>


                    <Typography variant="subtitle1" color="textSecondary">Select an entry below to create a
                        new
                        booking</Typography>
                    <br/><br/>
                    <Container size="large" spaceBelow="small">
                        {error ? <LoadingError error={error}/> : null}
                        {events.length === 0 ? (
                            <Well>
                                <div>
                                    <H2 spaceAbove="large" spaceBelow="large">
                                        {loading ? <Loading/> : "No results available"}
                                    </H2>
                                </div>
                            </Well>
                        ) : (

                            <Table className={tableStyle}>
                                <THead>
                                    <tr style={{color: '#3127B8', textAlign: "center"}}>
                                        <th>License Plate</th>
                                        <th>Status</th>
                                        <th>Date</th>
                                        <th>Time</th>
                                    </tr>
                                </THead>

                                <TBody>
                                    {events.map((event) => {
                                        return (
                                            <EventRow
                                                key={event.id}
                                                event={event}
                                                onClick={onEntrySelect}
                                            />
                                        );
                                    })}
                                </TBody>
                            </Table>
                        )}

                        <div className={css({textAlign: "center"})}>
                            {/*{events.length === 0 && (*/}
                            {/*    <Button onClick={onCreate}>Enter number plate</Button>*/}
                            {/*)}*/}
                            {moreToLoad ? (
                                <Button onClick={loadMore} style={{backgroundColor: '#3127B8'}} spaceAbove="medium"
                                        spaceBelow="medium">
                                    Fetch More
                                </Button>
                            ) : null}
                        </div>

                    </Container>
                </CardContent>
            </Card>
        </div>
    );
}

export default withInstallation(NewBooking);
