import React from 'react';
import { css, compose } from 'glamor';
import * as theme from '../theme';

const Wrap = ({ ...props }) => (
	<div
		className={css({
			position: 'relative',
			overflowX: 'auto',
			'@media (min-width: 800px)': {
				overflowX: 'initial',
			},
		})}
		{...props}
	/>
);

const tableStyle = css({
	width: '100%',
	'& thead td, thead th': {
		borderBottom: `1px solid ${theme.gray30}`,
		color: theme.gray40,
		fontSize: 14,
		fontWeight: 'bold',
		height: 60,
		paddingLeft: 0,
		paddingRight: 0,
		textAlign: 'left',
		textTransform: 'uppercase',
		verticalAlign: 'middle',
	},
	'& tfoot td': {
		borderTop: `2px solid ${theme.gray30}`,
		fontWeight: 'bold',
		height: 60,
		paddingLeft: 0,
		paddingRight: 0,
		verticalAlign: 'middle',
	},
	'& tbody td': {
		borderBottom: `1px solid ${theme.gray10}`,
		height: 60,
		paddingLeft: 0,
		paddingRight: 0,
		verticalAlign: 'middle',
	},
});

export const Cell = ({ data, CellComponent, format = (b) => b, ...props }) => (
	<CellComponent {...props}>{format(data)}</CellComponent>
);

export const Row = ({ data, CellComponent, columns }) => {
	return (
		<tr>
			{columns.map(({ path, ...col }, index) => (
				<Cell key={path + index} CellComponent={CellComponent} data={data[path]} {...col} />
			))}
		</tr>
	);
};

const renderRow = ({ CellComponent, columns }) => (row, index) => (
	<Row
		key={row.id || index}
		CellComponent={CellComponent} // urgh ... I dont like this API...
		columns={columns}
		data={row}
	/>
);

export const Table = ({
	spaceAbove = 'none',
	spaceBelow = 'small',
	headers,
	data,
	footers,
	columns,
	children,
	...props
}) => (
	<Wrap>
		<table
			className={compose(
				tableStyle,
				{
					marginTop: theme.verticalSpace[spaceAbove],
					marginBottom: theme.verticalSpace[spaceBelow],
				},
			)}
			{...props}
		>
			{headers && <thead>{headers.map(renderRow({ columns, CellComponent: 'th' }))}</thead>}
			{footers && <tfoot>{footers.map(renderRow({ columns, CellComponent: 'td' }))}</tfoot>}
			{data && <tbody>{data.map(renderRow({ columns, CellComponent: 'td' }))}</tbody>}
			{children}
		</table>
	</Wrap>
);

export const TBody = (props) => <tbody {...props} />;
export const THead = (props) => <thead {...props} />;
export const TFoot = (props) => <tfoot {...props} />;

export const TR = (props) => <tr {...props} />;
export const TH = (props) => <th {...props} />;
export const TD = (props) => <td {...props} />;
