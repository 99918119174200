import React, {useEffect, useState} from 'react'
import {Alert} from '../../components/Alert';
import {Button, HollowButton, NakedButton} from '../../components/Button';
import {Container, ColContainer, CardContainer, TPL_50_50} from '../../components/Container';
import {FlashMessage} from '../../components/FlashMessage';
import ReactModal from 'react-modal';
import {H1, Byline, H3} from '../../components/Typography';
import {Helmet} from 'react-helmet';
import {Input} from '../../components/Input';
import {Left, Right} from '../../components/Header';
import {Loading, LoadingError} from '../../components/LoadingFork';
import {css} from 'glamor';
import {useQuery, useMutation} from "@apollo/client"
import {withInstallation} from '../../components/InstallationContext';
import {
    ticketById,
    mutationDeleteTicket,
    mutationAddComment,
    queryGetCommentsByTicket,
    mutationResolveTicket,
    mutationReadStatusUserTicket
} from './queries'
import drivoApollo from "../../lib/drivoApollo";
import authManager from "../../lib/authManager";
import {FormControl, InputLabel, Input as MUIInput, FormHelperText} from '@material-ui/core'
import { fmtDate } from '../../lib/misc'
import {orderBy} from 'lodash'
import {StatusPill} from '../../components/StatusPill';

function Header(props) {
    return <div
        className={css({
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'stretch',
        })}
        {...props}
    />
}


function TicketDetailsForm({installation, onCommentAdd, ticket, ticketComments}) {

    const [comment, setComment] = useState('')

    return <div>
        <ColContainer spaceBelow="small" columnTemplate="2fr 1fr">
            <Input required
                   disabled={ticket.status === 'RESOLVED'}
                   placeHolder={"Add comment"}
                   value={comment}
                   onChange={(event) => {
                       setComment(event.target.value)
                   }}
            />
            <HollowButton type="submit"
                          disabled={!comment || ticket.status === 'RESOLVED'}
                          style={{'cursor': 'pointer'}} onClick={() => {
                onCommentAdd(comment)
                setComment('')
            }}>
                Add comment
            </HollowButton>
        </ColContainer>

        {ticketComments?.map(comment => {
            if (comment?.createdBy.name === ticket?.createdBy.name) {
                return (
                    <ColContainer columnTemplate={TPL_50_50} spaceBelow="small">
                        <FormControl disabled variant="standard">
                            <InputLabel htmlFor="component-disabled">{comment.createdBy.isRootAdmin ? "Drivo Team" : comment.createdBy.name}</InputLabel>
                            <MUIInput color="secondary" multiline={true} id="component-disabled"
                                      value={comment.comment}/>
                            <FormHelperText>{fmtDate(comment.createdAt, installation.timeZoneName)}</FormHelperText>
                        </FormControl>
                    </ColContainer>
                )
            } else {
                return (
                    <ColContainer columnTemplate={TPL_50_50} spaceBelow="small">
                        <div/>
                        <FormControl disabled variant="standard">
                            <InputLabel htmlFor="component-disabled">{comment.createdBy.isRootAdmin ? "Drivo Team" : comment.createdBy.name}</InputLabel>
                            <MUIInput multiline={true} id="component-disabled"
                                      value={comment.comment}/>
                            <FormHelperText>{fmtDate(comment.createdAt, installation.timeZoneName)}</FormHelperText>
                        </FormControl>
                    </ColContainer>
                )
            }

        })}

        <br/>
    </div>
}

function TicketDetails({installation, match, location, history}) {
    const {ticketId} = match.params
    const [isModalOpen, setModalOpen] = useState(false)
    const [showSuccessMessage, setSuccessMessage] = useState(false)
    const [modalType, setModalType] = useState("")
    const [resolveTicket] = useMutation(mutationResolveTicket, {
        client: drivoApollo
    })
    const [addComment] = useMutation(mutationAddComment, {
        client: drivoApollo
    })
    const [deleteTicket] = useMutation(mutationDeleteTicket, {
        client: drivoApollo
    })

    const {data = {}, loading, error} = useQuery(ticketById, {
        client: drivoApollo,
        variables: {
            ticketId,
            installationId: installation.id,
        }
    })
    const ticket = data.getSupportTicketById


    const [updateReadStatus] = useMutation(mutationReadStatusUserTicket, {
        client: drivoApollo
    })


    const {data: comments, refetch} = useQuery(queryGetCommentsByTicket, {
        client: drivoApollo,
        variables: {
            ticketId
        }
    })
    const ticketComments = orderBy(comments?.getAllCommentByTicket, ['createdAt'], ['desc']) || []
    const updateStatus = async () => {
        await updateReadStatus({
            variables: {
                ticketId: ticketId,
                userId: authManager.userId
            }
        })
        return true
    }
    // updateStatus()
    useEffect(() => {
        if (comments && comments?.getAllCommentByTicket) {
            updateStatus().then(r => {
                    return r
                }
            )
        }

    }, [comments])

    if (loading) return (<CardContainer><Loading/></CardContainer>)
    if (error) return (<CardContainer><LoadingError error={error}/></CardContainer>)


    if (!ticket) {
        return (
            <CardContainer>
                <Container spaceAbove="medium" spaceBelow="medium">
                    <H1>Not found</H1>
                    <Button onClick={onCancel}>Back to Tickets</Button>
                </Container>
            </CardContainer>
        )
    }

    async function ticketAction() {
        if (modalType === 'Delete') {
            await deleteTicket({
                variables: {
                    id: ticketId
                }
            })
        } else {
            await resolveTicket({
                variables: {
                    id: ticketId
                }
            })
        }

        history.goBack()
    }

    async function onCommentAdd(comment) {
        await addComment({
            variables: {
                userId: authManager.userId,
                comment,
                ticketId
            }
        })
        setSuccessMessage(true)
        setTimeout(() => {
            setSuccessMessage(false)
        }, 3000)
        refetch()
    }

    function onCancel() {
        history.goBack()
    }

    return (
        <CardContainer>
            <div>
                <Helmet>
                    <title>{`Ticket details`}</title>
                </Helmet>
                <Container spaceBelow="medium">
                    <FlashMessage locationState={location.state}/>
                    <Header>
                        <Left>
                            <div>
                                {/*<H1 spaceBelow="none">{ticket.supportType === 'OTHER' ? ticket.text : getTicketText(ticket.supportType)}</H1>*/}
                                <H1 spaceBelow="none">{ticket.supportType}</H1>
                                {ticket.text &&
                                <H3>
                                    {ticket.text}
                                </H3>
                                }
                                <Byline>
                                    {ticket?.createdBy?.isRootAdmin ? "Drivo Team" : ticket?.createdBy?.name}
                                    , created at{' '}
                                    {fmtDate(ticket.createdAt, installation.timeZoneName)}
                                </Byline>
                            </div>
                        </Left>
                        <Right>
                            {ticket.status === 'OPEN' ? (
                                <ColContainer>
                                    <HollowButton onClick={() => {
                                        setModalType("Resolve")
                                        setModalOpen(true)
                                    }} flavour={'primary'}>Resolve</HollowButton>

                                    <Button onClick={() => {
                                        setModalType("Delete")
                                        setModalOpen(true)
                                    }} flavour={'danger'}>Delete</Button>
                                </ColContainer>
                            ) : (
                                <StatusPill flavour={'secondary'}>
                                    RESOLVED
                                </StatusPill>
                            )}

                        </Right>

                    </Header>
                    <hr style={{'color': '#B1B1B2'}}/>
                    <br/>
                </Container>

                <Container spaceAbove="medium" spaceBelow="medium">
                    {showSuccessMessage && <Alert flavour="success">comment added</Alert>}
                    <TicketDetailsForm
                        installation={installation}
                        ticket={ticket}
                        ticketComments={ticketComments}
                        onCommentAdd={onCommentAdd}
                        onCancel={onCancel}/>
                </Container>

                <ReactModal
                    isOpen={isModalOpen}
                    onRequestClose={() => (setModalOpen(false))}
                    className={css({
                        display: 'block',
                        background: 'white',
                        margin: 10,
                        padding: 20,
                        borderRadius: 6,
                        outline: 'none',
                        minWidth: 300,
                        maxWidth: 550,
                    }).toString()}
                    overlayClassName={css({
                        backgroundColor: 'rgba(0,0,0,0.2)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'fixed',
                        zIndex: 100000,
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                    }).toString()}
                >
                    <h2 className={css({textAlign: 'left'})}>Are you sure you want to {modalType.toLowerCase()} this support ticket?</h2>

                    <ColContainer>
                        <HollowButton
                            flavour={modalType === "Resolve"? 'primary': 'danger'}
                            className={css({marginTop: 30})}
                            onClick={ticketAction}
                        >
                            {modalType}
                        </HollowButton>
                        <HollowButton
                            flavour={'dark'}
                            className={css({marginTop: 30})}
                            onClick={() => {
                                setModalOpen(false)
                            }}
                        >
                            Cancel
                        </HollowButton>
                    </ColContainer>
                </ReactModal>

            </div>
        </CardContainer>
    )
}

export default withInstallation(TicketDetails)
