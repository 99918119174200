import React from 'react';
import {Container, CardContainer} from '../../../components/Container';
import {H1} from '../../../components/Typography';
import {Helmet} from 'react-helmet';
import {withInstallation} from '../../../components/InstallationContext';

import ParkingLotCreateForm from './DetailsForm';

function ParkingLotCreate({installation, history}) {
    function onDone(lot) {
        history.goBack(lot ? {
            flash: {
                flavour: 'success',
                message: `${lot.name} created successfully`,
                createdAt: Date.now(),
            },
        } : null);
    }

    return (
        <CardContainer>
            <Container  spaceBelow="medium">
                <Helmet>
                    <title>New parking lot</title>
                </Helmet>
                <H1  spaceBelow="large">
                    New parking lot
                </H1>
                <ParkingLotCreateForm
                    installation={installation}
                    onSubmit={onDone}
                    onCancel={onDone}
                />
            </Container>
        </CardContainer>
    )
}

export default withInstallation(ParkingLotCreate);
