import React, {Component, createContext, useEffect, useState} from 'react'
import gql from 'graphql-tag'
import { Error404 } from './ErrorPage'
import { Loading, LoadingError } from './LoadingFork'
import { useQuery } from "@apollo/client"
import drivoApollo from "../lib/drivoApollo";
import {facilityByInstallation} from "../pages/Settings/RateCards/queries";

const InstallationContext = createContext({ installation: null, facility: null })
const installationByKeyQuery = gql`
	query findByBrandingKey($brandingKey: String!) {
		allInstallations(filter: { brandingKey: $brandingKey }) {
			id
			name
			brandingKey
			timeZoneName
			serviceFeePercent
			status

			hasPublicConsole
			publicConsoleSlug
		}
	}
`;

export function InstallationProvider ({ match, children }) {
	const [facility, setFacility] = useState(null);
	const {
		data,
		loading,
		error
	} = useQuery(installationByKeyQuery, {
		variables: {
			brandingKey: match.params.brandingKey,
		}
	})

	useEffect(() => {
		const getFacility = async () => {
			if (data && data.allInstallations[0]) {
				const {data: dataInstallation} = await drivoApollo.query({
					query: facilityByInstallation,
					fetchPolicy: 'network-only',
					variables: {id: data.allInstallations[0].id},
				})
				setFacility(dataInstallation.facility);
			}
		}
		getFacility();
	}, [data])

	if (loading || !facility) return <Loading />
	if (error) return <LoadingError error={error} />
	if (!data) return <Error404 />

	const installation = data.allInstallations[0]

	if (!installation) return <Error404 />

	return <InstallationContext.Provider value={{
		installation, facility
	}}>{children}</InstallationContext.Provider>
}

export function withInstallation (WrappedComponent) {
	return class WithInstallation extends Component {
		static displayName = `WithInstallation(${WrappedComponent.displayName || WrappedComponent.name})`;
		render() {
			return <InstallationContext.Consumer>
				{({ installation, facility }) => (
					<WrappedComponent {...this.props} installation={installation} facility={facility} />
				)}
			</InstallationContext.Consumer>
		}
	}
}
