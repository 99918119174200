import React, {useState} from 'react'
import {Button, HollowButton, NakedButton} from '../../../components/Button'
import {Container, ColContainer, TPL_50_50, CardContainer} from '../../../components/Container';
import {ValidationErrorMessage} from '../../../components/ErrorMessage';
import {H2} from '../../../components/Typography';
import {Input} from '../../../components/Input';
import {useFormState} from 'react-use-form-state'
import {useMutation, useQuery} from "@apollo/client"
import {withInstallation} from '../../../components/InstallationContext';
import {SingleDateInput} from '../../../components/SingleDateInput.js'
import {
    getSanctionedTimeById,
    updateSanctionedTimeMutation,
    deleteSanctionedTimeMutation
} from './queries';
import drivoApollo from "../../../lib/drivoApollo";
import moment from "moment";
import {Loading, LoadingError} from "../../../components/LoadingFork";
import {Alert} from "../../../components/Alert";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import {fmtDate} from "../../../lib/misc";


function SanctionedTimeUpdate({sanctionedTime, id, setSuccessMessage, history, installationId, timezone}) {
    //form
    const [open, setOpen] = React.useState(false);
    const [updateSanctionedTime] = useMutation(updateSanctionedTimeMutation, {
        client: drivoApollo
    })

    const [deleteSanctionedTime] = useMutation(deleteSanctionedTimeMutation, {
        client: drivoApollo
    })

    const [{errors, values}, {number}] = useFormState({
        date: sanctionedTime.date ? fmtDate(sanctionedTime.date, timezone, 'YYYY-MM-DD') : '',
        start: sanctionedTime.start || '',
        end: sanctionedTime.end || '',
    })

    async function onUpdate() {
        const newInput = {
            id: id,
            start: values.start,
            end: values.end
        }
        if (values.date !== fmtDate(sanctionedTime.date, timezone, 'YYYY-MM-DD')) {
            Object.assign(newInput, {
                date: fmtDate(values.date, timezone, 'YYYY-MM-DD')
            })
        }

        const {data} = await updateSanctionedTime({
            variables: {
                input: newInput
            },
        })
        if (data.updateSanctionedTimeById) {
            history.goBack()
        } else {
            errors.date = `Duplicate entry not allowed for ${fmtDate(values.date, timezone, 'YYYY-MM-DD')}`
        }

    }

    const deleteSubmit = async () => {
        setOpen(false)
        const {date} = await deleteSanctionedTime({
            variables: {id, installationId: installationId}
        })
            history.goBack()
    }

    const [date, setDate] = useState(fmtDate(sanctionedTime.date, timezone, 'YYYY-MM-DD'))
    const firstError = Object.values(errors)[0]
    const hasErrors = !!firstError

    const isUpdate = (
        fmtDate(sanctionedTime.date, timezone, 'YYYY-MM-DD') !== values.date ||
        sanctionedTime.start !== values.start ||
        sanctionedTime.end !== values.end ||
        hasErrors
    )


    function onCancel() {
        history.goBack()
    }

    function dateChange(date) {
        values.date = date.format('YYYY-MM-DD')
        setDate(date)
    }

    function validateTime(value) {
        if (value > 0 && value <= 2400 && value.length === 4) return
        return "Invalid time format, time must be in between 0001-2400"
    }

    return <div>

        <CardContainer>
            <Container spaceAbove="medium" spaceBelow="medium">

                <H2>Sanctioned Time Details</H2>
                {hasErrors && <ValidationErrorMessage spaceBelow="medium" error={firstError}/>}

                <ColContainer columnTemplate={TPL_50_50} spaceAbove="medium">
                    <Input required
                           label="Start"
                           error={errors.start}
                           {...number({
                               name: 'start',
                               validate: validateTime,
                               validateOnBlur: true

                           })} />

                    <Input required
                           label="End"
                           error={errors.end}
                           {...number({
                               name: 'end',
                               validate: validateTime,
                               validateOnBlur: true
                           })} />
                    <SingleDateInput
                        date={date}
                        onChange={dateChange}
                        label={"Date"}
                    />

                </ColContainer>
            </Container>
            <ColContainer spaceBelow='medium'>
                <Button type="submit" style={{'cursor': 'pointer'}} spaceAbove="small" disabled={!isUpdate}
                        flavour='primary'
                        onClick={(values) => {
                            onUpdate(values)
                        }}>
                    Update
                </Button>
                <Button style={{'cursor': 'pointer'}} flavour="gray" spaceAbove="small" onClick={onCancel}>
                    Cancel
                </Button>
            </ColContainer>
            <Button block
                    flavour='danger'
                    onClick={() => {
                        setOpen(true)
                    }}
            >
                Delete
            </Button>
        </CardContainer>

        <Dialog
            open={open}
            onClose={() => {
                setOpen(false)
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this record ?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <HollowButton
                    size={"small"}
                    onClick={() => {
                        setOpen(false)
                    }}>Cancel</HollowButton>
                <HollowButton
                    size={"small"}
                    flavour='danger'
                    onClick={deleteSubmit} autoFocus>
                    Delete
                </HollowButton>
            </DialogActions>
        </Dialog>

    </div>
}

function SanctionedDetail({match, history, installation}) {

    const {id} = match.params

    const [showSuccessMessage, setSuccessMessage] = useState(false)
    const {data = {}, loading, error} = useQuery(getSanctionedTimeById, {
        client: drivoApollo,
        fetchPolicy: 'no-cache',
        variables: {
            id: id
        }
    })

    if (loading) return (<CardContainer><Loading/></CardContainer>)
    if (error) return (<CardContainer><LoadingError error={error}/></CardContainer>)
    const sanctionedTime = data.getSanctionedById


    return (
        <CardContainer>
            <div>
                <Container spaceAbove="small" spaceBelow="small">
                    {showSuccessMessage && <Alert flavour="success">Your changes have been saved.</Alert>}
                    <SanctionedTimeUpdate
                        sanctionedTime={sanctionedTime}
                        id={id}
                        history={history}
                        setSuccessMessage={setSuccessMessage}
                        installationId={installation.id}
                        timezone={installation.timeZoneName}
                    />
                </Container>
            </div>
        </CardContainer>
    )

}

export default withInstallation(SanctionedDetail)
