import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { Button } from '../../components/Button';
import { GraphqlErrorMessage } from '../../components/ErrorMessage';
import { H2 } from '../../components/Typography';
import { Input } from '../../components/Input';
import { createEmailMutation } from './mutations';
import { css } from 'glamor';
import { useMutation } from "@apollo/client"

const EMAIL_TYPE = {
	BookingConfirmationEmail: 'booking confirmation',
	BookingReceiptEmail: 'receipt',
}

function ResendEmailForm ({
	installationId,
	bookingId,
	emailType,
	guestEmail,
	onSuccess,
	onCancel
}) {
	const [email, setEmail] = useState(guestEmail)
	const [createEmail, { loading, error }] = useMutation(createEmailMutation)
	const emailTypeName = EMAIL_TYPE[emailType]

	async function sendEmail () {
		await createEmail({
			variables: {
				installationId,
				relatedId: bookingId,
				settingsJson: JSON.stringify({
					overrideEmail: email
				})
			}
		})

		onSuccess()
	}

	return <>
		<H2 spaceAbove='none'>Resend {emailTypeName} email</H2>
		{error && <GraphqlErrorMessage error={error} />}
		<Input
			label='Email address'
			name='overrideEmail'
			value={email}
			onValueChange={value => setEmail(value)}
			disabled={loading}
		/>
		<Button spaceBelow='none' type='submit' onClick={sendEmail} disabled={loading}>
			Send
		</Button>
		<Button
			spaceBelow='none'
			mode='naked'
			flavour='gray'
			onClick={onCancel}
			disabled={loading}
		>
			Cancel
		</Button>
	</>
}

function SuccessMessage ({ emailType, onClose }) {
	const emailTypeName = EMAIL_TYPE[emailType]

	return <div className={css({ textAlign: 'center' })}>
		<H2 spaceAbove='medium'>Success</H2>
		<p>
			The <strong>{emailTypeName}</strong> email has been resent.
		</p>
		<Button spaceAbove='medium' onClick={onClose}>Done</Button>
	</div>
}

function ResendEmail ({ booking, hasCharged }) {
	const [isFormOpen, setFormOpen] = useState(false)
	const [success, setSuccess] = useState(false)

	const emailType = hasCharged ? 'BookingReceiptEmail' : 'BookingConfirmationEmail'
	const emailTypeName = EMAIL_TYPE[emailType]

	function openForm () {
		setFormOpen(true)
	}

	function closeForm () {
		setFormOpen(false)
		setSuccess(false)
	}

	function onSuccess () {
		setSuccess(true)
	}

	return <div>
		<Button block  flavour='gray' onClick={openForm}>
			Resend {emailTypeName} email
		</Button>
		<ReactModal
			isOpen={isFormOpen}
			onRequestClose={closeForm}
			className={css({
				display: 'block',
				background: 'white',
				margin: 10,
				padding: 20,
				borderRadius: 6,
				outline: 'none',
				minWidth: 400,
			}).toString()}
			overlayClassName={css({
				backgroundColor: 'rgba(0,0,0,0.2)',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				position: 'fixed',
				zIndex: 100000,
				top: 0,
				right: 0,
				bottom: 0,
				left: 0,
			}).toString()}
		>
			{success ? (
				<SuccessMessage emailType={emailType} onClose={closeForm} />
			) : (
				<ResendEmailForm
					onSuccess={onSuccess}
					onCancel={closeForm}
					guestEmail={booking?.guestEmail}
					installationId={booking?.installationId}
					bookingId={booking?.id}
					emailType={emailType}
				/>
			)}
		</ReactModal>
	</div>
}

export default ResendEmail
