import {ApolloClient, HttpLink, InMemoryCache} from '@apollo/client';

export default new ApolloClient({
    link: new HttpLink({
        uri: process.env.REACT_APP_GRAPHQL_URI,
        credentials: 'include',
    }),
    cache: new InMemoryCache(
        {
            typePolicies: {
                Query: {
                    fields: {
                        allBookings: {
                            // Don't cache separate results based on
                            // any of this field's arguments.
                            keyArgs: ["filter"],
                            // Concatenate the incoming list items with
                            // the existing list items.
                            merge(existing = [], incoming) {
                                return [...existing, ...incoming];
                            },
                        },
                        allBookableEvents: {
                            // Don't cache separate results based on
                            // any of this field's arguments.
                            keyArgs: ["filter"],
                            // Concatenate the incoming list items with
                            // the existing list items.
                            merge(existing = [], incoming) {
                                return [...existing, ...incoming];
                            },
                        },

                    }
                }
            }
        }
    )
})
