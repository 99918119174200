import React, { useState } from "react";
import { css } from "glamor";
import { format, formatInTimeZone, utcToZonedTime } from "date-fns-tz";
import { useMutation, useQuery } from "@apollo/client";
import * as theme from "../../theme";

import { Button, HollowButton } from "../../components/Button";
import {
  CardContainer,
  ColContainer,
  Container,
} from "../../components/Container";
import { H1, H2 } from "../../components/Typography";
import { Loading, LoadingError } from "../../components/LoadingFork";
import { Table, TBody, THead } from "../../components/Table";
import { Well } from "../../components/Well";
import { withInstallation } from "../../components/InstallationContext";

import LinkTR from "./components/LinkTR";
import RateCardLabel from "./components/RateCardLabel";
import SearchForm from "./components/SearchForm";
import { InlineBookingStatus } from "./components/BookingStatus";
import { listBookings, listGateCharges } from "./queries";
import drivoApollo from "../../lib/drivoApollo";
import ReactModal from "react-modal";
import { Input } from "../../components/Input";
import { ValidationErrorMessage } from "../../components/ErrorMessage";
import { sendPayAtGateEmailMutation } from "../Settings/RateCards/queries";
import { CellPill } from "../../components/StatusPill";
import { DRIVO_URL } from "../../lib/drivoApollo";
import { DateTime } from "luxon";

const paddingLeft = css({ paddingLeft: "40px !important" });
const textBold = css({ fontWeight: "bold", fontSize: 14 });
const textGray = css({ color: theme.gray40, fontSize: 14 });
const rowStyle = css({
  "> td": {
    verticalAlign: "middle",
    height: 60,
    borderBottom: `1px solid ${theme.gray10}`,
    transition: "all .2s linear",
    textAlign: "center",
  },
  "> td:first-child": { paddingLeft: 10 },
  "> td:last-child": { paddingRight: 10 },
  ":hover > td": {
    background: theme.gray10,
    cursor: "pointer",
  },
});

const extraRowStyle = css({
  "> td": {
    verticalAlign: "middle",
    height: 60,
    borderBottom: `1px solid ${theme.gray10}`,
    transition: "all .2s linear",
    textAlign: "center",
    background: theme.barelyYellow,
  },
  "> td:first-child": { paddingLeft: 10 },
  "> td:last-child": { paddingRight: 10 },
  ":hover > td": {
    background: theme.gray10,
    cursor: "pointer",
  },
});

async function EmailButtonClick(booking, setFormOpen, setSelectBooking) {
  await setSelectBooking(booking);
  setFormOpen(true);
}

function PayAtGateRow({ booking, setFormOpen, setSelectBooking, installationTimezone }) {

  return (
    <LinkTR className={rowStyle}>
      <td className={textBold}>{booking.plate}</td>
      <HollowButton
        onClick={() => EmailButtonClick(booking, setFormOpen, setSelectBooking)}
        className={css({
          marginTop: "20px",
          fontSize: "14px",
          fontWeight: "inherit",
          height: "unset",
        })}
      >
        Email
      </HollowButton>
      <td className={textGray}>
        {formatInTimeZone(new Date(Number(booking.createdAt)), installationTimezone, "yyyy-MM-dd")}
      </td>
      <td className={textGray}>-</td>
      <td className={textGray}>Pay @ Gate</td>
      <td>${booking.amount / 100}</td>
    </LinkTR>
  );
}

function GetCleanIntervalForBooking(bookingInterval, timezone) {
  if (bookingInterval == null) {
    return "";
  }

  let start = bookingInterval.split("/")[0];
  let end = bookingInterval.split("/")[1];
  // 2021-05-13T07:29:00.643+10:00/2021-05-13T08:42:37.081+10:00
  let startDate = utcToZonedTime(new Date(start), timezone);
  let endDate = utcToZonedTime(new Date(end), timezone);

  return `${format(startDate, "h:maaa d MMM", { timeZone: timezone })} to 
    ${format(endDate, "h:maaa d MMM", { timeZone: timezone })}`;
}

function BookingList({ installation, facility, history, match }) {
  function fmtDate(date) {
    if (!date) {
      return "-";
    }
    try {
      return DateTime.fromMillis(Number(date), { locale: "en-AU" })
        .setZone(installation.timeZoneName)
        .toFormat("yyyy/MM/dd ZZZZ");
    } catch (error) {
      console.error(
        "Error Parsing %o into a Date:",
        date,
        JSON.stringify(error, Object.getOwnPropertyNames(error))
      );
      return "-";
    }
  }
  function BookingRow({ href, booking }) {
    return (
      <LinkTR
        className={
          booking.bookingExtras && booking.bookingExtras.length > 0
            ? extraRowStyle
            : rowStyle
        }
        href={href}
      >
        <td className={textBold}>
          <CellPill color="#37B534" width="100px" size="tableCell">
            {booking.plate}
          </CellPill>
        </td>
        <td>
          {booking.guestName && (
            <CellPill
              className={textBold}
              width="100px"
              color="#FFA800"
              size="tableCell"
            >
              {booking.guestName}
            </CellPill>
          )}
        </td>
        <td className={textGray}>
          <CellPill width="117px" color="#FF4773" size="tableCell">
            {" "}
            {fmtDate(booking.startDay)}
          </CellPill>
        </td>
        <td className={textGray}>
          <CellPill width="117px" color="#45B5F4" size="tableCell">
            {fmtDate(booking.endDay)}
          </CellPill>
        </td>
        <td className={textGray}>
          <CellPill width="237px" color="#08515B" size="tableCell">
            {" "}
            <RateCardLabel rateCard={booking.rateCard} />
          </CellPill>
        </td>
        <td>
          <InlineBookingStatus status={booking.status} />
        </td>
      </LinkTR>
    );
  }

  const [statusFilter, setStatusFilter] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [sendPayAtGateEmail, { error: sendPayAtGateEmailError }] = useMutation(
    sendPayAtGateEmailMutation,
    { client: drivoApollo }
  );
  const {
    data = {},
    loading,
    error,
    refetch,
    fetchMore,
  } = useQuery(listBookings, {
    variables: {
      installationId: installation.id,
      offset: 0,
      status: statusFilter || undefined,
      search: searchTerm || undefined,
    },
  });

  const { data: gateData = {}, loading: gateLoading } = useQuery(
    listGateCharges,
    {
      fetchPolicy: "no-cache",
      variables: {
        input: {
          installationId: installation.id,
          offset: 0,
          search: searchTerm || "",
        },
      },
      client: drivoApollo,
    }
  );

  const bookings = data.allBookings || [];
  const bookingsCount = (data._allBookingsMeta || { count: 0 }).count;
  const moreToLoad = bookings.length < bookingsCount;

  const gateBookings = gateData.getGateChargesForInstallation || [];

  function loadMore() {
    fetchMore({
      variables: {
        offset: bookings.length,
      },
    });
  }

  function onSearch(e) {
    setStatusFilter(e.statusFilter);
    setSearchTerm(e.searchTerm);
  }

  async function sendMail() {
    if (name === "") {
      setModalNameError("Please Fill Name ..");
    } else if (email === "") {
      setModalError("Please Fill Email ..");
    } else {
      await sendPayAtGateEmail({
        variables: {
          input: {
            name: name,
            email: email,
            chargeId: selectBooking.id,
          },
        },
      });
      closeModal();
    }
  }

  function closeModal() {
    setModalError("");
    setModalNameError("");
    setFormOpen(false);
    setName("");
    setEmail("");
  }

  function ChangeValue(e, stateValue) {
    setModalError("");
    setModalNameError("");
    stateValue(e);
  }

  const [isFormOpen, setFormOpen] = useState(false);
  const [selectBooking, setSelectBooking] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [modalNameError, setModalNameError] = useState("");
  const [modalError, setModalError] = useState("");

  return (
    <div>
      <CardContainer>
        <Container size="large" spaceBelow="medium"  >
          <H1>Bookings </H1>
          <div
            style={{
              display: "flex",
              gap: "20px",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <SearchForm onUpdate={onSearch} />
            <Button
              target={"_blank"}
              href={`${DRIVO_URL}/extras-report/${facility.id}/download`}
              spaceAbove="medium"
              spaceBelow="medium"
              className={css({
                marginTop: 0,
                marginBottom: 16,
              })}
            >
              Download Extra Report
            </Button>
          </div>
        </Container>
        <Container size="large">
          {error ? <LoadingError error={error} /> : null}
          {bookings.length === 0 && gateBookings.length === 0 ? (
            <Well>
              <div>
                <H2 spaceAbove="large" spaceBelow="large">
                  {loading ? <Loading /> : "No bookings available"}
                </H2>
              </div>
            </Well>
          ) : (
            <div>
              <Table>
                <THead>
                  <tr>
                    <th style={{ textAlign: "center" }}>Licence plate</th>
                    <th style={{ textAlign: "center" }}>Customer</th>
                    <th style={{ textAlign: "center" }}>Start Day</th>
                    <th style={{ textAlign: "center" }}>End Day</th>
                    <th style={{ textAlign: "center" }}>Guest card</th>
                    <th style={{ textAlign: "center" }}>Status</th>
                  </tr>
                </THead>
                <TBody>
                  {bookings.map((booking) => (
                    <BookingRow
                      key={booking.id}
                      booking={booking}
                      href={`${match.url}/${booking.id}`}
                    />
                  ))}
                  {statusFilter === "" &&
                    gateBookings.map((booking) => (
                      <PayAtGateRow
                        key={booking.id}
                        booking={booking}
                        setFormOpen={setFormOpen}
                        setSelectBooking={setSelectBooking}
                        installationTimezone={installation.timeZoneName}
                      />
                    ))}
                </TBody>
              </Table>
              <div
                className={css({
                  textAlign: "center",
                })}
              >
                {moreToLoad ? (
                  <Button
                    onClick={loadMore}
                    spaceAbove="medium"
                    spaceBelow="medium"
                  >
                    Load more results
                  </Button>
                ) : null}
                <HollowButton
                  className={css({ marginLeft: 20 })}
                  onClick={() => refetch()}
                  spaceAbove="medium"
                  spaceBelow="medium"
                >
                  Refresh
                </HollowButton>
              </div>
            </div>
          )}

          <ReactModal
            isOpen={isFormOpen}
            onRequestClose={closeModal}
            className={css({
              display: "block",
              background: "white",
              margin: 10,
              padding: 20,
              borderRadius: 6,
              outline: "none",
              minWidth: 400,
            }).toString()}
            overlayClassName={css({
              backgroundColor: "rgba(0,0,0,0.2)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "fixed",
              zIndex: 100000,
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            }).toString()}
          >
            {modalError && <ValidationErrorMessage error={modalError} />}
            {modalNameError && (
              <ValidationErrorMessage error={modalNameError} />
            )}
            <h1 className={css({ textAlign: "left" })}>
              {selectBooking.plate}
            </h1>
            <ColContainer>
              <h3 className={css({ textAlign: "left" })}>
                {GetCleanIntervalForBooking(
                  selectBooking.interval,
                  installation.timeZoneName
                )}
              </h3>
              <h3 className={css({ textAlign: "right" })}>
                ${selectBooking.amount / 100}
              </h3>
            </ColContainer>
            <ColContainer>
              <Input
                required
                error={modalNameError}
                label="Name"
                value={name}
                onValueChange={(e) => ChangeValue(e, setName)}
              />
              <Input
                required
                error={modalError}
                label="Email"
                value={email}
                onValueChange={(e) => ChangeValue(e, setEmail)}
              />
            </ColContainer>
            <HollowButton className={css({ marginTop: 30 })} onClick={sendMail}>
              Send Email
            </HollowButton>
          </ReactModal>
        </Container>
      </CardContainer>
    </div>
  );
}

export default withInstallation(BookingList);
