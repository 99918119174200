import React from 'react';
import ReactSVG from 'react-svg';
import * as theme from '../../../../theme';
import starIcon from '../../../../icons/star.svg';
import { StatusPill } from '../../../../components/StatusPill';
import { css } from 'glamor';

// TODO: not showing sometimes
export function PublicStar () {
	return <ReactSVG
		path={starIcon}
		className={css({
			display: 'inline-block',
			paddingLeft: 10,
			color: theme.blue,
		}).toString()}
	/>
}

export function Header (props) {
	return <div
		className={css({
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'stretch',
		})}
		{...props}
	/>
}

export function Status ({
	defaultOnExit,
	defaultPublic,
	isArchived
}) {
	return <StatusPill flavour={isArchived ? 'light' : 'success'}>
		{isArchived ? 'Archived' : 'Active'}
		{defaultOnExit ? ` / Exiting` : ``}
		{defaultPublic ? ` / Public` : ``}
	</StatusPill>
}
