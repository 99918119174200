import React from 'react';
import { css } from 'glamor';
import * as theme from '../theme';
import { Label } from './Label';
import imgCheck from '../icons/check.svg';

export function CheckboxInput ({
	label,
	error,
	className,
	checked,
	disabled,
	...props
}) {
	return (
		<label className={className}>
			{label && <Label error={error}>{label}</Label>}
			<input
				type="checkbox"
				className={css({
					position: 'absolute',
					left: -10000000,
					':focus + span': {
						outline: 'none',
						borderColor: theme.blue,
						transform: 'scale(1.2)',
					},
				})}
				{...props}
				disabled={disabled}
				checked={checked}
			/>
			<span
				className={css(
					{
						backgroundColor: theme.gray05,
						borderColor: theme.gray15,
						borderWidth: 2,
						borderStyle: 'solid',
						display: 'inline-flex',
						alignItems: 'center',
						justifyContent: 'center',
						width: 24,
						height: 24,
						borderRadius: 6,
						transition: 'all 100ms linear',
					},
					!disabled && {
						':hover': {
							outline: 'none',
							borderColor: theme.blue,
							transform: 'scale(1.2)',
						},
						':active': {
							transform: 'scale(1.4)',
						},
					},
					checked && {
						backgroundColor: theme.green,
						borderColor: 'transparent',
					},
					disabled && {
						opacity: 0.5,
					},
					disabled &&
						checked && {
							backgroundColor: theme.gray60,
						},
				)}
			>
				{checked && <img src={imgCheck} alt="Yes" />}
			</span>
		</label>
	);
}
