import React, { useState, useEffect } from 'react';
import {css} from 'glamor';
import {useQuery} from "@apollo/client";

import * as theme from '../../../theme';
import {withInstallation} from '../../../components/InstallationContext';
import {FlashMessage} from '../../../components/FlashMessage';
import {StatusPill} from '../../../components/StatusPill';
import {Button} from '../../../components/Button';
import {Container, CardContainer} from '../../../components/Container';
import {Left, Right} from '../../../components/Header';
import {Well} from '../../../components/Well';

import {H1, H2} from '../../../components/Typography';
import {Table, THead, TBody} from '../../../components/Table';
import {Loading, LoadingError} from '../../../components/LoadingFork';
import {listUsersQuery} from './queries';
import authManager from '../../../lib/authManager';


function Header(props) {
    return <div
        className={css({
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'stretch',
        })}
        {...props}
    />
}

function Status({isEnabled}) {
    return <td>
        <div
            className={css({
                display: 'flex',
                justifyContent: 'stretch',
                flexDirection: 'column',
            })}
        >
            <StatusPill flavour={isEnabled ? 'success' : 'light'}>
                {isEnabled ? 'Active' : 'Inactive'}
            </StatusPill>
        </div>
    </td>
}

const rowStyle = css({
    '> td': {
        verticalAlign: 'middle',
        height: 60,
        borderBottom: `1px solid ${theme.gray10}`,
        transition: 'all .2s linear',
    },
    '> td:first-child': {paddingLeft: 10},
    '> td:last-child': {paddingRight: 10},
    ':hover > td': {
        background: theme.gray10,
        cursor: 'pointer',
    },
});

function roleFromPermission(permission) {
    if (permission && permission.isAdmin) return 'Hotel Admin';
    if (permission && !permission.isAdmin) return 'Receptionist';
    return 'None';
}

function UserRow({user, onClick}) {
    const {name, email, phone, userPermissions} = user
    const permission = userPermissions && userPermissions[0]
    return <tr key={user.id} className={rowStyle} onClick={onClick}>
        <td>{name}</td>
        <td>{roleFromPermission(permission)}</td>
        <td>{email}</td>
        <td>{phone}</td>
        <Status isEnabled={user.isEnabled}/>
    </tr>
}

function filterInactiveUsers(users) {
    return users.filter( (user) =>  user?.isEnabled);
}

function UsersList({match, installation, location, history}) {
    const {data = {}, loading, error} = useQuery(listUsersQuery, {
        fetchPolicy: 'no-cache',
        variables: {
            installationId: installation.id
        }
    })
    const [users, setUsers] = useState([]);

    useEffect( () => {
        if (!loading) {
            if (authManager.isRootAdmin()) {
                setUsers(data.allUsers);
            } else {
                setUsers(filterInactiveUsers(data.allUsers))
            }
        }
    }, [data]);

    return (
        <div>
            <CardContainer>
                <Container size="large" spaceAbove="small" spaceBelow="small">
                    <Header>
                        <Left>
                            <H1>Users</H1>
                        </Left>
                        <Right>
                            {!loading && users.length > 0 && (
                                <Button to={`${match.url}/new`} style={{'background-color':'#3127B8'}}>New user</Button>
                            )}
                        </Right>
                    </Header>
                </Container>
                <Container spaceBelow="large">
                    <FlashMessage locationState={location.state}/>
                    {loading && <Loading/>}
                    {error && <LoadingError error={error}/>}
                    {!loading && users.length === 0 && <Well>
                        <div>
                            <H2 spaceBelow="large">No users available</H2>
                            <Button spaceBelow="none" to={`${match.url}/new`}>
                                Create a new user now
                            </Button>
                        </div>
                    </Well>}
                    {users.length > 0 && <Table>
                        <THead>
                            <tr>
                                <th>Name</th>
                                <th>Role</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>Status</th>
                            </tr>
                        </THead>
                        <TBody>
                        {
                            users.map( (user) => {
                                return(
                                    <UserRow
                                        key={user.id}
                                        user={user}
                                        onClick={() => {
                                            history.push(`${match.url}/${user.id}`);
                                        }}
                                    />
                                )
                            })
                        }
                        </TBody>
                    </Table>}
                </Container>
            </CardContainer>
        </div>
    )
}

export default withInstallation(UsersList)
