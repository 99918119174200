import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { Button } from '../../components/Button';
import { H2 } from '../../components/Typography';
import { createCancellationRequestMutation } from './mutations';
import { css } from 'glamor';
import { withInstallation } from "../../components/InstallationContext";
import { useMutation } from "@apollo/client";

const RequestCancellationForm = ({ onSubmit, onCancel, bookingId }) => {
	return(
        <div>
            <H2 spaceAbove="none">Request Cancellation</H2>

            <p>Are you sure you want to cancel this booking?</p>
            <Button
                spaceBelow="none"
                onClick={() => {
                    onSubmit(bookingId);
                }}
            >
                Yes
            </Button>
            <Button
                spaceBelow="none"
                mode="hollow"
                flavour="danger"
                onClick={onCancel}
                className={css({ marginLeft: "5px" })}
            >
                No
            </Button>
        </div>
    )
};

const ErrorMessage = ({ onClose, error }) => {
	return(
        <div className={css({ textAlign: 'center' })}>
            <H2 spaceAbove="medium">Something went wrong.</H2>
            <p>{error}</p>
            <Button spaceAbove="medium" onClick={onClose}>
                Confirm
            </Button>
        </div>
    )
}

const _RequestCancellation = ({ booking, onCancellationRequest, isCancellationAlreadyRequested, isCancellationProcessed }) => {
	const [isFormOpen, setIsFormOpen] = useState(false);
    const [error, setError] = useState(null);
    const [createCancellationRequest] = useMutation(createCancellationRequestMutation);

	const toggleForm = () => {
        setIsFormOpen(!isFormOpen);
        if (error) setError(null);
    }

	const onSuccess = () => {
		onCancellationRequest()
		toggleForm()
	}

    const handleSubmit = async (bookingId) => {
        try {
            await createCancellationRequest({
                variables: {
                    relatedBookingId: bookingId,
                }
            });
        
            onSuccess()
        } catch (error) {
            setError(error.message);
        }
    }

	return (
		<div>
			<Button block mode="hollow" flavour="warning" onClick={toggleForm} disabled={isCancellationAlreadyRequested}>
                { !isCancellationAlreadyRequested ? "Request Cancellation" : isCancellationProcessed ? "Booking Cancelled" : "Booking Cancellation In Progress" }
			</Button>
			<ReactModal
				isOpen={isFormOpen}
				onRequestClose={toggleForm}
				className={css({
					display: 'block',
					background: 'white',
					margin: 10,
					padding: 20,
					borderRadius: 6,
					outline: 'none',
					minWidth: 400,
				}).toString()}
				overlayClassName={css({
					backgroundColor: 'rgba(0,0,0,0.2)',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					position: 'fixed',
					zIndex: 100000,
					top: 0,
					right: 0,
					bottom: 0,
					left: 0,
				}).toString()}
			>
                { isFormOpen && !error &&
                    <RequestCancellationForm
                        onSubmit={handleSubmit}
                        onCancel={toggleForm}
                        bookingId={booking?.id}
                    />
                }
                { error &&
                    <ErrorMessage onClose={toggleForm} error={error} />
                }
			</ReactModal>
		</div>
	);
}

export default withInstallation(_RequestCancellation);
