import gql from 'graphql-tag'

export const listSupportTicketQuery = gql`
query SupportTicket($installationId:ID!,$userId:String!){
  getAllSupportTicketByInstallationId(installationId:$installationId,userId:$userId ){
    id
    createdAt
    createdBy{
      name
      isRootAdmin
    }
    supportType
    text
    status
    ticketNotifications{
      id
      userId
      readStatus
    }
  }
}
`

export const mutationSupportTicket = gql`
mutation SupportTicket($userId:String!, $supportType:String!,$installationId:ID!,$text:String){
  addSupportTicket(userId:$userId, supportType:$supportType, installationId:$installationId,text:$text){
    id
    createdAt
    createdBy{
      name
    }
    supportType
    text
  }
}
`

export const ticketById = gql`
query SupportTicket($ticketId:ID!){
  getSupportTicketById(ticketId:$ticketId){
    id
    createdAt
    createdBy{
      id
      name
      isRootAdmin
    }
    supportType
    text
    status
  }
}
`

export const mutationDeleteTicket = gql`
mutation SupportTicket($id:ID!){
  deleteSupportTicket(id:$id)
}
`

export const mutationAddComment = gql`
mutation TicketComments($userId:String!,$comment:String!,$ticketId:ID!){
  addTicketComments(userId:$userId, comment:$comment, ticketId:$ticketId){
    id
    comment
    createdBy{
      name
    }
  }
}
`

export const queryGetCommentsByTicket = gql`
query TicketComments($ticketId:ID!){
  getAllCommentByTicket(ticketId:$ticketId){
    id
    createdAt
    comment
    createdById
    createdBy{
      name
      isRootAdmin
    }
    
  }
}
`

export const mutationResolveTicket = gql`
mutation SupportTicket($id:ID!){
   updateTicketStatus(id:$id)
}
`
export const mutationAddUserNotification = gql`
mutation addUserNotification($userId:String!){
  addUserNotification(userId:$userId)
}
`
export const mutationReadStatusUserTicket = gql`
mutation updateReadStatusUserTicket($userId:String!, $ticketId:ID!){
  updateReadStatusUserTicket(userId:$userId,ticketId:$ticketId)
}
`
