import React from 'react';
import { configureForm } from '@thinkmill/pragmatic-forms';

import { ColContainer } from '../../../components/Container'
import { SearchInput } from '../../../components/SearchInput'
import { SelectInput } from '../../../components/SelectInput'
import * as enums from '../../../lib/enums'

const withForm = configureForm({
	initFields: () => ({
		searchTerm: '',
		statusFilter: '',
	}),
	onChange: (formData, { onUpdate }) => {
		onUpdate(formData);
	},
});

export default withForm(({ form }) => (
	<form.Form>
		<ColContainer>
			<SearchInput
				placeholder="Search customer or licence plate"
				{...form.getFieldProps({ name: 'searchTerm' })}
			/>
			<SelectInput
				options={enums.BOOKING_STATUS_SEARCH_OPTIONS}
				{...form.getFieldProps({
					name: 'statusFilter',
					type: 'select',
				})}
			/>
		</ColContainer>
	</form.Form>
));
