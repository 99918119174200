import React from 'react';
import { css } from 'glamor';
import * as theme from '../theme';
import loupe from '../icons/loupe.svg';

import { Label } from './Label';

const iconStyle = css({
	position: 'absolute',
	top: '50%',
	left: 16,
	transform: 'translateY(-50%)',
});

const inputStyle = css({
	appearance: 'none',
	display: 'block',
	width: '100%',
	background: theme.white,
	borderWidth: 2,
	borderStyle: 'solid',
	borderColor: theme.gray10,
	borderRadius: 6,
	height: 60,
	fontSize: 22,
	paddingLeft: 16 + 30,
	paddingRight: 16,
	marginBottom: 16,
	transition: theme.transitionSnappy,
	':focus': {
		outline: 'none',
		borderColor: theme.blue,
	},
	':disabled': {
		opacity: 0.5,
	},
	'::placeholder': {
		color: theme.gray20,
	},
});

const errorStyle = {
	color: theme.flavours.danger.foregroundColor,
	borderColor: theme.flavours.danger.highlightColor,
};

export const SearchInput = ({
	label,
	error,
	isDirty, // eslint-disable-line no-unused-vars
	onValueChange, // eslint-disable-line no-unused-vars
	...props
}) => {
	return (
		<div className={css({ position: 'relative' })}>
			{label && <Label error={error}>{label}</Label>}
			<div className={css({ position: 'relative' })}>
				<img className={iconStyle} src={loupe} alt="" />
				<input
					type="search"
					className={css(inputStyle, !!error && errorStyle)}
					{...props}
				/>
			</div>
		</div>
	);
};
