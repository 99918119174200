import React from "react";
import ReactSVG from "react-svg";

import gql from "graphql-tag";
import {RootOnly} from "../../../components/AdminOnly";
import {Button} from "../../../components/Button";
import {Container, CardContainer} from "../../../components/Container";
import {CurrencyDisplay} from "../../../components/CurrencyDisplay";
import {H1, H2, Byline} from "../../../components/Typography";
import {Helmet} from "react-helmet";
import {Loading, LoadingError} from "../../../components/LoadingFork";
import {StatusPill} from "../../../components/StatusPill";
import {Table, THead, TBody} from "../../../components/Table";
import {Well} from "../../../components/Well";
import {css} from "glamor";
import {fmtDate} from "../../../lib/misc";
import {formatCurrency} from "../../../lib/rateCardUtils";
import {gray10, gray40, flavours} from "../../../theme";
import {useMutation, useQuery} from "@apollo/client";
import {withAppConfig} from "../../../components/AppConfigContext";
import {withInstallation} from "../../../components/InstallationContext";
import downloadIconSVG from "../../../icons/download.svg";
import drivoApollo, {DRIVO_URL} from "../../../lib/drivoApollo";




export const createPayoutMutation = gql`
  mutation createPayout($installationId: ID!) {
    createPayout(payout: { installationId: $installationId }) {
      id
    }
  }
`;

export const financialsQuery = gql`
  query installations($installationId: ID!) {
      payouts(installationId:$installationId) {
        id
        createdAt
        finalisedAt
        amountAudRemit
      }
    }
`;

const statusFlavour = {
    PENDING: "warning",
    PROCESSED: "gray",
    REJECTED: "danger",
};

function DownloadLink({children, href}) {
    const className = css({
        color: flavours.primary.highlightColor,
        textDecoration: "none",
    });

    return (
        <a href={href} download className={className}>
            <ReactSVG
                src={downloadIconSVG}
                svgclassname={css({display: "block"}).toString()}
                className={css({
                    display: "inline-block",
                    marginRight: 10,
                }).toString()}
            />
            {children}
        </a>
    );
}

function _PayoutSummaryRow({
                               appConfig,
                               id,
                               createdAt,
                               finalisedAt,
                               amountAudRemit,
                               currency,
                               timezone,
                           }) {
    const status = finalisedAt ? "PROCESSED" : "PENDING";
    const acceptUrl = finalisedAt
        ? null
        : `${appConfig.APPLICATION_URL}/payouts/${id}/mark-as-processed`;

    return (
        <tr>
            <td>
                {fmtDate(createdAt, timezone, "d MMM yyyy")}{" "}
                {acceptUrl ? (
                    <RootOnly>
                        <a
                            href={acceptUrl}
                            className={css({"font-size": 12, color: gray40})}
                        >
                            (mark as processed)
                        </a>
                    </RootOnly>
                ) : null}
            </td>
            <td>{fmtDate(finalisedAt, timezone, "d MMM yyyy")}</td>
            <td>
                <DownloadLink
                    href={`${DRIVO_URL}/payouts/${id}/download`}
                >
                    Spreadsheet (.xls)
                </DownloadLink>
            </td>
            <td>
                <strong>
                    <CurrencyDisplay amountString={amountAudRemit} currency={currency} />
                </strong>
            </td>
            <td>
                <StatusPill flavour={statusFlavour[status]}>{status}</StatusPill>
            </td>
        </tr>
    );
}

const PayoutSummaryRow = withAppConfig(_PayoutSummaryRow);

function Payouts({appConfig, installation}) {
    const {data: drivoData, error: drivoError, refetch: refetchFinancials} = useQuery(
        gql`
      query Drivo($id: ID!) {
			facility: facilityByInstallation(installation: $id) {
				id
				financials
			}
	  }`,
        {
            client: drivoApollo,
            variables: {id: installation.id},
            fetchPolicy: "no-cache",
        }
    );

    const {data = {}, loading, error: historyError, refetch} = useQuery(
        financialsQuery,
        {
            fetchPolicy: "no-cache",
            client: drivoApollo,
            variables: {
                installationId: installation.id,
            },
        }
    );

    const {payouts = []} = data;

    const netUnpaid = (() => {
        if (
            !drivoError &&
            drivoData &&
            drivoData.facility &&
            drivoData.facility.financials
        ) {
            return drivoData.facility.financials.unpaid;
        } else {
            return 0;
        }
    })();
    const allowRequest = true; // TODO: netUnpaid > 0, probably

    const [createPayoutRequest, {error: requestError}] = useMutation(
        createPayoutMutation
    );
    const error = historyError || requestError;

    async function onSubmit() {
        await createPayoutRequest({
            variables: {
                installationId: installation.id,
            },
        });

        await Promise.all([refetch(), refetchFinancials()]);
    }

    return (
        <div>
                <CardContainer>
                    <Helmet>
                        <title>Payouts</title>
                    </Helmet>
                    <Container size="large" spaceAbove="small" spaceBelow="none">
        <pre style={{display: "none"}}>
          {JSON.stringify({drivoData, drivoError})}
        </pre>
                        <div
                            className={css({
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            })}
                        >
                            <H1>Payouts</H1>
                        </div>
                    </Container>
                    <Container size="large" spaceAbove="small" spaceBelow="medium">
                        <H2>Account</H2>
                        {loading ? <Loading/> : null}
                        {error ? <LoadingError error={error}/> : null}
                        {loading ? null : (
                            <div>
                                <div
                                    className={css({
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        backgroundColor: gray10,
                                        borderRadius: 6,
                                        paddingLeft: 20,
                                        paddingRight: 20,
                                        paddingTop: 16,
                                    })}
                                >
                                      <span
                                          className={css({
                                              textTransform: "uppercase",
                                              fontSize: 18,
                                              fontWeight: "800",
                                              marginBottom: 16,
                                          })}
                                      >
                                        Request payout
                                      </span>
                                    <span
                                        className={css({
                                            fontSize: 30,
                                            lineHeight: 1,
                                            fontWeight: "800",
                                            paddingLeft: 10,
                                            paddingRight: 10,
                                            marginBottom: 16,
                                            marginRight: 20,
                                        })}
                                    >
                                            {formatCurrency(netUnpaid, installation.currency)}
                                          </span>
                                    <Button type="submit"
                                            style={{backgroundColor: '#3127B8', cursor: 'pointer'}}
                                            onClick={onSubmit} disabled={!allowRequest}>
                                        Submit request
                                    </Button>
                                </div>
                            </div>
                        )}
                    </Container>
                    <Container spaceBelow="large">
                        <H2>Recent payouts</H2>
                        {payouts.length === 0 ? (
                            <Well>
                                <div>
                                    <H2>No payouts available</H2>
                                    <Byline spaceBelow="none">
                                        Use the form above to create your first payout.
                                    </Byline>
                                </div>
                            </Well>
                        ) : (
                            <Table>
                                <THead>
                                    <tr>
                                        <th>Requested</th>
                                        <th>Processed</th>
                                        <th>Download</th>
                                        <th>Amount</th>
                                        <th>Status</th>
                                    </tr>
                                </THead>
                                <colgroup>
                                    <col className={css({width: "20%"})}/>
                                    <col className={css({width: "20%"})}/>
                                    <col className={css({width: "25%"})}/>
                                    <col className={css({width: "20%"})}/>
                                    <col/>
                                </colgroup>
                                <TBody>
                                    {payouts.map((payout) => (
                                        <PayoutSummaryRow key={payout.id} {...payout} currency={installation.currency} timezone={installation.timeZoneName} />
                                    ))}
                                </TBody>
                            </Table>
                        )}
                    </Container>
                </CardContainer>
        </div>
    );
}

export default withInstallation(Payouts);
