import React from 'react'
import {Helmet} from 'react-helmet'
import {LoadingFork} from '../../components/LoadingFork'
import {Container} from '../../components/Container'
import {H1, H3, HR, Byline} from '../../components/Typography'
import {getProfile} from './queries'
import {CardContainer} from "../../components/Container";
import UserForm from './UserForm'
import PasswordForm from './PasswordForm'
import {graphql} from "@apollo/client/react/hoc";

function ProfilePage({data}) {
    return (
        <CardContainer>
            <LoadingFork
                isLoading={data.loading}
                error={data.error}
                render={() => (
                    <div>
                        <Helmet>
                            <title>User Profile</title>
                        </Helmet>
                        <Container size="medium"  spaceBelow="medium">
                            <H1>{data.loggedInUser.name}</H1>
                            <Byline>User Profile</Byline>
                            <HR/>
                        </Container>
                        <Container size="medium" spaceBelow="medium">
                            <H3>User Details</H3>
                            <UserForm User={data.loggedInUser} onSuccess={data.refetch}/>
                        </Container>
                        <Container size="medium" spaceBelow="medium">
                            <H3>Update password</H3>
                            <PasswordForm User={data.loggedInUser}/>
                        </Container>
                        <Container size="medium" spaceBelow="large">
                            <HR/>
                        </Container>
                    </div>
                )}
            />
        </CardContainer>

    )
}

export default graphql(getProfile)(ProfilePage)
