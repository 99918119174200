import React, { useState } from 'react';
import moment from 'moment';
import { css } from 'glamor';
import * as theme from '../theme';
import { Label } from './Label';
import { InlineError } from './InputInlineError';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import DayPickerSingleDateController from 'react-dates/lib/components/DayPickerSingleDateController';
import OutsideClickHandler from 'react-outside-click-handler';
import calendarIcon from '../icons/calendar.svg';

export function DateInput ({
	label,
	displayFormat,
	isOutsideRange,
	isDayHighlighted,
	floatPicker,

	error,
	onChange,
	onValueChange,
	value,
	...props
}) {
	displayFormat = displayFormat || 'DD/MM/YYYY'
	onValueChange = onChange || onValueChange

	const [focused, setFocused] = useState(false)

	function _onChange (date) {
		if (moment.isMoment(date)) {
			onValueChange(date.format('YYYY-MM-DD'));
		} else {
			onValueChange(date);
		}
	}

	const date = value ? moment(value) : null;
	const baseStyle = {
		position: 'relative',
		' .DayPicker': {
			borderRadius: 6,
			'z-index': '99 !important'
		},
		' .CalendarMonthGrid': {
			background: 'transparent',
		},
		' .CalendarDay__selected': {
			background: theme.blue,
			border: `1px double ${theme.blue}`,
			':active': {
				background: theme.gray70,
			},
		},
	};

	const floatedBaseStyle = floatPicker && {
		' .DayPicker': {
			position: 'absolute',
			top: 44,
			left: 16,
			'z-index': '99 !important'
		},
	};

	const focusedBaseStyle = focused && {
		' .DayPicker': {
			'z-index': '99 !important'
		},
	};

	const iconStyle = css({
		position: 'absolute',
		top: '50%',
		right: 16,
		transform: 'translateY(-50%)',
		pointerEvents: 'none',
	});

	const inputStyle = {
		display: 'block',
		width: '100%',
		background: theme.white,
		borderWidth: 2,
		borderStyle: 'solid',
		borderColor: theme.gray10,
		borderRadius: 6,
		height: 60,
		fontSize: 22,
		paddingLeft: 16,
		paddingRight: 16,
		marginBottom: 16,
		transition: theme.transitionSnappy,
		'::placeholder': {
			color: theme.gray20,
		},
		':focus': {
			outline: 'none',
		},
		':disabled': {
			opacity: 0.5,
		},
	};

	const focusedStyles = focused && {
		borderColor: theme.blue,
	};

	const errorStyle = !!error && {
		color: theme.flavours.danger.foregroundColor,
		borderColor: theme.flavours.danger.highlightColor,
	};

	return (
		<OutsideClickHandler onOutsideClick={() => setFocused(false)}>
			<div className={css(baseStyle, focusedBaseStyle, floatedBaseStyle)}>
				{label && <Label error={error}>{label}</Label>}
				<div className={css({ position: 'relative' })}>
					<img className={iconStyle} src={calendarIcon} alt="" />
					<input
						className={css(inputStyle, focusedStyles, errorStyle)}
						value={date ? date.format(displayFormat) : ''}
						onFocus={() => setFocused(true)}
						readOnly
						{...props}
					/>
				</div>
				{focused && (
					<DayPickerSingleDateController
						date={date}
						onDateChange={_onChange}
						onFocusChange={({ focused }) => setFocused(focused)}
						enableOutsideDays={false}
						isOutsideRange={isOutsideRange}
						isDayHighlighted={isDayHighlighted}
						numberOfMonths={1}
						hideKeyboardShortcutsPanel
					/>
				)}
				{error && <InlineError error={error} />}
			</div>
		</OutsideClickHandler>
	);
}
