import gql from 'graphql-tag';

export const listSanctionedPlatesQuery = gql`
	query ListSanctionedPlates($installationId: ID!) {
		allSanctionedPlates(filter: { installationId: $installationId }, orderBy: label_ASC) {
			id
			plate
			label
			isEnabled
			lot {
				name
			}
		}
	}
`;

export const createSanctionedPlateQuery = gql`
	mutation CreateSanctionedPlate(
		$installationId: ID!
		$createdById: ID!
		$plate: String!
		$label: String
		$lotId: ID
	) {
		createSanctionedPlate(
			sanctionedPlate: {
				installationId: $installationId
				createdById: $createdById
				plate: $plate
				label: $label
				isEnabled: true
				lotId: $lotId
			}
		) {
			id
			plate
			label
			isEnabled
		}
	}
`;

export const updateSanctionedPlateQuery = gql`
	mutation UpdateSanctionedPlate(
		$id: ID!
		$plate: String!
		$label: String
		$isEnabled: Boolean!
		$currentUser: ID!
		$lotId: ID
	) {
		updateSanctionedPlate(
			sanctionedPlate: {
				id: $id
				plate: $plate
				label: $label
				isEnabled: $isEnabled
				updatedById: $currentUser
				lotId: $lotId
			}
		) {
			id
		}
	}
`;

export const deleteSanctionedPlateQuery = gql`
	mutation DeleteSanctionedPlate($id: ID!) {
		deleteSanctionedPlate(id: $id) {
			id
		}
	}
`;
