import React, { Component } from 'react';
import { Alert } from './Alert';

const DEFAULT_DELAY = 3000;

export class FlashMessage extends Component {
	state = {
		timerComplete: false,
	};

	componentDidMount() {
		const { locationState } = this.props;
		if (locationState && locationState.flash) {
			this.timeout = setTimeout(() => {
				this.setState({ timerComplete: true });
			}, locationState.flash.delay || DEFAULT_DELAY);
		}
	}

	componentWillUnmount() {
		clearTimeout(this.timeout);
	}

	render() {
		const { spaceAbove, spaceBelow, locationState } = this.props;
		if (!locationState || !locationState.flash) return null;
		if (this.state.timerComplete) return null;

		const { flavour, createdAt, message, delay } = locationState.flash;

		if (createdAt && Date.now() - createdAt > (delay || DEFAULT_DELAY)) {
			// The flash is stale. Don't show it
			return null;
		}

		return (
			<Alert spaceAbove={spaceAbove} spaceBelow={spaceBelow} flavour={flavour || 'info'}>
				{message}
			</Alert>
		);
	}
}
