import React from 'react'
import { css } from 'glamor'
import { Link } from 'react-router-dom'
import * as theme from '../theme'
const { flavours } = theme

export const buttonSize = {
	small: {
		heightAboveGround: 1,
		borderWidth: 2,
		borderRadius: 4,
		fontSize: 14,
		height: 30,
		paddingLeft: 16,
		paddingRight: 16,
	},
	medium: {
		heightAboveGround: 2,
		borderWidth: 2,
		borderRadius: 6,
		fontSize: 20,
		height: 60,
		paddingLeft: 32,
		paddingRight: 32,
	},
	large: {
		heightAboveGround: 6,
		borderWidth: 4,
		borderRadius: 12,
		fontSize: 40,
		height: 120,
		paddingLeft: 64,
		paddingRight: 64,
	},
};

export const Button = ({
	flavour = 'primary',
	size = 'medium',
	mode = 'fill',
	type = 'button',
	block = false,
	disabled = false,
	spaceAbove = 'none',
	spaceBelow = 'small',
	className,
	...props
}) => {
	const hog = buttonSize[size].heightAboveGround;
	let Component = 'button';
	if (props.to && !disabled) {
		Component = Link;
	}
	if (props.href && !disabled) {
		Component = 'a';
	}

	const baseStyle = css({
		fontWeight: 'bold',
		borderStyle: 'solid',
		outline: 'none',

		// Make non-buttons look like buttons
		alignItems: 'center',
		display: 'inline-flex',
		textDecoration: 'none',
		textAlign: 'center',
		justifyContent: 'center',

		// Size
		borderWidth: buttonSize[size].borderWidth,
		borderRadius: buttonSize[size].borderRadius,
		fontSize: buttonSize[size].fontSize,
		height: buttonSize[size].height,
		paddingLeft: buttonSize[size].paddingLeft,
		paddingRight: buttonSize[size].paddingRight,

		// Spacing
		marginTop: theme.verticalSpace[spaceAbove],
		marginBottom: theme.verticalSpace[spaceBelow],

		// Other
		transition: theme.transitionSnappy,

		cursor:'pointer'
	});

	const blockStyle = block
		? css({
				display: 'flex',
				width: '100%',
		  })
		: null;

	const interactionShadow = !disabled && {
		':focus': {
			outline: 'none',
			boxShadow: `0 ${3 * hog}px ${hog}px -${1.5 * hog}px ${theme.gray30}`,
		},
		':hover': {
			outline: 'none',
			boxShadow: `0 ${3 * hog}px ${hog}px -${1.5 * hog}px ${theme.gray30}`,
		},
		':active': {
			outline: 'none',
			transition: `${25 * hog}ms`,
			transform: `translateY(${hog}px)`,
			boxShadow: `0 ${hog}px ${hog}px -${1.5 * hog}px ${theme.gray30}`,
		},
	};

	const nakedInteractionStyles = !disabled && {
		':hover, :focus': {
			opacity: 1,
		},
		':active': {
			backgroundColor: theme.gray10,
		},
	};

	const modeStyle = {
		fill: {
			color: flavours[flavour].contrastingColor,
			borderColor: 'transparent',
			background: flavours[flavour].highlightColor,
			':disabled': {
				color: theme.white,
				background: theme.gray20,
				opacity: 0.7,
			},
			...interactionShadow,
		},
		hollow: {
			background: theme.white,
			color: flavours[flavour].highlightColor,
			borderColor: flavours[flavour].highlightColor,
			':disabled': {
				color: theme.gray20,
				borderColor: theme.gray20,
				opacity: 0.7,
			},
			...interactionShadow,
		},
		naked: {
			color: flavours[flavour].highlightColor,
			background: 'transparent',
			borderColor: 'transparent',
			':disabled': {
				color: theme.gray10,
				opacity: 0.7,
			},
			...nakedInteractionStyles,
		},
	}[mode];

	return (
		<Component
			className={css(baseStyle, blockStyle, modeStyle, className)}
			type={type}
			disabled={disabled}
			{...props}
		/>
	);
};

export const HollowButton = (props) => <Button {...props} mode="hollow" />;
export const NakedButton = (props) => <Button {...props} mode="naked" />;
