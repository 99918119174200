import gql from "graphql-tag";

export const allRateCardStatusesQuery = gql`
  query RateCardStatuses($installationId: ID!) {
    rateCardsForInstallation(id: $installationId) {
      id
      full
      expired
      bookings
    }
  }
`;

export const rateCardStatusesQuery = gql`
  query RateCardStatuses($rateCardId: ID!) {
    rateCardStatus(id: $rateCardId) {
      id
      full
      expired
      bookings
    }
  }
`;
export const allRateCards = gql`
  query allRateCards($installationId: ID!, $isArchived: Boolean!) {
    allRateCards(
      filter: { installationId: $installationId, isArchived: $isArchived }
      orderBy: name_ASC
    ) {
      id
      version

      name
      type
      serviceFeePercent
      defaultOnExit
      defaultPublic

      isPublic
      selfPark
      publicUrl

      entryAfterTime
      exitBeforeTime
      bookingRateAudPerDay
      overstayRateAudPerHour
      overstayFlagfallAud
      overstayGraceMinutes

      bookingRateAudPerHour
      chargeOnExpiryAud

      hoursUntilExpiry
      maximum24HourAud

      isArchived
      createdAt

      startsAt
      expiresAt
      bookingsLimit
      calculationMode
      lot {
        name
      }

      isVip
      vipEmailRecipient
    }
  }
`;

export const rateCardById = gql`
  query rateCardById($rateCardId: ID!) {
    RateCard(id: $rateCardId) {
      id
      lineageId
      version

      name
      type
      serviceFeePercent
      defaultOnExit
      defaultPublic

      isPublic
      selfPark
      publicUrl
      stopPublicBookingWhenLotFull
      lotFullText
      homeText

      entryAfterTime
      exitBeforeTime
      bookingRateAudPerDay
      overstayRateAudPerHour
      overstayFlagfallAud
      overstayGraceMinutes

      bookingRateAudPerHour
      chargeOnExpiryAud

      hoursUntilExpiry
      maximum24HourAud

      guestName
      guestEmail
      guestPhone
      guestRoom
      guestReservation
      paymentGate
      multiDates
      paymentStripe
      includeCarType
      includeCarNotes
      includeArrivalTime
      includeLeavingTime

      isArchived
      createdBy {
        name
      }
      createdAt

      startsAt
      expiresAt
      bookingsLimit
      calculationMode

      rateCardExtras {
        description
        amount
        type
        disclaimer
        image
      }

      lot {
        id
        name
      }

      isVip
      vipEmailRecipient
    }
  }
`;

export const sendPayAtGateEmailMutation = gql`
  mutation PayAtGateEmail($input: PayAtGateEmailInput!) {
    sendPayAtGateEmail(payAtGateEmailInput: $input) {
      name
    }
  }
`;

export const createRateCardExtraMutation = gql`
  mutation createRateCardExtra(
    $rateCardId: ID!
    $description: String!
    $amount: String!
    $disclaimer: String
    $image: String
    $type: RateCardExtraTypeEnum!
  ) {
    createRateCardExtra(
      rateCardExtra: {
        rateCardId: $rateCardId
        description: $description
        amount: $amount
        type: $type
        disclaimer: $disclaimer
        image: $image
      }
    ) {
      id
      description
    }
  }
`;

export const createRateCardMutation = gql`
  mutation createRateCard(
    $installationId: ID!
    $lineageId: ID
    $name: String
    $type: RateCardTypeEnum!
    $defaultOnExit: Boolean!
    $defaultPublic: Boolean!
    $isPublic: Boolean!
    $selfPark: Boolean!
    $includeCarType: Boolean
    $includeCarNotes: Boolean
    $includeArrivalTime: Boolean
    $includeLeavingTime: Boolean
    $publicUrl: String
    $stopPublicBookingWhenLotFull: Boolean
    $lotFullText: String
    $homeText: String
    $entryAfterTime: Int!
    $exitBeforeTime: Int!
    $bookingRateAudPerDay: String!
    $overstayRateAudPerHour: String!
    $overstayFlagfallAud: String!
    $overstayGraceMinutes: Int!
    $bookingRateAudPerHour: String!
    $chargeOnExpiryAud: String!
    $maximum24HourAud: String!
    $hoursUntilExpiry: Int!
    $bookingsLimit: Int
    $expiresAt: String
    $startsAt: String
    $guestName: Boolean!
    $guestEmail: Boolean!
    $guestPhone: Boolean!
    $guestRoom: Boolean!
    $guestReservation: Boolean!
    $paymentGate: Boolean!
    $multiDates: Boolean!
    $paymentStripe: Boolean!
    $lotId: ID
    $calculationMode: RateCardCalculationModeEnum!
    $isVip: Boolean!
    $vipEmailRecipient: String
  ) {
    createRateCard(
      rateCard: {
        installationId: $installationId
        lineageId: $lineageId
        lotId: $lotId
        name: $name
        type: $type
        defaultOnExit: $defaultOnExit
        defaultPublic: $defaultPublic
        isPublic: $isPublic
        includeCarType: $includeCarType
        includeCarNotes: $includeCarNotes
        includeArrivalTime: $includeArrivalTime
        includeLeavingTime: $includeLeavingTime
        selfPark: $selfPark
        publicUrl: $publicUrl
        stopPublicBookingWhenLotFull: $stopPublicBookingWhenLotFull
        lotFullText: $lotFullText
        homeText: $homeText
        entryAfterTime: $entryAfterTime
        exitBeforeTime: $exitBeforeTime
        bookingRateAudPerDay: $bookingRateAudPerDay
        overstayRateAudPerHour: $overstayRateAudPerHour
        overstayFlagfallAud: $overstayFlagfallAud
        overstayGraceMinutes: $overstayGraceMinutes
        bookingRateAudPerHour: $bookingRateAudPerHour
        chargeOnExpiryAud: $chargeOnExpiryAud
        maximum24HourAud: $maximum24HourAud
        hoursUntilExpiry: $hoursUntilExpiry
        guestName: $guestName
        guestEmail: $guestEmail
        guestPhone: $guestPhone
        guestRoom: $guestRoom
        guestReservation: $guestReservation
        paymentGate: $paymentGate
        multiDates: $multiDates
        paymentStripe: $paymentStripe
        bookingsLimit: $bookingsLimit
        expiresAt: $expiresAt
        startsAt: $startsAt
        calculationMode: $calculationMode
        isVip: $isVip
        vipEmailRecipient: $vipEmailRecipient
      }
    ) {
      id
      name
    }
  }
`;

export const archiveRateCardMutation = gql`
  mutation archiveRateCard($rateCardId: ID!) {
    updateRateCard(rateCard: { id: $rateCardId }) {
      id
    }
  }
`;

export const createHourlyRateMutation = gql`
  mutation createHourlyRate($input: CreateHourlyRatesInput!) {
    createHourlyRate(input: $input) {
      id
    }
  }
`;

export const updateHourlyRateValuesMutation = gql`
  mutation updateHourlyRateValues($input: UpdateHourlyRatesInput!) {
    updateHourlyRateValues(input: $input) {
      facilityId
    }
  }
`;

export const hourlyRateByFacility = gql`
  query hourlyRateByFacility($installationId: ID!) {
    hourlyRateByFacility(installationId: $installationId) {
      facilityId
      rate
      maxDailyCharge
      override
    }
  }
`;
export const isPlateInLot = gql`
  query Facility($id: ID!, $plate: String!) {
    facility(id: $id) {
      isPlateInLot(plate: $plate)
    }
  }
`;
export const facilityByInstallation = gql`
  query Drivo($id: ID!) {
    facility: facilityByInstallation(installation: $id) {
      id
    }
  }
`;
