import Color from 'color';

export const white = '#FFFFFF';
export const black = '#000000';

export const gray90 = '#1A1A1A';
export const gray80 = '#333';
export const gray70 = '#4D4D4D';
export const gray60 = '#666';
export const gray50 = '#7F7F7F';
export const gray40 = '#999';
export const gray30 = '#d2d6da';
export const gray20 = '#CCC';
export const gray15 = '#D9D9D9';
export const gray10 = '#EAEDEF';
export const gray05 = '#F7F8F9';

export const blue = '#007AFF';
export const green = '#4CD964';
export const red = '#FF3B30';
export const yellow = '#ffc300';
export const barelyYellow = 'rgba(255,250,192,0.72)';
export const cyan = '#56CCF2';
export const indigo = '#5F267A';
export const orange = '#FFA800';

export const transitionSnappy = 'all 150ms linear';

export const verticalSpace = {
	none: 0,
	xsmall: 8,
	small: 16,
	medium: 32,
	large: 64,
	xlarge: 128,
};

export const flavours = {
	primary: {
		highlightColor: '#3127B8',
		contrastingColor: white,
		foregroundColor: Color(blue).darken(0.3),
		backgroundColor: Color(blue).lighten(0.7),

	},
	secondary: {
		highlightColor: indigo,
		contrastingColor: white,
		foregroundColor: Color(indigo).darken(0.2),
		backgroundColor: Color(indigo).lighten(1.5),
	},
	dark: {
		highlightColor: gray90,
		contrastingColor: white,
		foregroundColor: gray30,
		backgroundColor: gray80,
	},
	light: {
		highlightColor: gray15,
		contrastingColor: gray50,
		foregroundColor: gray40,
		backgroundColor: gray05,
	},
	gray: {
		highlightColor: gray50,
		contrastingColor: white,
		foregroundColor: gray60,
		backgroundColor: gray30,
	},
	info: {
		highlightColor: cyan,
		contrastingColor: white,
		foregroundColor: Color(cyan).darken(0.3),
		backgroundColor: Color(cyan).lighten(0.45),
	},
	warning: {
		highlightColor: yellow,
		contrastingColor: white,
		foregroundColor: Color(yellow).darken(0.2),
		backgroundColor: Color(yellow).lighten(0.8),
	},
	danger: {
		highlightColor: red,
		contrastingColor: white,
		foregroundColor: red,
		backgroundColor: Color(red).lighten(0.65),
	},
	success: {
		highlightColor: green,
		contrastingColor: white,
		foregroundColor: Color(green).darken(0.3),
		backgroundColor: Color(green).lighten(0.65),
	},
	badge:{
		borderStyle:'',
		backgroundColor:'rgb(156,159,194,.1)',
		borderColor:''
	}
};
