import React, {useLayoutEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {useQuery} from "@apollo/client"
import {CardContainer, Container} from '../../components/Container';
import {Byline, H1, H2, H6} from '../../components/Typography';
import {withInstallation} from '../../components/InstallationContext';
import {MediaPlayer} from 'dashjs';
import {getCameraStreams, getGates} from "./queries";
import drivoApollo from "../../lib/drivoApollo";
import {Button} from "../../components/Button";
import {openGateMutation} from "./mutations";

function Streams({installation, history, match}) {
    const [showGateMessage, setShowGateMessage] = useState(false);
    const {
        data = {}
    } = useQuery(getCameraStreams, {
        client: drivoApollo,
        fetchPolicy: 'no-cache',
        variables: {
            id: installation.id
        },
    })

    const {
        data: gates = {}
    } = useQuery(getGates, {
        client: drivoApollo,
        fetchPolicy: 'no-cache',
        variables: {
            id: installation.id
        },
    })

    useLayoutEffect(() => {
        window.dash = {};
        return () => {
            console.log("unmounting", window.dash)
            for (let gateId of Object.keys(window.dash)) {
                let dashStream = window.dash[gateId];
                console.log("unmounted", gateId)
                dashStream.reset();
            }
        }
    }, [])

    const openGate = async (gateId) => {
        console.log("Sending open gate request to ", gateId)
        await drivoApollo.mutate({
            mutation: openGateMutation,
            variables: {
                id: gateId
            }
        });
        setShowGateMessage(true);
        setTimeout(() => setShowGateMessage(false), 3000)
    }

    return <div>
        <Helmet>
            <title>Camera Streams</title>
        </Helmet>

        <CardContainer>
            <Container size="medium" spaceBelow="medium">
                <H1>Camera Streams</H1>
                <Byline>Your facility's live cameras</Byline>
            </Container>

            <Container size="medium" spaceBelow="large">
                <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
                    {
                        data?.StreamUrl?.map(u => {
                            console.log("CREATING VIDEO ", u.name);
                            return <div style={{width: 320, padding: 10}}>
                                <h3>{u.name}</h3>
                                <video style={{width: '100%', height: 240}}
                                       ref={(ref) => {
                                           const dashjs = MediaPlayer().create();
                                           window.dash[u.gateId] = dashjs;
                                           dashjs.initialize(ref,
                                               u.url,
                                               true);
                                       }
                                       }
                                       autoPlay={true}/>
                                {u.gateType === "EXIT" && u.signId != null &&
                                <iframe title={`Signs-${u.signId}`} src={`https://drivo.io/signs/${u.signId}`}/>
                                }

                            </div>
                        })}
                </div>
            </Container>
            <Container size="medium" spaceBelow="large">
                <H2>Gate Controls</H2>
                <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
                    {
                        gates?.gatesForInstallation?.map(gate => {
                            return <Button onClick={() => openGate(gate.id)}>Open Gate {gate.gateDescription}</Button>
                        })}
                </div>
                {showGateMessage && <H6>Gate open request sent.</H6>}
            </Container>
        </CardContainer>
    </div>
}

export default withInstallation(Streams)
