import React from 'react';
import {graphql} from "@apollo/client/react/hoc";
import { configureForm } from '@thinkmill/pragmatic-forms';

import authManager from '../../lib/authManager';
import compose from 'lodash/flowRight';
import { withState } from '../../lib/StateFully';
import { Alert } from '../../components/Alert';
import { Input } from '../../components/Input';
import { GraphqlErrorMessage, ValidationErrorMessage } from '../../components/ErrorMessage';
import { Button, NakedButton } from '../../components/Button';
import { ColContainer } from '../../components/Container';

import { updateUserQuery } from './queries';

const withForm = configureForm({
	initFields: (props) => ({
		name: props.User.name || '',
		email: props.User.email || '',
		phone: props.User.phone || '',
	}),
	validate: ({ name, email }) => {
		const errors = {};
		if (!name) errors.name = 'Please enter a name';
		if (!email) errors.email = 'Please enter an email';
		return errors;
	},
	submit: ({ name, email, phone }, props) => {
		return props.mutate({
			variables: {
				name,
				email,
				phone,
				userId: authManager.userId,
			},
		});
	},
	onSuccess: async (results, props, form) => {
		await props.onSuccess();
		form.reset();
		props.setState({ showSuccessMessage: true });
	},
	onFirstInteraction: (field, props) => {
		props.setState({ showSuccessMessage: false });
	},
});

const UserForm = ({ form, state }) => (
	<form.Form>
		{state.showSuccessMessage && <Alert flavour="success">Your changes have been saved.</Alert>}
		{form.submitError && <GraphqlErrorMessage error={form.submitError} />}
		{form.hasErrors && <ValidationErrorMessage />}
		<ColContainer>
			<Input label="Name" {...form.getFieldProps({ name: 'name', type: 'text' })} autoFocus />
			<Input label="Email" {...form.getFieldProps({ name: 'email', type: 'email' })} />
			<Input label="Phone number" {...form.getFieldProps({ name: 'phone', type: 'tel' })} />
		</ColContainer>
		<Button type="submit" disabled={form.loading || form.isPristine}>
			Update profile
		</Button>
		{!form.isPristine && (
			<NakedButton type="reset" flavour="gray" disabled={form.loading}>
				Cancel
			</NakedButton>
		)}
	</form.Form>
);

export default compose(
	withState(() => ({ showSuccessMessage: false })),
	graphql(updateUserQuery),
	withForm,
)(UserForm);
