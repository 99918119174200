import React, { Component } from 'react'
import { configureForm } from '@thinkmill/pragmatic-forms'
import { css } from 'glamor'
import ReactModal from 'react-modal'

import * as theme from '../theme'
import LoadingIndicator from './LoadingIndicator'
import compose from 'lodash/flowRight'
import { A, HR } from './Typography'
import { Alert } from './Alert'
import { Button, NakedButton } from './Button'
import { ColContainer } from './Container'
import { Label } from './Label'
import { Loading, LoadingError } from './LoadingFork'
import { inputSize } from './Input'
import {
	injectStripe,
	Elements,
	StripeProvider,
	CardNumberElement,
	CardExpiryElement,
	CardCVCElement,
} from 'react-stripe-elements'

function errorBorder (error) {
	return css({
		borderColor: error && theme.flavours.danger.foregroundColor,
		borderStyle: error && 'solid',
		borderWidth: error && inputSize.medium.borderWidth,
		borderRadius: error && inputSize.medium.borderRadius,
		padding: error && `10px`,
		marginBottom: error && `16px`,
	})
}

const stripeOptions = {
	classes: {
		base: css({
			display: 'block',
			width: '100%',
			background: theme.white,
			borderWidth: inputSize.medium.borderWidth,
			borderStyle: 'solid',
			borderColor: theme.gray10,
			borderRadius: inputSize.medium.borderRadius,
			height: inputSize.medium.height,
			paddingTop: '16px',
			paddingLeft: inputSize.medium.paddingLeft,
			paddingRight: inputSize.medium.paddingRight,
			marginBottom: inputSize.medium.marginBottom,
			transition: theme.transitionSnappy,
		}).toString(),
		focus: css({
			outline: 'none',
			borderColor: theme.blue,
		}).toString(),
		invalid: css({
			color: theme.flavours.danger.foregroundColor,
			borderColor: theme.flavours.danger.highlightColor,
		}).toString(),
	},
	style: {
		base: {
			fontSize: `${inputSize.medium.fontSize}px`,
			'::placeholder': {
				fontSize: inputSize.medium.fontSize,
				color: theme.gray20,
			},
		},
	},
}

const withForm = configureForm({
	displayName: 'CardForm',
	onSubmitStopPropagation: true,
	initFields: () => ({}),
	submit: async (formData, { stripe }) => {
		const { error, source } = await stripe.createSource({ type: 'card' })
		if (error) {
			return Promise.reject(error)
		} else {
			return source
		}
	},
	onSuccess: (stripeSource, props) => {
		props.onSuccess(stripeSource)
	},
})

const CardForm = compose(
	injectStripe,
	withForm,
)(({ form, onCancel }) => (
	<form.Form>
		{form.submitError && <Alert flavour="danger">{form.submitError.message}</Alert>}
		<div>
			<Label>Card number</Label>
			<CardNumberElement {...stripeOptions} />
		</div>
		<ColContainer>
			<div>
				<Label>Expiry Date</Label>
				<CardExpiryElement {...stripeOptions} />
			</div>
			<div>
				<Label>CVC</Label>
				<CardCVCElement {...stripeOptions} />
			</div>
		</ColContainer>
		{!form.isLoading && (
			<ColContainer>
				<Button type="submit" spaceBelow="none">
					Add card
				</Button>
				<NakedButton type="reset" flavour="gray" spaceBelow="none" onClick={onCancel}>
					Cancel
				</NakedButton>
			</ColContainer>
		)}
		{form.isLoading && (
			<div
				className={css({
					display: 'flex',
					alignItems: 'flex-end',
					height: 30,
					justifyContent: 'center',
				})}
			>
				<LoadingIndicator />
			</div>
		)}
	</form.Form>
))

function last4FromStripeSource (stripeSource) {
	try {
		const { card } = JSON.parse(stripeSource)
		return `•••• •••• •••• ${card.last4}`
	} catch (err) {
		return 'Cannot display card number.'
	}
}

function expiryFromStripeSource (stripeSource) {
	try {
		const { card } = JSON.parse(stripeSource)
		const mm = String(card.exp_month).padStart(2, '0')
		const yyyy = String(card.exp_year).slice(-2)
		return `${mm} / ${yyyy}`
	} catch (err) {
		return 'Cannot display card expiry.'
	}
}

export class StripeElementsInput extends Component {
	state = {
		loading: true,
		formIsOpen: false,
	};

	componentDidMount() {
		if (window.Stripe) {
			this.setState({ loading: false })
		} else {
			document.querySelector('#stripe-js').addEventListener('load', () => {
				this.setState({ loading: false })
			})
		}
	}

	openForm = () => this.setState({ formIsOpen: true })
	closeForm = () => this.setState({ formIsOpen: false })
	handleCardChange = (cardSource) => {
		this.closeForm()
		this.props.onChange(JSON.stringify(cardSource))
	}

	render() {
		const { loading, error, formIsOpen } = this.state
		const { value, disabled, apiKey } = this.props

		if (loading) return <Loading />
		if (error) return <LoadingError error={error} />

		const last4 = value && last4FromStripeSource(value)
		const expiry = value && expiryFromStripeSource(value)

		if (disabled) {
			return <div>
				<ColContainer columnTemplate="1fr 1fr 1fr">
					<div>Card number</div>
					<div>{last4}</div>
				</ColContainer>
				<HR spaceAbove="small" spaceBelow="small" />
				<ColContainer columnTemplate="1fr 1fr 1fr">
					<div>Card expiry</div>
					<div>{expiry}</div>
				</ColContainer>
				<HR spaceAbove="small" spaceBelow="small" />
			</div>
		}

		return <StripeProvider apiKey={apiKey}>
			<div>
				{!value && <ColContainer columnTemplate="1fr 1fr 1fr">
					<Button onClick={this.openForm}>+ Add Card</Button>
					<div className={errorBorder(this.props.error)}>
						<span className={css({
							color: this.props.error && theme.flavours.danger.foregroundColor,
						})} >
							Add customer card details to authorise parking fee
						</span>
					</div>
				</ColContainer>}

				{value && <>
					<ColContainer columnTemplate='1fr 1fr 1fr'>
						<div>Card number</div>
						<div>{last4}</div>
					</ColContainer>
					<HR spaceAbove='small' spaceBelow='small' />
					<ColContainer columnTemplate='1fr 1fr 1fr'>
						<div>Card expiry</div>
						<div>{expiry}</div>
					</ColContainer>
					<HR spaceAbove='small' spaceBelow='small' />
					<A block flavour='primary' spaceBelow='medium' onClick={this.openForm}>
						Update card details
					</A>
				</>}

				<ReactModal
					isOpen={formIsOpen}
					onRequestClose={this.closeForm}
					className={css({
						display: 'block',
						background: 'white',
						margin: 10,
						padding: 20,
						borderRadius: 6,
						outline: 'none',
						minWidth: 400,
					}).toString()}
					overlayClassName={css({
						backgroundColor: 'rgba(0,0,0,0.2)',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						position: 'fixed',
						zIndex: 100000,
						top: 0,
						right: 0,
						bottom: 0,
						left: 0,
					}).toString()}
				>
					<Elements ref={(el) => (this.stripeElement = el)}>
						<CardForm onSuccess={this.handleCardChange} onCancel={this.closeForm} />
					</Elements>
				</ReactModal>
			</div>
		</StripeProvider>
	}
}
