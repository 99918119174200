import React, { Component } from 'react';

export const withState = (initialiseState) => (WrappedComponent) =>
	class Statefull extends Component {
		static displayName = `Statefull(${WrappedComponent.displayName}`;
		constructor(props, context) {
			super(props, context);

			this.state = {
				...initialiseState(props),
			};
			this.boundSetState = this.setState.bind(this);
		}

		render() {
			return (
				<WrappedComponent
					{...this.props}
					state={{ ...this.state }}
					setState={this.boundSetState}
				/>
			);
		}
	};
