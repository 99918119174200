import React, { useEffect, useState } from 'react';
import { css } from 'glamor';
import * as theme from '../../../theme';

import { ColContainer, TPL_50_50 } from '../../../components/Container';
import { HR } from '../../../components/Typography';
import {
	formatCurrency,
	format24HPeriod,
	formatMinutes,
	makeSafe,
    timings,
} from '../../../lib/rateCardUtils'
import {fmtDate} from "../../../lib/misc";

function SummaryLabel (props) {
	return <span
		className={css({
			fontSize: 18,
			color: theme.gray50,
		})}
		{...props}
	/>
}

function SummaryValue (props) {
	return <div
		className={css({
			fontSize: 18,
			fontWeight: 'bold',
			color: theme.gray90,
		})}
		{...props}
	/>
}

function SummaryHint (props) {
	return <div
		className={css({
			fontSize: '0.8em',
			fontWeight: 'normal',
			paddingTop: '0.2em',
			color: theme.gray50,
		})}
		{...props}
	/>
}

export function ParkingSummary ({
	installation,
	rateCard,
	startDay,
	endDay,
	entryTime, 
	errors
}) {
	const [scheduled, setScheduled] = useState(null)
	const {
		bookingRateAudPerDay,
		overstayRateAudPerHour,
		overstayFlagfallAud,
		overstayGraceMinutes,

		serviceFeeRatio
	} = makeSafe(rateCard)
	const { currency } = installation;

	useEffect(() => {
		const fetchData = async () => {
			if (errors) return 

			try {
				const res = await timings({ startDay, endDay }, installation.id, rateCard.id)
				setScheduled(res.scheduled);
			} catch (e) {
				console.error(e.message)
			}
		};

		fetchData();
	}, [installation, rateCard, startDay, endDay, errors])

	return <div
		className={css({
			backgroundColor: theme.gray05,
			borderRadius: 6,
			padding: 20,
		})}
	>
		<ColContainer columnTemplate={TPL_50_50} spaceBelow="small">
			<SummaryLabel>Duration</SummaryLabel>
			<SummaryValue>
				{format24HPeriod(scheduled?.days, rateCard)}
			</SummaryValue>
		</ColContainer>
		<HR />
		<ColContainer columnTemplate={TPL_50_50} spaceAbove="small" spaceBelow="small">
			<SummaryLabel>Entry event</SummaryLabel>
			<SummaryValue>
				{entryTime ? fmtDate(entryTime?.toISO(), installation.timeZoneName, 'D MMM, yyyy') : 'No entry registered.'}
				{entryTime ? (
					<SummaryHint>
						Entered at {fmtDate(entryTime?.toISO(), installation.timeZoneName, 'HH:mm ZZZZ')}
					</SummaryHint>
				) : null}
			</SummaryValue>
		</ColContainer>
		<HR />
		<ColContainer columnTemplate={TPL_50_50} spaceAbove="small" spaceBelow="small">
			<SummaryLabel>Booking start</SummaryLabel>
			<SummaryValue>
				{startDay && fmtDate(scheduled?.startAt, installation.timeZoneName, 'D MMM, yyyy')}
				<SummaryHint>Entry after {fmtDate(scheduled?.startAt, installation.timeZoneName, 'HH:mm ZZZZ')}</SummaryHint>
			</SummaryValue>
		</ColContainer>
		<HR />
		{endDay ? <ColContainer columnTemplate={TPL_50_50} spaceAbove="small" spaceBelow="small">
			<SummaryLabel>Booking end</SummaryLabel>
			<SummaryValue>
				{endDay && fmtDate(scheduled?.endAt, installation.timeZoneName, 'D MMM, yyyy')}
				<SummaryHint>
					Exit before {fmtDate(scheduled?.endAt, installation.timeZoneName,'HH:mm ZZZZ')} (
					{formatMinutes(overstayGraceMinutes)} grace)
				</SummaryHint>
			</SummaryValue>
		</ColContainer> : null}
		<HR />
		<ColContainer columnTemplate={TPL_50_50} spaceAbove="small" spaceBelow="small">
			<SummaryLabel>Daily rate</SummaryLabel>
			<SummaryValue>
				{formatCurrency(bookingRateAudPerDay, currency)}
			</SummaryValue>
		</ColContainer>
		<HR />
		<ColContainer columnTemplate={TPL_50_50} spaceAbove="small">
			<SummaryLabel>Overstay flagfall</SummaryLabel>
			<SummaryValue>
				{formatCurrency(overstayFlagfallAud, currency)}
				{' '}<SummaryHint>
					(plus {formatCurrency(overstayRateAudPerHour, currency)} per hour)
				</SummaryHint>
			</SummaryValue>
		</ColContainer>
	</div>
}

export function ParkingSummaryHeader ({ installation, rateCard, startDay, endDay, errors }) {
	const [billing, setBilling] = useState(null)
	useEffect(() => {
		const fetchData = async () => {
			if (errors) return 

			try {
				const res = await timings({ startDay, endDay }, installation.id, rateCard.id)
				setBilling(res.billing);
			} catch (e) {
				console.error(e.message)
			}
		};

		fetchData();
	}, [installation, rateCard, startDay, endDay])
	if (!rateCard || !endDay || !startDay) {
		return <ColContainer columnTemplate="1fr 2fr" spaceBelow="medium">
			<strong className={css({ fontSize: 48, fontWeight: 800 })}>$0.00</strong>
			<p>
				Parking fee(s) are calculated automatically <i>after</i> the guest card and exit time have been selected.
			</p>
		</ColContainer>
	}

	return <ColContainer columnTemplate="1fr 2fr" spaceBelow="medium">
		<strong className={css({ fontSize: 48, fontWeight: 800 })}>
		{formatCurrency(billing?.fee.total, installation.currency)}
		</strong>
		<p>Plus overstay fee if applicable.</p>
	</ColContainer>
}
