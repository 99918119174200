import React from 'react';
import {Helmet} from 'react-helmet';
import {Route, Switch} from 'react-router-dom';

import SupportTicketList from './List';
import Support from './Create';
import TicketDetails from "./Details";

export default function ({ match }) {
    return <div>
        <Helmet>
            <title>Support</title>
        </Helmet>
        <Switch>
            <Route path={`${match.path}/new`} component={Support}/>
            <Route path={`${match.path}/:ticketId`}
                          component={TicketDetails}/>
            <Route path={`${match.path}`} component={SupportTicketList}/>
        </Switch>
    </div>
}
