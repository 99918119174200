const {
  addDays,
  addMilliseconds,
  addHours,
  addMinutes,
  subDays,
  subHours,
  differenceInHours,
  differenceInMilliseconds,
  formatDistanceStrict,
  lightFormat: formatDate,
  max,
  min,
  set,
  isBefore,
  isEqual,
  isAfter,
  isValid,
} = require('date-fns')
const {
  zonedTimeToUtc,
  utcToZonedTime
} = require('date-fns-tz')
const { find } = require('lodash/collection')
const { findLastIndex } = require('lodash/array')
const {DateTime} = require("luxon");

const EARLY_GRACE_HOURS = 6

// NOTE: uses `minutes` __and__ `hours` to account for DST changes
function __minutesToLocalTime (date, minutes) {
  return set(date, {
    hours: 0,
    minutes,
    seconds: 0,
    milliseconds: 0
  })
}

function toTitleCase(str) {
  return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
  );
}

// TODO: any additional rounding (e.g, using 1 + differenceInHours) will fork
function differenceInHoursCeil (a, b) {
  return Math.ceil(differenceInMilliseconds(a, b) / 3600000)
}

// TODO: FIXME: s/10000/100 (2 decimal places, not 4)
function roundCents (value) {
  return Math.round(value * 10000) / 10000
}

function dayToLocalDate (day) {
  if (typeof day !== 'string' || day.length !== 10) throw new TypeError(`Expected String day, got ${day}`)
  const dayLocal = new Date(day)
  if (!isValid(dayLocal)) throw new Error(`Expected String day, got ${day}`)
  return dayLocal
}

function __localTime (day, tz, addMinutes) {
  if (!tz) throw new TypeError('Missing TZ')
  return zonedTimeToUtc(__minutesToLocalTime(dayToLocalDate(day), addMinutes), tz)
}

function isEntryEvent (e) { return e.gateType === 'ENTRY' }
function isExitEvent (e) { return e.gateType === 'EXIT' }

// TODO: add tests
function span (events, expiresAt) {
  const safeEvents = events.filter(x => (expiresAt ? isBefore(x.pcLoggedAt, expiresAt) : true))
  const firstEntry = safeEvents.find(isEntryEvent) || null
  const lastEntryIndex = findLastIndex(safeEvents, isEntryEvent)
  const lastExit = (lastEntryIndex === -1 ? null : find(safeEvents, isExitEvent, lastEntryIndex)) || null

  return {
    firstEntry,
    lastExit
  }
}

function dateOrNull (value) {
  return value ? new Date(value) : null
}

function byPcLoggedAtASC (a, b) {
  return a.pcLoggedAt - b.pcLoggedAt
}

function makeBookingSafe (booking) {
  const {
    createdAt,
    closedAt,
    finalisedAt,
    events = []
  } = booking

  return Object.assign({}, booking, {
    createdAt: dateOrNull(createdAt),
    closedAt: dateOrNull(closedAt),
    finalisedAt: dateOrNull(finalisedAt),
    events: events
      .map((event) => {
        return Object.assign({}, event, {
          pcLoggedAt: dateOrNull(event.pcLoggedAt)
        })
      })
      .sort(byPcLoggedAtASC)
  })
}

function dropDuplicates (events, periodSameMs, periodAnyMs) {
  // drops any events with the same plate, within periodSameMs of eachother
  return events
    .concat() // don't mutate
    .sort(byPcLoggedAtASC)
    .filter(({ gateType, plate, pcLoggedAt }, i) => {
      if (i === 0) return true

      const previous = events[i - 1]
      if (previous.plate !== plate) return true

      const diff = differenceInMilliseconds(
        new Date(pcLoggedAt),
        new Date(previous.pcLoggedAt)
      )

      // _any_ event type within periodAnyMs is ignored
      if (diff < periodAnyMs) return false

      // _different_ event types, diff >= periodAnyMs, are retained
      if (previous.gateType !== gateType) return true

      // _same_ event type within periodSameMs is ignored
      return diff >= periodSameMs
    })
}

function feeBreakdown (amount, serviceFeeRatio) {
  const amountService = amount * serviceFeeRatio
  return {
    hotel: roundCents(amount - amountService),
    service: roundCents(amountService),
    total: roundCents(amount),
  }
}

function determineStartDay (startedAt, shouldBackDate, tz) {
  if (!tz) throw new TypeError('Missing TZ')
  const determined = shouldBackDate ? subDays(startedAt, 1) : startedAt
  return formatDate(utcToZonedTime(determined, tz), 'yyyy-MM-dd')
}

function hourlyFees ({
  hours,
  rateAudPerHour,
  maximum24HourAud,
}) {
  // no maximum24HourAud?
  if (maximum24HourAud < 0.01) {
    return {
      capped: false,
      fee: hours * rateAudPerHour,
      hours: {
        residual: hours,
        total: hours
      }
    }
  }

  const days = Math.floor(hours / 24)
  const daysFee = days * maximum24HourAud
  const residualHours = hours % 24
  const residualFee = residualHours * rateAudPerHour
  const capped = (days > 0) || (residualFee > maximum24HourAud)
  const residualFeeCapped = Math.min(residualFee, maximum24HourAud)

  return {
    capped,
    fee: daysFee + residualFeeCapped,
    hours: {
      residual: residualHours,
      total: hours
    }
  }
}

function detailsDaily (booking, tz, rateCard) {
  if (!rateCard.isDaily) throw new TypeError('Expected DAILY rateCard')

  const {
    createdAt = null,
    currentAt = null,
    closedAt = null,
    finalisedAt = null,

    startDay,
    endDay,

    events
  } = makeBookingSafe(booking)

  const {
    serviceFeeRatio,

    entryAfterTime,
    exitBeforeTime,

    bookingRateAudPerDay,
    overstayRateAudPerHour,
    overstayFlagfallAud,
    overstayGraceMinutes,

    hoursUntilExpiry = 24,
    maximum24HourAud
  } = makeSafe(rateCard)

  function milisToZonedDateTime(milis, tz){
    try {
      return DateTime
          .fromMillis(Number(milis), { locale: "en-AU" })
          .setZone(tz)
    } catch (error) {
      console.log("Error Parsing %o into a Date:", milis, JSON.stringify(error, Object.getOwnPropertyNames(error)))
    }
  }

  function ISOToZonedDateTime(date, tz){
    try {
      return DateTime
          .fromISO(date, { zone: tz, locale: "en-AU" })
    } catch (error) {
      console.log("Error Parsing %o into a Date:", date, JSON.stringify(error, Object.getOwnPropertyNames(error)))
    }
  }

  function dateToZonedDateTime(date, tz){
    return DateTime.fromISO(date, { locale: "en-AU" }).setZone(tz);
  }

  let scheduledStartAt = startDay ? (isNaN(startDay) ? ISOToZonedDateTime(startDay, tz): milisToZonedDateTime(startDay, tz)) : null;
  let scheduledEndAt = endDay ? (isNaN(endDay) ? ISOToZonedDateTime(endDay, tz): milisToZonedDateTime(endDay, tz)) : null;

  const shouldBumpExtraDay = scheduledEndAt < scheduledStartAt;

  if (scheduledStartAt) scheduledStartAt = scheduledStartAt.startOf('day').plus({ minutes: entryAfterTime })
  if (scheduledEndAt) scheduledEndAt = scheduledEndAt.startOf('day').plus({
    minutes: exitBeforeTime,
    days: shouldBumpExtraDay ? 1 : 0, // same-day daily booking adjustment to prevent negative intervals
  })

  const days = (scheduledStartAt && scheduledEndAt) ? Math.ceil(determineDays(scheduledStartAt.toISO(), scheduledEndAt.toISO(), rateCard, tz)) : NaN;
  const expiresAt = scheduledEndAt ? scheduledEndAt.plus({hours: hoursUntilExpiry}) : null
  const { firstEntry, lastExit } = span(events, expiresAt?.toJSDate())
  const enteredAt = firstEntry ? dateToZonedDateTime(firstEntry.pcLoggedAt.toISOString(), tz) : null
  const exitedAt = lastExit ? dateToZonedDateTime(lastExit.pcLoggedAt.toISOString(), tz) : null
  const expired = Boolean(closedAt && !exitedAt)

  const startedAt = (enteredAt && scheduledStartAt) ? DateTime.min(scheduledStartAt, enteredAt) : scheduledStartAt
  const scheduledEndAtForOverstay = scheduledEndAt
  const endedAt = closedAt ? ((exitedAt && scheduledEndAtForOverstay) ? DateTime.max(exitedAt, scheduledEndAtForOverstay) : scheduledEndAtForOverstay) : null
  const exitGraceEndsAt = scheduledEndAtForOverstay.plus({minutes: overstayGraceMinutes})
  const overstayedGrace = endedAt > exitGraceEndsAt

  let overstayHours = 0
  if (scheduledEndAtForOverstay < endedAt) {
    overstayHours = Math.abs(endedAt.diff(scheduledEndAtForOverstay, "hours").hours)
  }

  const overstayed = overstayHours > 0

  // fees
  const baseAud = days * bookingRateAudPerDay
  const {
    capped,
    fee: overstayAudBase,
    hours: hoursBreakdown
  } = hourlyFees({
    hours: overstayHours,
    rateAudPerHour: overstayRateAudPerHour,
    maximum24HourAud
  })

  const overstayAud = overstayedGrace ? (overstayAudBase + overstayFlagfallAud) : 0

  return {
    scheduled: {
      days,
      startAt: scheduledStartAt?.toJSDate(),
      endAt: scheduledEndAt?.toJSDate(),
      activeAt: scheduledStartAt?.toJSDate(),
      expiresAt: expiresAt?.toJSDate()
    },

    billing: {
      days,
      scheduled: {
        fee: feeBreakdown(baseAud, serviceFeeRatio),
      },
      overstay: {
        applicable: overstayedGrace,
        rate: feeBreakdown(overstayRateAudPerHour, serviceFeeRatio),
        capped,
        hours: hoursBreakdown,
        fee: feeBreakdown(overstayAud, serviceFeeRatio)
      },
      rate: feeBreakdown(bookingRateAudPerDay, serviceFeeRatio),
      fee: feeBreakdown(baseAud + overstayAud, serviceFeeRatio)
    },

    // useful
    early: enteredAt < scheduledStartAt,
    expired,
    overstayed,

    enteredAt: enteredAt?.toJSDate(),
    exitedAt: exitedAt?.toJSDate(),
    startedAt: startedAt?.toJSDate(),
    endedAt: endedAt?.toJSDate(),

    // historical
    createdAt,
    currentAt,
    closedAt,
    finalisedAt
  }
}

function detailsHourly (booking, tz, rateCard, options) {
  if (!rateCard.isHourly) throw new TypeError('Expected HOURLY rateCard')

  const {
    createdAt,
    currentAt,
    closedAt,
    finalisedAt,
    events
  } = makeBookingSafe(booking)

  const {
    serviceFeeRatio,

    bookingRateAudPerHour,
    chargeOnExpiryAud,

    msUntilActive = 0, // TODO: not part of a rateCard
    hoursUntilExpiry,
    maximum24HourAud,
  } = makeSafe(rateCard)

  const { firstEntry, lastExit } = span(events)
  const enteredAt = firstEntry ? firstEntry.pcLoggedAt : null
  const exitedAt = lastExit ? lastExit.pcLoggedAt : null
  const expired = Boolean(closedAt && !exitedAt)

  const scheduledStartAt = enteredAt || createdAt // WARNING: variable
  const activeAt = addMilliseconds(scheduledStartAt, msUntilActive) // WARNING: variable
  const expiresAt = addHours(scheduledStartAt, hoursUntilExpiry) // WARNING: variable

  const startedAt = expired ? scheduledStartAt : enteredAt
  const endedAt = startedAt ? (expired ? expiresAt : exitedAt) : null

  const activated = Boolean(startedAt) && isAfter(endedAt, activeAt)
  const hours = (startedAt && endedAt) ? differenceInHoursCeil(endedAt, startedAt) : 0

  const {
    capped,
    fee: feeAudBase,
    hours: hoursBreakdown
  } = hourlyFees({
    hours,
    rateAudPerHour: bookingRateAudPerHour,
    maximum24HourAud
  })
  // fees
  const feeAud = expired ? chargeOnExpiryAud : feeAudBase

  return {
    // WARNING: schedule is variable
    scheduled: {
      startAt: scheduledStartAt,
      endAt: expiresAt,
      activeAt,
      expiresAt,
    },

    billing: {
      applicable: activated,
      rate: feeBreakdown(bookingRateAudPerHour, serviceFeeRatio),
      capped,
      hours: hoursBreakdown,
      fee: activated ? feeBreakdown(feeAud, serviceFeeRatio) : feeBreakdown(0, 0)
    },

    // useful
    early: false,
    expired,
    overstayed: false, // N/A

    enteredAt,
    exitedAt,
    startedAt,
    endedAt,

    // historical
    createdAt,
    currentAt,
    closedAt,
    finalisedAt
  }
}

// is startedAt < startedAt.day@entryAfterTime, or after (EARLY_GRACE_HOURS before the next day @entryAfterTime)?
// function determineIsEarly (startedAt, tz, rateCard) {
//   const sameDay = determineStartDay(startedAt, false, tz)
//   const nextDay = determineStartDay(addDays(startedAt, 1), false, tz)
//   const sameDayTimings = details({ startDay: sameDay }, tz, rateCard)
//   const nextDayTimings = details({ startDay: nextDay }, tz, rateCard)
//
//   return isBefore(startedAt, sameDayTimings.scheduled.startAt) ||
//     isAfter(startedAt, subHours(nextDayTimings.scheduled.startAt, EARLY_GRACE_HOURS))
// }

// NOTE: no tz parameter - no time is involved
function determineEndDay (startDay, days, rateCard) {
  const startTime = dayToLocalDate(startDay)
  const daily = rateCard.entryAfterTime < rateCard.exitBeforeTime
  const fullDays = daily ? Math.max(0, days - 1) : days
  const endDay = addDays(startTime, fullDays)
  return formatDate(endDay, 'yyyy-MM-dd')
}

function determineDays(
  startDay,
  endDay,
  rateCard,
  tz,
) {
  // DAILY
  if (rateCard.type === 'DAILY') {
    const startAt = DateTime.fromISO(startDay, { zone: tz, locale: 'en-AU' });
    const endAt = DateTime.fromISO(endDay, { zone: tz, locale: 'en-AU' });

    if (startDay === endDay) {
      // NOTE: same day is 1 day
      return 1;
    }

    if (endAt < startAt) {
      throw new TypeError(`${startDay} < ${endDay}`);
    }

    // NOTE: differenceInDays offsets DST, we don't want that
    const days = Math.abs(endAt.diff(startAt, 'days').days);

    return days;
  }

  // HOURLY
  else {
    const startAt = DateTime.fromISO(startDay, {
      zone: tz,
      locale: 'en-AU',
    }).plus({ minutes: rateCard.entryAfterTime });
    const endAt = DateTime.fromISO(endDay, { zone: tz, locale: 'en-AU' }).plus({
      minutes: rateCard.exitBeforeTime,
    });

    if (startDay == endDay) {
      // NOTE: same day is 1 day
      return 1;
    }

    if (endAt < startAt) {
      throw new TypeError(`${startDay} < ${endDay}`);
    }

    const rollover = rateCard.entryAfterTime === rateCard.exitBeforeTime;
    if (endAt === startAt && rollover) {
      throw new TypeError(`${startDay} == ${endDay}`);
    }

    // NOTE: differenceInDays offsets DST, we don't want that
    const days = Math.abs(endAt.diff(startAt, 'days').days);

    const finalDays = rollover ? days : days + 1;

    // NOTE: same day is 1 day
    if (finalDays === 0) {
      return 1;
    }

    return finalDays;
  }
}


function numberOrThrow (value) {
  // gives 0 for null, NaN for NaN, NaN for text or whitespace
  value = Number(value)
  if (!Number.isFinite(value)) throw new TypeError(`Expected Number, got ${value}`)
  return value
}

function makeSafe (rateCard) {
  const serviceFeeRatio = numberOrThrow(rateCard.serviceFeePercent) / 100

  const bookingRateAudPerDay = numberOrThrow(rateCard.bookingRateAudPerDay)
  const overstayRateAudPerHour = numberOrThrow(rateCard.overstayRateAudPerHour)
  const overstayFlagfallAud = numberOrThrow(rateCard.overstayFlagfallAud)
  const overstayGraceMinutes = numberOrThrow(rateCard.overstayGraceMinutes)

  const bookingRateAudPerHour = numberOrThrow(rateCard.bookingRateAudPerHour)
  const chargeOnExpiryAud = numberOrThrow(rateCard.chargeOnExpiryAud)

  const maximum24HourAud = numberOrThrow(rateCard.maximum24HourAud)

  const isDaily = rateCard.type === 'DAILY'
  const isHourly = rateCard.type === 'HOURLY'

  const cardExpiration = rateCard?.expiresAt ? DateTime.fromJSDate(new Date(rateCard.expiresAt)) : null;
  const expiresLaterToday = cardExpiration ? Math.abs(cardExpiration.diff(DateTime.fromJSDate(new Date()), ["hours"]).values.hours) < 24 : false;

  return Object.assign({}, rateCard, {
    isDaily,
    isHourly,
    serviceFeeRatio,

    bookingRateAudPerDay,
    overstayRateAudPerHour,
    overstayFlagfallAud,
    overstayGraceMinutes,

    bookingRateAudPerHour,
    maximum24HourAud,
    chargeOnExpiryAud,

    expiresLaterToday
  })
}

async function details(booking) {
  const url = `${process.env.REACT_APP_API_URL}/bookings/details`;
  const res = await fetch(url, {
    method: 'POST', body: JSON.stringify({
      bookingId: booking.id,
      installationId: booking.installationId,
      rateCardId: booking.rateCard.id,
    }),
    headers: { 'Content-Type': 'application/json' }
  });
  const data = await res.json();
  return data?.details;
}

async function timings(booking, installationId, rateCardId) {
  const url = `${process.env.REACT_APP_API_URL}/bookings/details`;
  const res = await fetch(url, {
    method: 'POST', body: JSON.stringify({
      booking: booking,
      installationId,
      rateCardId,
    }),
    headers: { 'Content-Type': 'application/json' }
  });
  const data = await res.json();
  return data?.details;
}

function requirements (rateCard) {
  const {
    isDaily,
    guestName,
    guestEmail,
    guestPhone,
    guestRoom,
    guestReservation,
    paymentStripe,
    includeCarType,
    includeCarNotes,
    includeArrivalTime,
    includeLeavingTime,
  } = makeSafe(rateCard)

  return {
    guestDetails: guestName || guestEmail || guestPhone || guestRoom || guestReservation,
    startDay: isDaily,
    endDay: isDaily,

    guestName,
    guestEmail,
    guestPhone,
    guestRoom,
    guestReservation,
    stripeSource: paymentStripe,
    includeCarType,
    includeCarNotes,
    includeArrivalTime,
    includeLeavingTime,
  }
}

function determineOptionals (rateCard) {
  const {
    defaultOnExit,
    defaultPublic,
    guestName,
    guestEmail,
    guestPhone,
    guestRoom,
    guestReservation,
    paymentGate,
    paymentStripe
  } = rateCard

  // pay-at-gate constraints
  if (defaultOnExit || paymentGate) {
    return {
      defaultOnExit: true,
      defaultPublic: false,
      guestName: false,
      guestEmail: false,
      guestPhone: false,
      guestRoom: false,
      guestReservation: false,
      paymentGate: true,
      paymentStripe: false
    }
  }

  // typical constraints
  if (
    defaultPublic ||
    guestName ||
    guestEmail ||
    guestPhone ||
    guestRoom ||
    guestReservation ||
    paymentStripe
  ) {
    return {
      defaultOnExit: false,
      defaultPublic: true,
      guestName: true,
      guestEmail: true,
      guestPhone: true,
      guestRoom: true,
      guestReservation: true,
      paymentGate: false,
      paymentStripe: true
    }
  }

  // everything is possible at first
  return {
    defaultOnExit: true,
    defaultPublic: true,
    guestName: true,
    guestEmail: true,
    guestPhone: true,
    guestRoom: true,
    guestReservation: true,
    paymentGate: true,
    paymentStripe: true
  }
}

function verify (booking, rateCard) {
  const needs = requirements(rateCard)
  if (needs.startDay && !booking.startDay) return false
  if (needs.endDay && !booking.endDay) return false
  if (needs.guestName && !booking.guestName) return false
  if (needs.guestEmail && !booking.guestEmail) return false
  if (needs.guestPhone && !booking.guestPhone) return false
  if (needs.guestRoom && !booking.guestRoom) return false
  if (needs.guestReservation && !booking.guestReservation) return false
  if (needs.paymentStripe && !booking.stripeSource) return false
  return true
}

function formatCurrency (value, currency = 'AUD') {
  try {
    if (value !== 0 && !value || !Number.isFinite(value)) throw new TypeError(`Expected Number, got ${value}`)
  } catch {
    return value
  }
  
  return value.toLocaleString('en-AU', {
    style: 'currency',
    currency,
    currencyDisplay: 'symbol',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}

function format24HPeriod (days, rateCard) {
  if (!rateCard) return '-';

  const period = rateCard.entryAfterTime < rateCard.exitBeforeTime
    ? 'day' : 'night'

  if (days === 1) return `1 ${period}`
  return `${days} ${period}s`
}

function formatMinutes (minutes) {
  return formatDistanceStrict(0, minutes * 60000)
}

function formatHours (hours) {
  return formatDistanceStrict(0, hours * 3600000)
}

export {
  __minutesToLocalTime, // don't use
  details,
  timings,
  determineDays,
  // determineIsEarly,
  determineStartDay,
  determineEndDay,
  determineOptionals,
  dropDuplicates,
  formatCurrency,
  format24HPeriod,
  formatHours,
  formatMinutes,
  makeSafe,
  requirements,
  span,
  verify,
  toTitleCase
}
