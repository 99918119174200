import React from 'react';
import { css } from 'glamor';
import * as theme from '../theme';
import { Label } from './Label';

export const inputSize = {
	small: {
		borderWidth: 2,
		borderRadius: 4,
		fontSize: 14,
		height: 30,
		paddingLeft: 8,
		paddingRight: 8,
		marginBottom: 8,
	},
	medium: {
		borderWidth: 2,
		borderRadius: 6,
		fontSize: 18,
		height: 200,
		paddingLeft: 16,
		paddingRight: 16,
		marginBottom: 16,
	},
	large: {
		borderWidth: 4,
		borderRadius: 12,
		fontSize: 22,
		height: 500,
		paddingLeft: 32,
		paddingRight: 32,
		marginBottom: 32,
	},
};

export const TextArea = ({
	label,
	error,
	size = 'medium',
	onChange,
	onValueChange,
	format,
	className,
	...props
}) => {
	const CSS = inputSize[size]
	const errorStyle = !!error && {
		color: theme.flavours.danger.foregroundColor,
		borderColor: theme.flavours.danger.highlightColor,
	};

	function _onChange (event) {
		if (onChange) return onChange(event)

		const v = event.target.value;
		if (format) return onValueChange(format(v));
		return onValueChange(v);
	}

	return <div className={className}>
		{label && <Label error={error}>{label}</Label>}
		<textarea
			className={css(
				{
					display: 'block',
					width: '100%',
					background: theme.white,
					borderWidth: CSS.borderWidth,
					borderStyle: 'solid',
					borderColor: theme.gray10,
					borderRadius: CSS.borderRadius,
					height: CSS.height,
					fontSize: CSS.fontSize,
					paddingLeft: CSS.paddingLeft,
					paddingRight: CSS.paddingRight,
					marginBottom: CSS.marginBottom,
					transition: theme.transitionSnappy,
					':focus': {
						outline: 'none',
						borderColor: theme.blue,
					},
					':disabled': {
						opacity: 0.5,
					},
					'::placeholder': {
						color: theme.gray20,
					},
				},
				errorStyle,
			)}
			onChange={_onChange}
			{...props}
		/>
	</div>
}
