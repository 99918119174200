import React, {useEffect, useState} from 'react';
import authManager, {AUTH_STATE_CHANGE} from './lib/authManager';
import compose from 'lodash/flowRight';
import {AdminOnly, MemberOnly} from './components/AdminOnly';
import {Helmet} from 'react-helmet';
import {LoadingFork} from './components/LoadingFork';
import {PageContainer, PageFooter, StartContainer} from './components/Container';
import {PoweredBy} from './components/PoweredBy'
import {css} from 'glamor';
import {setDefaultTimezone} from './lib/momentConfig';
import {withAppConfig} from './components/AppConfigContext';
import {withInstallation} from './components/InstallationContext';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';
import BookingNewPage from './pages/Booking/New';
import BookingPage from './pages/Booking';
import LoginPage from './pages/Login';
import ProfilePage from './pages/Profile';
import SettingsPage from './pages/Settings';
import HealthPage from './pages/Health';
import StreamsPage from "./pages/Booking/StreamsPage";
import SupportPage from "./pages/Support";
import Dashboard from "./pages/Dashboard";

import Grid from '@material-ui/core/Grid'
import SideBar from './components/Sidebar'
import NavBar from './components/NavBar'
import UpcomingExtras from "./pages/Booking/UpcomingExtras";

function HomePage({match}) {
    return <Redirect to={`${match.url.replace(/\/$/, '')}/new-booking`}/>;
}

function PrivateRoute({component: Component, ...rest}) {
    return <Route {...rest} render={(props) => <Component {...props} />}/>;
}

function App(props) {
    const {installation, match} = props
    const [authenticated, setAuthenticated] = useState(authManager.isAuthenticated)
    const [updating, setUpdating] = useState(authManager.isUpdating)
    const [statusMessage, setStatus] = useState(authManager.statusMessage)

    function onAuthStateChange(authState) {
        setAuthenticated(authManager.isAuthenticated)
        setUpdating(authManager.isUpdating)
        setStatus(authState.statusMessage)
    }

    useEffect(() => {
        try {
            setDefaultTimezone(installation.timeZoneName);
        } catch (err) {
            console.error(err); // eslint-disable-line no-console
        }

        authManager.addListener(AUTH_STATE_CHANGE, onAuthStateChange);
        return () => {
            authManager.removeListener(AUTH_STATE_CHANGE, onAuthStateChange);
        }
    }, [installation])

    if (authenticated) {
        return (
            <PageContainer>
                <Helmet defaultTitle="Hotel 101" titleTemplate="%s | Hotel 101">
                    {installation && <title>{installation.name}</title>}
                </Helmet>
                {/*<AppHeader {...props} />*/}
                <NavBar {...props} />
                <div style={{padding: '20px'}}>
                    <br/><br/>
                    <Grid container spacing={1}>
                        <Grid item xs={3}>
                            <SideBar {...props}/>
                        </Grid>
                        <Grid item xs={9}>
                            <main
                                className={css({
                                    paddingLeft: [20, '3vw'],
                                    paddingRight: [20, '3vw'],
                                })}
                            >
                                <Switch>
                                    <MemberOnly>
                                        <PrivateRoute exact path={`${match.path}/dashboard`} component={Dashboard}/>
                                        <PrivateRoute exact path={`${match.path}/new-booking`}
                                                      component={BookingNewPage}/>
                                        <PrivateRoute exact path={`${match.path}/upcoming-extras`}
                                                      component={UpcomingExtras}/>
                                        <PrivateRoute exact path={`${match.path}/streams`} component={StreamsPage}/>
                                        <PrivateRoute path={`${match.path}/bookings`} component={BookingPage}/>
                                        <PrivateRoute path={`${match.path}/profile`} component={ProfilePage}/>
                                        <PrivateRoute path={`${match.path}/support`} component={SupportPage}/>
                                        <AdminOnly>
                                            <PrivateRoute path={`${match.path}/settings`} component={SettingsPage}/>
                                        </AdminOnly>
                                        <Route exact path={`${match.path}/login`}>
                                            <Redirect to={`${match.url}/dashboard`}/>
                                        </Route>
                                        <Route path={`${match.path}/health`} component={HealthPage}/>
                                    </MemberOnly>
                                </Switch>
                            </main>
                        </Grid>
                    </Grid>
                </div>
                <PageFooter>
                    <PoweredBy/>
                </PageFooter>
            </PageContainer>
        );
    }

    // Show a spinner rather than the login screen when loading auth state.
    if (updating) {
        return <LoadingFork loading statusMessage={statusMessage}/>;
    }

    return <StartContainer>
        <Switch>
            <Route path={`${match.path}/login`} component={LoginPage}/>
            <Redirect from={`${match.path}`} to={`${match.url}/login`}/>
        </Switch>
        <PageFooter>
            <PoweredBy/>
        </PageFooter>
    </StartContainer>
}

export default compose(
    withAppConfig,
    withInstallation,
    withRouter,
)(App)
