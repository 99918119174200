import React from 'react';
import { css, compose } from 'glamor';
import * as theme from '../theme';
import { Label } from './Label';
import dropdown from '../icons/dropdown.svg';

export function SelectInput ({
	label,
	placeholder,
	error,
	name,
	value,
	options = [],
	onChange,
	disabled,
	className,
}) {
	const baseStyle = {
		position: 'relative',
	};

	const iconStyle = {
		position: 'absolute',
		top: '50%',
		right: 16,
		transform: 'translateY(-50%)',
		pointerEvents: 'none',
	};

	const selectStyle = {
		WebkitAppearance: 'none',
		display: 'block',
		width: '100%',
		background: theme.white,
		borderWidth: 2,
		borderStyle: 'solid',
		borderColor: theme.gray10,
		borderRadius: 6,
		height: 60,
		fontSize: 22,
		paddingLeft: 16,
		paddingRight: 16,
		marginBottom: 16,
		transition: theme.transitionSnappy,
		':focus': {
			outline: 'none',
			borderColor: theme.blue,
		},
		':disabled': {
			opacity: 0.5,
		},
		'::placeholder': {
			color: theme.gray20,
		},
	};

	const errorStyle = !!error && {
		borderColor: theme.flavours.danger.foregroundColor,
	};

	return (
		<div className={compose(baseStyle, className)}>
			{label && <Label error={error}>{label}</Label>}
			<div className={css({ position: 'relative' })}>
				<img src={dropdown} className={css(iconStyle)} alt="" />
				<select
					name={name}
					defaultValue={placeholder}
					className={compose(
						selectStyle,
						errorStyle,
					)}
					onChange={onChange}
					value={value}
					disabled={disabled}
				>
					{placeholder ? (
						<option label={placeholder} disabled>{placeholder}</option>
					) : null}
					{options.map((oProps, index) => {
						const key = `${oProps.value}_${index}`
						return <option key={key} {...oProps}>{oProps.label}</option>
					})}
				</select>
			</div>
		</div>
	);
}
