import React from 'react';
import { css } from 'glamor';
import { verticalSpace } from '../theme';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

const sizes = {
	small: 460,
	medium: 760,
	large: 1160,
};

const footerHeight = 50;

export const PageContainer = (props) => (
	<div
		{...props}
		className={css({
			position: 'relative',
			minHeight: '100vh',
			paddingBottom: footerHeight,
		})}
	/>
);

export const StartContainer = (props) => (
	<div
		{...props}
		className={css({
			position: 'relative',
			minHeight: '100vh',
			paddingBottom: footerHeight,
			display:'flex',
			justifyContent:'center',
			alignItems:'center'
		})}
	/>
);

export const PageFooter = (props) => (
	<footer
		{...props}
		className={css({
			position: 'absolute',
			bottom: 0,
			left: 0,
			right: 0,
			height: footerHeight,
		})}
	/>
);

export const Container = ({
	size = 'large',
	spaceBelow = 'none',
	spaceAbove = 'none',
	children,
	...props
}) => (
	<div
		className={css({
			position: 'relative',
			marginLeft: 'auto',
			marginRight: 'auto',
			marginTop: verticalSpace[spaceAbove],
			marginBottom: verticalSpace[spaceBelow],
			maxWidth: sizes[size],
		})}
		{...props}
	>
		<div className={css({ position: 'relative' })}>{children}</div>
	</div>
);

export const ColContainer = ({
	spaceBelow = 'none',
	spaceAbove = 'none',
	columnTemplate = 'auto auto',
	...props
}) => (
	<section
		className={css({
			marginTop: verticalSpace[spaceAbove],
			marginBottom: verticalSpace[spaceBelow],
			'@media (min-width: 650px)': {
				display: 'grid',
				gridTemplateColumns: columnTemplate,
				gridColumnGap: 16,
				alignItems: 'center',
			},
		})}
		{...props}
	/>
);

export function CardContainer({children}) {
	return (
		<Card style={{
			overflowY: 'auto',
			height: '850px',
			borderRadius: '14px',
			background: '#fff',
			boxShadow: '0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05)',
			transition: '.3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12)',
			padding: '35px 35px 45px 15px',
		}}>
			<CardContent>
				{children}
			</CardContent>
		</Card>
	)
}

export const TPL_50_50 = '50% 50%';
export const TPL_THIRDS = '1fr 1fr 1fr';
